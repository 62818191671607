import {commitMutation} from "react-relay";
import {reportError} from "../utils/errorReporting";


export function handleMutationError(input, error, onCompleted, mutation) {
  const isConnectivityIssue = error?.data?.message === "Failed to fetch";
  reportError(new Error("Error in mutation " + mutation.operation.name + (isConnectivityIssue ? " (connectivity)" : "")), {input, mutation, error});
  if (onCompleted) onCompleted({}, [error]);
}

export function createMutation(mutation, {inputSerializer, returnTheOnlyKeyInResponse = false, updater = null, configs = null, optimisticResponse = null, serializeMutationInputOnError = true} = {}) {

  const commit = (environment, input, onCompleted, updaterContext) => {
    const serializedInput = inputSerializer ? inputSerializer(input) : input;
    const onCompletedAction = (response, errors) => {
      if (onCompleted && typeof onCompleted === 'function') {
        if (returnTheOnlyKeyInResponse) {
          const keys = Object.keys(response);
          if (keys.length !== 1) {
            throw Error("More than one key!");
          }
          response = response[keys[0]];
        }
        onCompleted(response, errors);
      }
    };
    const updaterCallback = updater && ((store) => updater(store, updaterContext));

    return commitMutation(environment, {
      mutation,
      variables: {
        input: serializedInput
      },
      onCompleted: onCompletedAction,
      onError: err => (
        handleMutationError(serializeMutationInputOnError ? input : {}, err, onCompleted, mutation)
      ),
      optimisticResponse: optimisticResponse,
      updater: updaterCallback,
      configs: configs
    });
  };

  commit.async = async (environment, input, updaterContext) => {
    return new Promise((resolve, reject) => {
      const onCompleted = (response, errors) => {
        if (errors) {
          reject(errors);
        } else {
          resolve(response);
        }
      };
      commit(environment, input, onCompleted, updaterContext);
    });
  };

  return commit;
}
