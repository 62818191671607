import {isDev} from "./Utils";
import {trackIntercomEvent, updateIntercomAboutPageChange, updateIntercomUserProps} from "./intercom";
import _ from 'lodash';

function mixpanelIns() {
  // eslint-disable-next-line no-undef
  return mixpanel;
}

class AnalyticsWrapper {
  constructor() {
    this.isInit = false;
    this.isDEV = isDev();
    this.pageUrl = null;
  }

  init(isFirstLogin, isFirstSession, businessName, userEmail, languageCode, experiments) {
    if (this.isDEV || this.isInit) return;
    this.isInit = true;

    mixpanelIns().people.set_once({
      "$created": new Date(),
    });
    if (userEmail) {
      mixpanelIns().identify(userEmail);
      mixpanelIns().people.set_once({
        "$email": userEmail,
      });
    }
    mixpanelIns().people.set({
      "$name": businessName || userEmail,
      "$last_login": new Date(),
      "$language_code": languageCode
    });

    if (isFirstLogin) {
      this.onFirstLogin();
    }
    if (isFirstSession) {
      this.trackActiveExperiments(experiments);
    }
    this.trackEvent('App Session', {isFirstLogin});

    window.Intercom('onShow', () => {
      this.trackEvent("Intercom Show");
    });
    window.Intercom('onHide', () => {
      this.trackEvent("Intercom Hide");
    });
  }

  onFirstLogin() {
    if (this.isDEV) {
      console.log("Track first login");
    } else {
      window.gtag('event', 'conversion', {'send_to': 'AW-692204288/jESKCIKY_NkBEIDmiMoC'});
      window.fbq('track', 'Conversion Account Created');
      this.trackEvent("First Login");
    }
  }

  onRecordSaved() {
    if (this.isDEV) {
      console.log("Record Saved");
    } else {
      window.gtag('event', 'conversion', {'send_to': 'AW-692204288/Y7t2CKL_ru4BEIDmiMoC'});
      window.fbq('track', 'Record Saved');
      this.trackEvent("Record Saved", {}, true);
    }
  }

  onTaxServiceRequestedConversion() {
    if (this.isDEV) {
      console.log("Tax Service Request Conversion");
    } else {
      window.gtag('event', 'conversion', {'send_to': 'AW-692204288/HeVTCJa2n-4BEIDmiMoC'});
      window.fbq('track', 'Tax Service Requested');
    }
  }

  setUserProperties(properties) {
    if (this.isDEV) {
      console.log("setUserProperties", properties);
      return;
    }
    mixpanelIns().people.set(properties);
    updateIntercomUserProps(properties);
  }

  trackEvent(event, properties = {}, reportToIntercom = false, reportToFacebook) {
    if (this.isDEV) {
      console.log(event, properties);
      return;
    }
    const props = {...properties};
    if (this.pageUrl) {
      props.pageUrl = this.pageUrl;
      props.pageName = this.pageName;
    }
    mixpanelIns().track(event, props);

    if (reportToIntercom) {
      trackIntercomEvent(event, props);
    }
    if (reportToFacebook) {
      this.reportEventToFacebook(event);
    }
  }

  reportEventToFacebook(event) {
    if (this.isDEV) {
      console.log(`Reporting ${event} to facebook`);
      return;
    }
    window.fbq('track', event);
  }

  trackPageChange(pageName, url) {
    if (["/", "/app"].includes(pageName)) return; // will be redirected, not interesting events
    this.pageUrl = url;
    this.pageName = pageName;
    this.trackEvent('PL - ' + pageName);
    updateIntercomAboutPageChange();
  }

  trackActiveExperiments(experiments) {
    // Needed for anonymous user, since the backend doesn't have the tracking id and there is no email yet
    experiments.forEach(experiment => {
      const experimentName = _.startCase(_.toLower(experiment.replace("_", " ")));
      this.setUserProperties({[`${experimentName} Experiment`]: true});
    });

  }

  onSubscription(value, currency = 'EUR') {
    if (this.isDEV) {
      console.log("Subscription Conversion");
    } else {
      window.gtag('event', 'conversion', {'send_to': 'AW-692204288/hbzqCPbIj88DEIDmiMoC', 'value': value, 'currency': currency});
      window.fbq('track', 'Subscription');
    }
  }
}

export default new AnalyticsWrapper();
