// @flow

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {|
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any,
  DateTimeStringScalar: any,
  DateStringScalar: any,
  DecimalStringScalar: any,
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any,
  DecimalScalar: any,
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any,
|};

export type AbstractRecordInterface = {|
  /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
  uid?: ?$ElementType<Scalars, 'String'>,
  grossAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
  partialRecordSourceCategoryType?: ?PartialRecordSourceCategoryEnum,
  category?: ?$ElementType<Scalars, 'String'>,
  convertedGrossAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
  deductibleGrossAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
  deductibleConvertedGrossAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
  isServiceOrGoods?: ?$ElementType<Scalars, 'String'>,
  privateCarUsageType?: ?$ElementType<Scalars, 'String'>,
  currency?: ?$ElementType<Scalars, 'String'>,
  paymentStatus?: ?$ElementType<Scalars, 'String'>,
  counterPartyCountry?: ?$ElementType<Scalars, 'String'>,
  repeatInterval?: ?$ElementType<Scalars, 'String'>,
  date?: ?$ElementType<Scalars, 'Date'>,
  description?: ?$ElementType<Scalars, 'String'>,
  currencyConversionRate?: ?$ElementType<Scalars, 'DecimalScalar'>,
  vat?: ?$ElementType<Scalars, 'JSONString'>,
  vatExemptionParagraph?: ?VatExemptionParagraph,
  expenseAttributionToVatExemptRevenue?: ?ExpenseAttributionToVatExemptRevenue,
  netAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
  isCustomerPrivatePerson?: ?$ElementType<Scalars, 'Boolean'>,
  counterPartyVatNumber?: ?$ElementType<Scalars, 'String'>,
  expectedLocalVatRate?: ?$ElementType<Scalars, 'DecimalScalar'>,
  otherTaxesCharged?: ?$ElementType<Scalars, 'Boolean'>,
  isHomeOffice?: ?$ElementType<Scalars, 'Boolean'>,
  vatDeductiblePortion?: ?$ElementType<Scalars, 'DecimalScalar'>,
  netDeductiblePortion?: ?$ElementType<Scalars, 'DecimalScalar'>,
  flatRateExpenseStartDate?: ?$ElementType<Scalars, 'Date'>,
  flatRateExpenseEndDate?: ?$ElementType<Scalars, 'Date'>,
  privateCarDistanceDriven?: ?$ElementType<Scalars, 'Int'>,
  businessTripMealAccommodationCountry?: ?$ElementType<Scalars, 'String'>,
  privateCarDriveToWorkTotalDays?: ?$ElementType<Scalars, 'Int'>,
  assetLifetimeValue?: ?$ElementType<Scalars, 'Int'>,
  paymentMonth?: ?$ElementType<Scalars, 'Date'>,
  document?: ?DocumentType,
  recurringSettingSource?: ?RecurringRecordSettingType,
  business?: ?BusinessType,
  depreciationAmounts?: ?$ElementType<Scalars, 'JSONString'>,
  inputChannel?: ?InputChannelEnum,
  invoice?: ?InvoiceType,
  vatPaymentPeriodInterval?: ?VatReportingInterval,
  shouldLink?: ?$ElementType<Scalars, 'Boolean'>,
  reverseChargeNetAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
  reverseChargeApply?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type AdvanceVatPaymentDataType = {|
  __typename?: 'AdvanceVatPaymentDataType',
  amount?: ?$ElementType<Scalars, 'DecimalScalar'>,
  recordIds?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

export type AnnualPayslipType = {|
  ...Node,
  ...{|
    __typename?: 'AnnualPayslipType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    incomeTaxReport: IncomeTaxReportType,
    isMainPartner: $ElementType<Scalars, 'Boolean'>,
    amounts?: ?$ElementType<Scalars, 'JSONString'>,
    startDate: $ElementType<Scalars, 'Date'>,
    endDate: $ElementType<Scalars, 'Date'>,
    taxClass: $ElementType<Scalars, 'String'>,
    totalIncomeBeforeTax: $ElementType<Scalars, 'DecimalScalar'>,
    employeeContributionsToPension?: ?$ElementType<Scalars, 'DecimalScalar'>,
    employeeContributionsToPublicHealthInsurance?: ?$ElementType<Scalars, 'DecimalScalar'>,
    employeeContributionsToPublicCareInsurance?: ?$ElementType<Scalars, 'DecimalScalar'>,
    employeeContributionsToUnemploymentInsurance?: ?$ElementType<Scalars, 'DecimalScalar'>,
    employeeContributionsToPrivateHealthAndCareInsurance?: ?$ElementType<Scalars, 'DecimalScalar'>,
  |}
|};

export type AnnualPayslipTypeConnection = {|
  __typename?: 'AnnualPayslipTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?AnnualPayslipTypeEdge>,
|};

/** A Relay edge containing a `AnnualPayslipType` and its cursor. */
export type AnnualPayslipTypeEdge = {|
  __typename?: 'AnnualPayslipTypeEdge',
  /** The item at the end of the edge */
  node?: ?AnnualPayslipType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export type AnnualVatReportType = {|
  ...Node,
  ...VatReportInterface,
  ...BaseReportInterface,
  ...BaseVatReportInterface,
  ...ReportPeriodInterface,
  ...{|
    __typename?: 'AnnualVatReportType',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    reportedDate?: ?$ElementType<Scalars, 'Date'>,
    sentWithSorted?: ?$ElementType<Scalars, 'Boolean'>,
    isCorrection?: ?$ElementType<Scalars, 'Boolean'>,
    confirmationDocument?: ?DocumentType,
    business?: ?BusinessType,
    year: $ElementType<Scalars, 'Int'>,
    reportedTotalNetRevenue?: ?$ElementType<Scalars, 'DecimalScalar'>,
    reportedTotalVatAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    reportedAdvanceVatAmountBalance?: ?$ElementType<Scalars, 'DecimalScalar'>,
    totalVatToBePaid?: ?$ElementType<Scalars, 'DecimalScalar'>,
    allLines?: ?Array<?VatReportLineType>,
    shouldPayVatInYear?: ?$ElementType<Scalars, 'Boolean'>,
    paymentReference?: ?$ElementType<Scalars, 'String'>,
    hideOnTimeline?: ?$ElementType<Scalars, 'Boolean'>,
    isSent?: ?$ElementType<Scalars, 'Boolean'>,
    submissionDeadline?: ?$ElementType<Scalars, 'Date'>,
    requiresSubscription?: ?$ElementType<Scalars, 'Boolean'>,
    isFeatureInTrial?: ?$ElementType<Scalars, 'Boolean'>,
    unpaidRecords?: ?RecordTypeConnection,
    invoicesWithoutTaxNumber?: ?RecordTypeConnection,
    expensesWithoutAttributionToVatExemptRevenue?: ?RecordTypeConnection,
    reportingInterval?: ?VatReportingInterval,
    submitUrgencyLevel?: ?ReportSubmitUrgencyLevel,
    allowSubmission?: ?$ElementType<Scalars, 'Boolean'>,
    periodStartDate?: ?$ElementType<Scalars, 'Date'>,
    totalVatAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    advanceVatAmountBalance?: ?$ElementType<Scalars, 'DecimalScalar'>,
    totalNetRevenue?: ?$ElementType<Scalars, 'DecimalScalar'>,
    remainingTaxToBePaid?: ?$ElementType<Scalars, 'DecimalScalar'>,
    previousYearReport?: ?AnnualVatReportType,
    advanceReports?: ?VatReportTypeConnection,
    revenueForSmallBusinessOwnerThreshold?: ?$ElementType<Scalars, 'DecimalScalar'>,
    advanceVatReportReportingInterval?: ?VatReportingInterval,
    advanceVatPaid?: ?AdvanceVatPaymentDataType,
    advanceVatRefund?: ?AdvanceVatPaymentDataType,
  |}
|};


export type AnnualVatReportTypeUnpaidRecordsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type AnnualVatReportTypeInvoicesWithoutTaxNumberArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type AnnualVatReportTypeExpensesWithoutAttributionToVatExemptRevenueArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type AnnualVatReportTypeAdvanceReportsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type AnnualVatReportTypeConnection = {|
  __typename?: 'AnnualVatReportTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?AnnualVatReportTypeEdge>,
|};

/** A Relay edge containing a `AnnualVatReportType` and its cursor. */
export type AnnualVatReportTypeEdge = {|
  __typename?: 'AnnualVatReportTypeEdge',
  /** The item at the end of the edge */
  node?: ?AnnualVatReportType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export const AutoRecordGrossAmountCurrencyValues = Object.freeze({
  /** ADB Unit of Account */
  Xua: 'XUA',
  /** Afghani */
  Afn: 'AFN',
  /** Algerian Dinar */
  Dzd: 'DZD',
  /** Argentine Peso */
  Ars: 'ARS',
  /** Armenian Dram */
  Amd: 'AMD',
  /** Aruban Guilder */
  Awg: 'AWG',
  /** Australian Dollar */
  Aud: 'AUD',
  /** Azerbaijanian Manat */
  Azn: 'AZN',
  /** Bahamian Dollar */
  Bsd: 'BSD',
  /** Bahraini Dinar */
  Bhd: 'BHD',
  /** Baht */
  Thb: 'THB',
  /** Balboa */
  Pab: 'PAB',
  /** Barbados Dollar */
  Bbd: 'BBD',
  /** Belarussian Ruble */
  Byn: 'BYN',
  /** Belarussian Ruble */
  Byr: 'BYR',
  /** Belize Dollar */
  Bzd: 'BZD',
  /** Bermudian Dollar (customarily known as Bermuda Dollar) */
  Bmd: 'BMD',
  /** Bhutanese ngultrum */
  Btn: 'BTN',
  /** Bolivar Fuerte */
  Vef: 'VEF',
  /** Boliviano */
  Bob: 'BOB',
  /** Bond Markets Units European Composite Unit (EURCO) */
  Xba: 'XBA',
  /** Brazilian Real */
  Brl: 'BRL',
  /** Brunei Dollar */
  Bnd: 'BND',
  /** Bulgarian Lev */
  Bgn: 'BGN',
  /** Burundi Franc */
  Bif: 'BIF',
  /** CFA Franc BCEAO */
  Xof: 'XOF',
  /** CFA franc BEAC */
  Xaf: 'XAF',
  /** CFP Franc */
  Xpf: 'XPF',
  /** Canadian Dollar */
  Cad: 'CAD',
  /** Cape Verde Escudo */
  Cve: 'CVE',
  /** Cayman Islands Dollar */
  Kyd: 'KYD',
  /** Chilean peso */
  Clp: 'CLP',
  /** Codes specifically reserved for testing purposes */
  Xts: 'XTS',
  /** Colombian peso */
  Cop: 'COP',
  /** Comoro Franc */
  Kmf: 'KMF',
  /** Congolese franc */
  Cdf: 'CDF',
  /** Convertible Marks */
  Bam: 'BAM',
  /** Cordoba Oro */
  Nio: 'NIO',
  /** Costa Rican Colon */
  Crc: 'CRC',
  /** Croatian Kuna */
  Hrk: 'HRK',
  /** Cuban Peso */
  Cup: 'CUP',
  /** Cuban convertible peso */
  Cuc: 'CUC',
  /** Czech Koruna */
  Czk: 'CZK',
  /** Dalasi */
  Gmd: 'GMD',
  /** Danish Krone */
  Dkk: 'DKK',
  /** Denar */
  Mkd: 'MKD',
  /** Djibouti Franc */
  Djf: 'DJF',
  /** Dobra */
  Std: 'STD',
  /** Dominican Peso */
  Dop: 'DOP',
  /** Dong */
  Vnd: 'VND',
  /** East Caribbean Dollar */
  Xcd: 'XCD',
  /** Egyptian Pound */
  Egp: 'EGP',
  /** El Salvador Colon */
  Svc: 'SVC',
  /** Ethiopian Birr */
  Etb: 'ETB',
  /** Euro */
  Eur: 'EUR',
  /** European Monetary Unit (E.M.U.-6) */
  Xbb: 'XBB',
  /** European Unit of Account 17(E.U.A.-17) */
  Xbd: 'XBD',
  /** European Unit of Account 9(E.U.A.-9) */
  Xbc: 'XBC',
  /** Falkland Islands Pound */
  Fkp: 'FKP',
  /** Fiji Dollar */
  Fjd: 'FJD',
  /** Forint */
  Huf: 'HUF',
  /** Ghana Cedi */
  Ghs: 'GHS',
  /** Gibraltar Pound */
  Gip: 'GIP',
  /** Gold */
  Xau: 'XAU',
  /** Gold-Franc */
  Xfo: 'XFO',
  /** Guarani */
  Pyg: 'PYG',
  /** Guinea Franc */
  Gnf: 'GNF',
  /** Guyana Dollar */
  Gyd: 'GYD',
  /** Haitian gourde */
  Htg: 'HTG',
  /** Hong Kong Dollar */
  Hkd: 'HKD',
  /** Hryvnia */
  Uah: 'UAH',
  /** Iceland Krona */
  Isk: 'ISK',
  /** Indian Rupee */
  Inr: 'INR',
  /** Iranian Rial */
  Irr: 'IRR',
  /** Iraqi Dinar */
  Iqd: 'IQD',
  /** Isle of Man Pound */
  Imp: 'IMP',
  /** Jamaican Dollar */
  Jmd: 'JMD',
  /** Jordanian Dinar */
  Jod: 'JOD',
  /** Kenyan Shilling */
  Kes: 'KES',
  /** Kina */
  Pgk: 'PGK',
  /** Kip */
  Lak: 'LAK',
  /** Kuwaiti Dinar */
  Kwd: 'KWD',
  /** Kwanza */
  Aoa: 'AOA',
  /** Kyat */
  Mmk: 'MMK',
  /** Lari */
  Gel: 'GEL',
  /** Latvian Lats */
  Lvl: 'LVL',
  /** Lebanese Pound */
  Lbp: 'LBP',
  /** Lek */
  All: 'ALL',
  /** Lempira */
  Hnl: 'HNL',
  /** Leone */
  Sll: 'SLL',
  /** Lesotho loti */
  Lsl: 'LSL',
  /** Liberian Dollar */
  Lrd: 'LRD',
  /** Libyan Dinar */
  Lyd: 'LYD',
  /** Lilangeni */
  Szl: 'SZL',
  /** Lithuanian Litas */
  Ltl: 'LTL',
  /** Malagasy Ariary */
  Mga: 'MGA',
  /** Malawian Kwacha */
  Mwk: 'MWK',
  /** Malaysian Ringgit */
  Myr: 'MYR',
  /** Manat */
  Tmm: 'TMM',
  /** Mauritius Rupee */
  Mur: 'MUR',
  /** Metical */
  Mzn: 'MZN',
  /** Mexican Unidad de Inversion (UDI) */
  Mxv: 'MXV',
  /** Mexican peso */
  Mxn: 'MXN',
  /** Moldovan Leu */
  Mdl: 'MDL',
  /** Moroccan Dirham */
  Mad: 'MAD',
  /** Mvdol */
  Bov: 'BOV',
  /** Naira */
  Ngn: 'NGN',
  /** Nakfa */
  Ern: 'ERN',
  /** Namibian Dollar */
  Nad: 'NAD',
  /** Nepalese Rupee */
  Npr: 'NPR',
  /** Netherlands Antillian Guilder */
  Ang: 'ANG',
  /** New Israeli Sheqel */
  Ils: 'ILS',
  /** New Leu */
  Ron: 'RON',
  /** New Taiwan Dollar */
  Twd: 'TWD',
  /** New Zealand Dollar */
  Nzd: 'NZD',
  /** North Korean Won */
  Kpw: 'KPW',
  /** Norwegian Krone */
  Nok: 'NOK',
  /** Nuevo Sol */
  Pen: 'PEN',
  /** Ouguiya */
  Mro: 'MRO',
  /** Paanga */
  Top: 'TOP',
  /** Pakistan Rupee */
  Pkr: 'PKR',
  /** Palladium */
  Xpd: 'XPD',
  /** Pataca */
  Mop: 'MOP',
  /** Philippine Peso */
  Php: 'PHP',
  /** Platinum */
  Xpt: 'XPT',
  /** Pound Sterling */
  Gbp: 'GBP',
  /** Pula */
  Bwp: 'BWP',
  /** Qatari Rial */
  Qar: 'QAR',
  /** Quetzal */
  Gtq: 'GTQ',
  /** Rand */
  Zar: 'ZAR',
  /** Rial Omani */
  Omr: 'OMR',
  /** Riel */
  Khr: 'KHR',
  /** Rufiyaa */
  Mvr: 'MVR',
  /** Rupiah */
  Idr: 'IDR',
  /** Russian Ruble */
  Rub: 'RUB',
  /** Rwanda Franc */
  Rwf: 'RWF',
  /** SDR */
  Xdr: 'XDR',
  /** Saint Helena Pound */
  Shp: 'SHP',
  /** Saudi Riyal */
  Sar: 'SAR',
  /** Serbian Dinar */
  Rsd: 'RSD',
  /** Seychelles Rupee */
  Scr: 'SCR',
  /** Silver */
  Xag: 'XAG',
  /** Singapore Dollar */
  Sgd: 'SGD',
  /** Solomon Islands Dollar */
  Sbd: 'SBD',
  /** Som */
  Kgs: 'KGS',
  /** Somali Shilling */
  Sos: 'SOS',
  /** Somoni */
  Tjs: 'TJS',
  /** South Sudanese Pound */
  Ssp: 'SSP',
  /** Sri Lanka Rupee */
  Lkr: 'LKR',
  /** Sucre */
  Xsu: 'XSU',
  /** Sudanese Pound */
  Sdg: 'SDG',
  /** Surinam Dollar */
  Srd: 'SRD',
  /** Swedish Krona */
  Sek: 'SEK',
  /** Swiss Franc */
  Chf: 'CHF',
  /** Syrian Pound */
  Syp: 'SYP',
  /** Taka */
  Bdt: 'BDT',
  /** Tala */
  Wst: 'WST',
  /** Tanzanian Shilling */
  Tzs: 'TZS',
  /** Tenge */
  Kzt: 'KZT',
  /** The codes assigned for transactions where no currency is involved */
  Xxx: 'XXX',
  /** Trinidad and Tobago Dollar */
  Ttd: 'TTD',
  /** Tugrik */
  Mnt: 'MNT',
  /** Tunisian Dinar */
  Tnd: 'TND',
  /** Turkish Lira */
  Try: 'TRY',
  /** Turkmenistan New Manat */
  Tmt: 'TMT',
  /** Tuvalu dollar */
  Tvd: 'TVD',
  /** UAE Dirham */
  Aed: 'AED',
  /** UIC-Franc */
  Xfu: 'XFU',
  /** US Dollar */
  Usd: 'USD',
  /** US Dollar (Next day) */
  Usn: 'USN',
  /** Uganda Shilling */
  Ugx: 'UGX',
  /** Unidad de Fomento */
  Clf: 'CLF',
  /** Unidad de Valor Real */
  Cou: 'COU',
  /** Uruguay Peso en Unidades Indexadas (URUIURUI) */
  Uyi: 'UYI',
  /** Uruguayan peso */
  Uyu: 'UYU',
  /** Uzbekistan Sum */
  Uzs: 'UZS',
  /** Vatu */
  Vuv: 'VUV',
  /** WIR Euro */
  Che: 'CHE',
  /** WIR Franc */
  Chw: 'CHW',
  /** Won */
  Krw: 'KRW',
  /** Yemeni Rial */
  Yer: 'YER',
  /** Yen */
  Jpy: 'JPY',
  /** Yuan Renminbi */
  Cny: 'CNY',
  /** Zambian Kwacha */
  Zmk: 'ZMK',
  /** Zambian Kwacha */
  Zmw: 'ZMW',
  /** Zimbabwe Dollar A/06 */
  Zwd: 'ZWD',
  /** Zimbabwe dollar A/08 */
  Zwn: 'ZWN',
  /** Zimbabwe dollar A/09 */
  Zwl: 'ZWL',
  /** Zloty */
  Pln: 'PLN'
});


export type AutoRecordGrossAmountCurrency = $Values<typeof AutoRecordGrossAmountCurrencyValues>;

export type AutoRecordType = {|
  ...AbstractRecordInterface,
  ...{|
    __typename?: 'AutoRecordType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business?: ?BusinessType,
    date?: ?$ElementType<Scalars, 'Date'>,
    grossAmountCurrency: AutoRecordGrossAmountCurrency,
    grossAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    currencyConversionRate?: ?$ElementType<Scalars, 'DecimalScalar'>,
    description?: ?$ElementType<Scalars, 'String'>,
    document?: ?DocumentType,
    vat?: ?$ElementType<Scalars, 'JSONString'>,
    totalVat?: ?$ElementType<Scalars, 'DecimalScalar'>,
    netAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    convertedGrossAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    category?: ?$ElementType<Scalars, 'String'>,
    recordType?: ?$ElementType<Scalars, 'String'>,
    isCustomerPrivatePerson?: ?$ElementType<Scalars, 'Boolean'>,
    counterPartyCountry?: ?$ElementType<Scalars, 'String'>,
    counterPartyVatNumber?: ?$ElementType<Scalars, 'String'>,
    isServiceOrGoods?: ?$ElementType<Scalars, 'String'>,
    expectedLocalVatRate?: ?$ElementType<Scalars, 'DecimalScalar'>,
    otherTaxesCharged?: ?$ElementType<Scalars, 'Boolean'>,
    isHomeOffice?: ?$ElementType<Scalars, 'Boolean'>,
    vatReport?: ?VatReportType,
    zmReport?: ?ZmReportType,
    vatDeductiblePortion?: ?$ElementType<Scalars, 'DecimalScalar'>,
    netDeductiblePortion?: ?$ElementType<Scalars, 'DecimalScalar'>,
    flatRateExpenseStartDate?: ?$ElementType<Scalars, 'Date'>,
    flatRateExpenseEndDate?: ?$ElementType<Scalars, 'Date'>,
    privateCarDistanceDriven?: ?$ElementType<Scalars, 'Int'>,
    businessTripMealAccommodationCountry?: ?$ElementType<Scalars, 'String'>,
    privateCarUsageType?: ?$ElementType<Scalars, 'String'>,
    privateCarDriveToWorkTotalDays?: ?$ElementType<Scalars, 'Int'>,
    assetLifetimeValue?: ?$ElementType<Scalars, 'Int'>,
    paymentStatus?: ?$ElementType<Scalars, 'String'>,
    paymentMonth?: ?$ElementType<Scalars, 'Date'>,
    repeatInterval?: ?$ElementType<Scalars, 'String'>,
    recurringSettingSource?: ?RecurringRecordSettingType,
    vatExemptionParagraph?: ?VatExemptionParagraph,
    expenseAttributionToVatExemptRevenue?: ?ExpenseAttributionToVatExemptRevenue,
    inputChannel?: ?InputChannelEnum,
    vatPaymentPeriodInterval?: ?VatReportingInterval,
    reverseChargeNetAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    reverseChargeApply?: ?$ElementType<Scalars, 'Boolean'>,
    isPendingApproval: $ElementType<Scalars, 'Boolean'>,
    linkedToRecord?: ?RecordType,
    uid?: ?$ElementType<Scalars, 'String'>,
    partialRecordSourceCategoryType?: ?PartialRecordSourceCategoryEnum,
    deductibleGrossAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    deductibleConvertedGrossAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    currency?: ?$ElementType<Scalars, 'String'>,
    depreciationAmounts?: ?$ElementType<Scalars, 'JSONString'>,
    invoice?: ?InvoiceType,
    shouldLink?: ?$ElementType<Scalars, 'Boolean'>,
  |}
|};

export type AutoRecordTypeConnection = {|
  __typename?: 'AutoRecordTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?AutoRecordTypeEdge>,
  count?: ?$ElementType<Scalars, 'Int'>,
  exists?: ?$ElementType<Scalars, 'Boolean'>,
  convertedGrossAmountSum?: ?$ElementType<Scalars, 'DecimalScalar'>,
|};

/** A Relay edge containing a `AutoRecordType` and its cursor. */
export type AutoRecordTypeEdge = {|
  __typename?: 'AutoRecordTypeEdge',
  /** The item at the end of the edge */
  node?: ?AutoRecordType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BankAccountType = {|
  __typename?: 'BankAccountType',
  name?: ?$ElementType<Scalars, 'String'>,
  nature?: ?$ElementType<Scalars, 'String'>,
  balance?: ?$ElementType<Scalars, 'DecimalStringScalar'>,
  currencyCode?: ?$ElementType<Scalars, 'String'>,
  iban?: ?$ElementType<Scalars, 'String'>,
|};

export type BankAttemptType = {|
  __typename?: 'BankAttemptType',
  id: $ElementType<Scalars, 'ID'>,
  failAt?: ?$ElementType<Scalars, 'DateTimeStringScalar'>,
  failMessage?: ?$ElementType<Scalars, 'String'>,
  failErrorClass?: ?$ElementType<Scalars, 'String'>,
  successAt?: ?$ElementType<Scalars, 'DateTimeStringScalar'>,
  fromDate?: ?$ElementType<Scalars, 'DateStringScalar'>,
|};

export const BankConnectionStatusValues = Object.freeze({
  Active: 'active',
  Inactive: 'inactive',
  Disabled: 'disabled'
});


export type BankConnectionStatus = $Values<typeof BankConnectionStatusValues>;

export type BankConnectionType = {|
  __typename?: 'BankConnectionType',
  id: $ElementType<Scalars, 'ID'>,
  provider?: ?BankProviderType,
  providerCode?: ?$ElementType<Scalars, 'String'>,
  providerName?: ?$ElementType<Scalars, 'String'>,
  nextRefreshPossibleAt?: ?$ElementType<Scalars, 'DateTimeStringScalar'>,
  createdAt?: ?$ElementType<Scalars, 'DateTimeStringScalar'>,
  status?: ?BankConnectionStatus,
  categorization?: ?BankProviderCategorization,
  lastSuccessAt?: ?$ElementType<Scalars, 'DateTimeStringScalar'>,
  lastAttempt?: ?BankAttemptType,
  bankAccounts?: ?Array<?BankAccountType>,
  autoRecords?: ?AutoRecordTypeConnection,
  isFailed?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type BankConnectionTypeAutoRecordsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
  recordType_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  category?: ?$ElementType<Scalars, 'String'>,
  date_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Gte?: ?$ElementType<Scalars, 'Date'>,
  recurringSettingSource_Isnull?: ?$ElementType<Scalars, 'Boolean'>,
  paymentStatus?: ?$ElementType<Scalars, 'String'>,
  search?: ?$ElementType<Scalars, 'String'>,
  dateYear?: ?$ElementType<Scalars, 'Int'>,
  paymentDateOrDateYearLte?: ?$ElementType<Scalars, 'Int'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  idIn?: ?$ElementType<Scalars, 'String'>,
  excludeVatPayments?: ?$ElementType<Scalars, 'Boolean'>,
  recommendedRecordsForPendingAutoRecord?: ?$ElementType<Scalars, 'String'>,
  isPendingApproval?: ?$ElementType<Scalars, 'Boolean'>,
|};

export const BankProviderCategorizationValues = Object.freeze({
  Personal: 'personal',
  Business: 'business'
});


export type BankProviderCategorization = $Values<typeof BankProviderCategorizationValues>;

export type BankProviderType = {|
  __typename?: 'BankProviderType',
  id: $ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  logoUrl?: ?$ElementType<Scalars, 'String'>,
  connectable?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type BaseReportInterface = {|
  id: $ElementType<Scalars, 'ID'>,
  sentWithSorted?: ?$ElementType<Scalars, 'Boolean'>,
  reportedDate?: ?$ElementType<Scalars, 'Date'>,
  hideOnTimeline?: ?$ElementType<Scalars, 'Boolean'>,
  isSent?: ?$ElementType<Scalars, 'Boolean'>,
  submissionDeadline?: ?$ElementType<Scalars, 'Date'>,
  requiresSubscription?: ?$ElementType<Scalars, 'Boolean'>,
  isFeatureInTrial?: ?$ElementType<Scalars, 'Boolean'>,
  confirmationDocument?: ?DocumentType,
  isCorrection?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type BaseVatReportInterface = {|
  unpaidRecords?: ?RecordTypeConnection,
  invoicesWithoutTaxNumber?: ?RecordTypeConnection,
  expensesWithoutAttributionToVatExemptRevenue?: ?RecordTypeConnection,
|};


export type BaseVatReportInterfaceUnpaidRecordsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type BaseVatReportInterfaceInvoicesWithoutTaxNumberArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type BaseVatReportInterfaceExpensesWithoutAttributionToVatExemptRevenueArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BillCustomerForTaxServiceInput = {|
  serviceBillingId: $ElementType<Scalars, 'ID'>,
  serviceDate: $ElementType<Scalars, 'Date'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type BillCustomerForTaxServicePayload = {|
  __typename?: 'BillCustomerForTaxServicePayload',
  serviceBilling?: ?TaxServiceBillingType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type BusinessBankAccountQuery = {|
  bankConnections?: ?Array<?BankConnectionType>,
  bankAccounts?: ?Array<?BankAccountType>,
  triedToConnectBankAccount?: ?$ElementType<Scalars, 'Boolean'>,
  failedBankConnectionsCount?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessCustomerType = {|
  ...Node,
  ...{|
    __typename?: 'BusinessCustomerType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessType,
    isPrivatePerson: $ElementType<Scalars, 'Boolean'>,
    firstName?: ?$ElementType<Scalars, 'String'>,
    lastName?: ?$ElementType<Scalars, 'String'>,
    businessName?: ?$ElementType<Scalars, 'String'>,
    address: $ElementType<Scalars, 'JSONString'>,
    vatNumber?: ?$ElementType<Scalars, 'String'>,
    email?: ?$ElementType<Scalars, 'String'>,
    invoices: InvoiceTypeConnection,
    displayName?: ?$ElementType<Scalars, 'String'>,
  |}
|};


export type BusinessCustomerTypeInvoicesArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessCustomerTypeConnection = {|
  __typename?: 'BusinessCustomerTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?BusinessCustomerTypeEdge>,
|};

/** A Relay edge containing a `BusinessCustomerType` and its cursor. */
export type BusinessCustomerTypeEdge = {|
  __typename?: 'BusinessCustomerTypeEdge',
  /** The item at the end of the edge */
  node?: ?BusinessCustomerType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export type BusinessPerformanceType = {|
  __typename?: 'BusinessPerformanceType',
  year: $ElementType<Scalars, 'Int'>,
  pnlReport?: ?PnlReportType,
  incomeTaxReport?: ?IncomeTaxReportType,
  annualVatReport?: ?AnnualVatReportType,
  advanceVatReports?: ?Array<?VatReportType>,
  autorecords?: ?AutoRecordTypeConnection,
|};


export type BusinessPerformanceTypeAutorecordsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
  recordType_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  category?: ?$ElementType<Scalars, 'String'>,
  date_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Gte?: ?$ElementType<Scalars, 'Date'>,
  recurringSettingSource_Isnull?: ?$ElementType<Scalars, 'Boolean'>,
  paymentStatus?: ?$ElementType<Scalars, 'String'>,
  search?: ?$ElementType<Scalars, 'String'>,
  dateYear?: ?$ElementType<Scalars, 'Int'>,
  paymentDateOrDateYearLte?: ?$ElementType<Scalars, 'Int'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  idIn?: ?$ElementType<Scalars, 'String'>,
  excludeVatPayments?: ?$ElementType<Scalars, 'Boolean'>,
  recommendedRecordsForPendingAutoRecord?: ?$ElementType<Scalars, 'String'>,
  isPendingApproval?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type BusinessType = {|
  ...Node,
  ...BusinessBankAccountQuery,
  ...{|
    __typename?: 'BusinessType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    owningUser?: ?UserType,
    defaultCurrency: $ElementType<Scalars, 'String'>,
    stripeCustomerId?: ?$ElementType<Scalars, 'String'>,
    completedOnboardingSteps: Array<$ElementType<Scalars, 'String'>>,
    finishedOnboarding: $ElementType<Scalars, 'Boolean'>,
    vatReportsStartDate?: ?$ElementType<Scalars, 'Date'>,
    zmReportStartDate?: ?$ElementType<Scalars, 'Date'>,
    taxNumber?: ?$ElementType<Scalars, 'String'>,
    vatNumber?: ?$ElementType<Scalars, 'String'>,
    address?: ?$ElementType<Scalars, 'JSONString'>,
    registeredTaxState?: ?$ElementType<Scalars, 'String'>,
    name?: ?$ElementType<Scalars, 'String'>,
    profession?: ?$ElementType<Scalars, 'String'>,
    businessLegalType?: ?$ElementType<Scalars, 'String'>,
    assignedTaxAdvisor?: ?TaxAdvisorType,
    taxNeedsOnboardingQuestion?: ?TaxNeeds,
    overrideEmailForReports?: ?$ElementType<Scalars, 'String'>,
    referralCodeReceived?: ?$ElementType<Scalars, 'String'>,
    referralCodeSent?: ?$ElementType<Scalars, 'String'>,
    showTaxCredit: $ElementType<Scalars, 'Boolean'>,
    isSelfEmployedBeforeRegistrationYear?: ?$ElementType<Scalars, 'Boolean'>,
    selfEmploymentStartDate?: ?$ElementType<Scalars, 'Date'>,
    selfEmploymentEndDate?: ?$ElementType<Scalars, 'Date'>,
    reportVatWhenNoRelevantIncomeAsSmallBusinessOwner?: ?$ElementType<Scalars, 'Boolean'>,
    defaultVatExemptionParagraph?: ?VatExemptionParagraph,
    saltedgeCustomerId?: ?$ElementType<Scalars, 'String'>,
    tapfiliateDashboardInitialPassword?: ?$ElementType<Scalars, 'String'>,
    companyName?: ?$ElementType<Scalars, 'String'>,
    documents?: ?DocumentTypeConnection,
    records?: ?RecordTypeConnection,
    autorecords?: ?AutoRecordTypeConnection,
    yearlyConfigs: YearlyConfigTypeConnection,
    experiments?: ?ExperimentTypeConnection,
    vatReports: VatReportTypeConnection,
    pnlReports: PnlReportTypeConnection,
    incomeTaxReports: IncomeTaxReportTypeConnection,
    annualVatReports: AnnualVatReportTypeConnection,
    zmReports: ZmReportTypeConnection,
    taxServiceBillings?: ?TaxServiceBillingTypeConnection,
    taxRegistration?: ?TaxRegistrationType,
    invoicePaymentMethods?: ?InvoicePaymentMethodTypeConnection,
    customers?: ?BusinessCustomerTypeConnection,
    invoiceSettings?: ?InvoiceSettingsType,
    invoices: InvoiceTypeConnection,
    unsupportedBankAccounts?: ?UnsupportedBankAccountTypeConnection,
    bankConnections?: ?Array<?BankConnectionType>,
    bankAccounts?: ?Array<?BankAccountType>,
    triedToConnectBankAccount?: ?$ElementType<Scalars, 'Boolean'>,
    failedBankConnectionsCount?: ?$ElementType<Scalars, 'Int'>,
    isAlertDismissed?: ?$ElementType<Scalars, 'Boolean'>,
    addressForDisplay?: ?$ElementType<Scalars, 'String'>,
    taxOfficeId?: ?$ElementType<Scalars, 'String'>,
    taxOffice?: ?TaxOfficeType,
    latestYearlyConfig?: ?YearlyConfigType,
    pnlReport?: ?PnlReportType,
    shouldShowSampleDocumentButtonExpense?: ?$ElementType<Scalars, 'Boolean'>,
    shouldShowSampleDocumentButtonIncome?: ?$ElementType<Scalars, 'Boolean'>,
    submittedAdvanceVatReport?: ?$ElementType<Scalars, 'Boolean'>,
    submittedZmReport?: ?$ElementType<Scalars, 'Boolean'>,
    registeredWithSorted?: ?$ElementType<Scalars, 'Boolean'>,
    businessPerformance?: ?BusinessPerformanceType,
    businessVatExemptionParagraphs?: ?Array<?VatExemptionParagraph>,
    taxRegistrationForMainPage?: ?TaxRegistrationType,
  |}
|};


export type BusinessTypeAssignedTaxAdvisorArgs = {|
  setDefaultLanguage?: ?$ElementType<Scalars, 'String'>,
|};


export type BusinessTypeDocumentsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type BusinessTypeRecordsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
  recordType_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  category?: ?$ElementType<Scalars, 'String'>,
  date_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Gte?: ?$ElementType<Scalars, 'Date'>,
  recurringSettingSource_Isnull?: ?$ElementType<Scalars, 'Boolean'>,
  paymentStatus?: ?$ElementType<Scalars, 'String'>,
  search?: ?$ElementType<Scalars, 'String'>,
  dateYear?: ?$ElementType<Scalars, 'Int'>,
  paymentDateOrDateYearLte?: ?$ElementType<Scalars, 'Int'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  idIn?: ?$ElementType<Scalars, 'String'>,
  excludeVatPayments?: ?$ElementType<Scalars, 'Boolean'>,
  recommendedRecordsForPendingAutoRecord?: ?$ElementType<Scalars, 'String'>,
|};


export type BusinessTypeAutorecordsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
  recordType_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  category?: ?$ElementType<Scalars, 'String'>,
  date_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Gte?: ?$ElementType<Scalars, 'Date'>,
  recurringSettingSource_Isnull?: ?$ElementType<Scalars, 'Boolean'>,
  paymentStatus?: ?$ElementType<Scalars, 'String'>,
  search?: ?$ElementType<Scalars, 'String'>,
  dateYear?: ?$ElementType<Scalars, 'Int'>,
  paymentDateOrDateYearLte?: ?$ElementType<Scalars, 'Int'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  idIn?: ?$ElementType<Scalars, 'String'>,
  excludeVatPayments?: ?$ElementType<Scalars, 'Boolean'>,
  recommendedRecordsForPendingAutoRecord?: ?$ElementType<Scalars, 'String'>,
  isPendingApproval?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type BusinessTypeYearlyConfigsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type BusinessTypeExperimentsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type BusinessTypeVatReportsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type BusinessTypePnlReportsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type BusinessTypeIncomeTaxReportsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type BusinessTypeAnnualVatReportsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type BusinessTypeZmReportsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type BusinessTypeTaxServiceBillingsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  state?: ?ServiceFeeState,
  state_In?: ?Array<?ServiceFeeState>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};


export type BusinessTypeInvoicePaymentMethodsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  isDefault?: ?$ElementType<Scalars, 'Boolean'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};


export type BusinessTypeCustomersArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type BusinessTypeInvoicesArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type BusinessTypeUnsupportedBankAccountsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type BusinessTypeIsAlertDismissedArgs = {|
  alertName?: ?$ElementType<Scalars, 'String'>,
|};


export type BusinessTypeLatestYearlyConfigArgs = {|
  upToYear?: ?$ElementType<Scalars, 'Int'>,
|};


export type BusinessTypePnlReportArgs = {|
  year: $ElementType<Scalars, 'Int'>,
|};


export type BusinessTypeBusinessPerformanceArgs = {|
  year?: ?$ElementType<Scalars, 'Int'>,
|};

export type BusinessTypeConnection = {|
  __typename?: 'BusinessTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?BusinessTypeEdge>,
|};

/** A Relay edge containing a `BusinessType` and its cursor. */
export type BusinessTypeEdge = {|
  __typename?: 'BusinessTypeEdge',
  /** The item at the end of the edge */
  node?: ?BusinessType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export type CancelTaxServiceBillingInput = {|
  serviceBillingId: $ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type CancelTaxServiceBillingPayload = {|
  __typename?: 'CancelTaxServiceBillingPayload',
  serviceBilling?: ?TaxServiceBillingType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type ColumnInfoType = {|
  __typename?: 'ColumnInfoType',
  date?: ?DateColumnSelection,
|};

export type CreditCard = {|
  __typename?: 'CreditCard',
  brand?: ?$ElementType<Scalars, 'String'>,
  last4Digits?: ?$ElementType<Scalars, 'String'>,
|};

export type Currency = {|
  __typename?: 'Currency',
  name?: ?$ElementType<Scalars, 'String'>,
  code?: ?$ElementType<Scalars, 'String'>,
  symbol?: ?$ElementType<Scalars, 'String'>,
|};


export const DateColumnSelectionValues = Object.freeze({
  DayFirst: 'day_first',
  MonthFirst: 'month_first',
  Unknown: 'unknown'
});


export type DateColumnSelection = $Values<typeof DateColumnSelectionValues>;






export type DeleteAnnualPayslipInput = {|
  annualPayslipId: $ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteAnnualPayslipPayload = {|
  __typename?: 'DeleteAnnualPayslipPayload',
  deletedAnnualPayslipId?: ?$ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteAnnualVatReportInput = {|
  id: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteAnnualVatReportPayload = {|
  __typename?: 'DeleteAnnualVatReportPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteBankConnectionInput = {|
  connectionId: $ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteBankConnectionPayload = {|
  __typename?: 'DeleteBankConnectionPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteBusinessCustomerInput = {|
  customerId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteBusinessCustomerPayload = {|
  __typename?: 'DeleteBusinessCustomerPayload',
  deletedCustomerId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteDocumentInput = {|
  documentId: $ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteDocumentPayload = {|
  __typename?: 'DeleteDocumentPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteIncomeTaxReportInput = {|
  id: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteIncomeTaxReportPayload = {|
  __typename?: 'DeleteIncomeTaxReportPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteInvoiceLogoInput = {|
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteInvoiceLogoPayload = {|
  __typename?: 'DeleteInvoiceLogoPayload',
  invoiceLogo?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteInvoicePaymentMethodInput = {|
  paymentMethodId: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteInvoicePaymentMethodPayload = {|
  __typename?: 'DeleteInvoicePaymentMethodPayload',
  deletedPaymentMethodId?: ?$ElementType<Scalars, 'String'>,
  defaultPaymentMethod?: ?InvoicePaymentMethodType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeletePnlReportInput = {|
  id: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeletePnlReportPayload = {|
  __typename?: 'DeletePnlReportPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteRecordInput = {|
  recordId: $ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteRecordPayload = {|
  __typename?: 'DeleteRecordPayload',
  deletedRecordId?: ?$ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteTaxRegistrationInput = {|
  id: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteTaxRegistrationPayload = {|
  __typename?: 'DeleteTaxRegistrationPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteUserAccountInput = {|
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteUserAccountPayload = {|
  __typename?: 'DeleteUserAccountPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteVatReportInput = {|
  id: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteVatReportPayload = {|
  __typename?: 'DeleteVatReportPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteYearlyChildInfoInput = {|
  yearlyChildInfoId: $ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteYearlyChildInfoPayload = {|
  __typename?: 'DeleteYearlyChildInfoPayload',
  deletedYearlyChildInfoId?: ?$ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteZmReportInput = {|
  id: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DeleteZmReportPayload = {|
  __typename?: 'DeleteZMReportPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DismissAlertInput = {|
  name: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DismissAlertPayload = {|
  __typename?: 'DismissAlertPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type DocumentType = {|
  ...Node,
  ...{|
    __typename?: 'DocumentType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessType,
    rawExtractedFields?: ?$ElementType<Scalars, 'JSONString'>,
    ocrText: $ElementType<Scalars, 'String'>,
    records: RecordTypeConnection,
    autorecords: AutoRecordTypeConnection,
    vatreportSet: VatReportTypeConnection,
    pnlreportSet: PnlReportTypeConnection,
    incometaxreportSet: IncomeTaxReportTypeConnection,
    annualvatreportSet: AnnualVatReportTypeConnection,
    zmreportSet: ZmReportTypeConnection,
    taxregistrationSet: TaxRegistrationTypeConnection,
    invoices: InvoiceTypeConnection,
    documentUrl?: ?$ElementType<Scalars, 'String'>,
    thumbnailUrl?: ?$ElementType<Scalars, 'String'>,
    bigThumbnailUrl?: ?$ElementType<Scalars, 'String'>,
    autoExtractedRecord?: ?AutoRecordType,
  |}
|};


export type DocumentTypeRecordsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type DocumentTypeAutorecordsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type DocumentTypeVatreportSetArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type DocumentTypePnlreportSetArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type DocumentTypeIncometaxreportSetArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type DocumentTypeAnnualvatreportSetArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type DocumentTypeZmreportSetArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type DocumentTypeTaxregistrationSetArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type DocumentTypeInvoicesArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type DocumentTypeConnection = {|
  __typename?: 'DocumentTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?DocumentTypeEdge>,
|};

/** A Relay edge containing a `DocumentType` and its cursor. */
export type DocumentTypeEdge = {|
  __typename?: 'DocumentTypeEdge',
  /** The item at the end of the edge */
  node?: ?DocumentType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export const ExpenseAttributionToVatExemptRevenueValues = Object.freeze({
  VatExemptionRelated: 'vat_exemption_related',
  NonVatExemptionRelated: 'non_vat_exemption_related',
  PartiallyVatExemptionRelated: 'partially_vat_exemption_related'
});


export type ExpenseAttributionToVatExemptRevenue = $Values<typeof ExpenseAttributionToVatExemptRevenueValues>;

export const ExpenseCategoryValues = Object.freeze({
  Tools: 'tools',
  Software: 'software',
  Communication: 'communication',
  TransportationTicket: 'transportation_ticket',
  Rent: 'rent',
  BusinessTripMealAllowance: 'business_trip_meal_allowance',
  Entertainment: 'entertainment',
  BusinessTripAccommodation: 'business_trip_accommodation',
  Utilities: 'utilities',
  Training: 'training',
  Materials: 'materials',
  Contractors: 'contractors',
  LegalAndAccounting: 'legal_and_accounting',
  PrivateCarUsage: 'private_car_usage',
  Advertising: 'advertising',
  Insurance: 'insurance',
  Gift: 'gift',
  VatPaymentToTaxOffice: 'vat_payment_to_tax_office',
  CoronaAid: 'corona_aid',
  Other: 'other'
});


export type ExpenseCategory = $Values<typeof ExpenseCategoryValues>;

export type ExpenseSummaryLine = {|
  __typename?: 'ExpenseSummaryLine',
  category?: ?$ElementType<Scalars, 'String'>,
  deductibleAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
  recordIds?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

export type ExpenseSummaryType = {|
  __typename?: 'ExpenseSummaryType',
  expenseSummaryLines?: ?Array<?ExpenseSummaryLine>,
  total?: ?$ElementType<Scalars, 'DecimalScalar'>,
|};

export const ExperimentNameValues = Object.freeze({
  /** Trial For Vat */
  TrialForVatNewFreelancers: 'TRIAL_FOR_VAT_NEW_FREELANCERS',
  /** Invoicing Tool */
  InvoicingTool: 'INVOICING_TOOL',
  /** Tax Consultant Button */
  TaxConsultantMenuButton: 'TAX_CONSULTANT_MENU_BUTTON',
  /** New Main Page */
  NewMainPage: 'NEW_MAIN_PAGE',
  /** Bank Account Integration */
  BankAccountIntegration: 'BANK_ACCOUNT_INTEGRATION',
  /** Create You First Record */
  CreateYourFirstRecord: 'CREATE_YOUR_FIRST_RECORD',
  /** Onboarding Tour */
  OnboardingTour: 'ONBOARDING_TOUR'
});


export type ExperimentName = $Values<typeof ExperimentNameValues>;

export type ExperimentType = {|
  ...Node,
  ...{|
    __typename?: 'ExperimentType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessType,
    name: ExperimentName,
  |}
|};

export type ExperimentTypeConnection = {|
  __typename?: 'ExperimentTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?ExperimentTypeEdge>,
|};

/** A Relay edge containing a `ExperimentType` and its cursor. */
export type ExperimentTypeEdge = {|
  __typename?: 'ExperimentTypeEdge',
  /** The item at the end of the edge */
  node?: ?ExperimentType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export type ExportAccountDataInput = {|
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type ExportAccountDataPayload = {|
  __typename?: 'ExportAccountDataPayload',
  exportId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type GetLatestDataExportInput = {|
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type GetLatestDataExportPayload = {|
  __typename?: 'GetLatestDataExportPayload',
  exportDownloadUrl?: ?$ElementType<Scalars, 'String'>,
  exportFileName?: ?$ElementType<Scalars, 'String'>,
  exportGeneratedAt?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type ImportDatevFileInput = {|
  document: $ElementType<Scalars, 'String'>,
  inputChannel: InputChannelEnum,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type ImportDatevFilePayload = {|
  __typename?: 'ImportDatevFilePayload',
  expensesApproved?: ?$ElementType<Scalars, 'Int'>,
  expensesPendingApproval?: ?$ElementType<Scalars, 'Int'>,
  incomeRecordsApproved?: ?$ElementType<Scalars, 'Int'>,
  incomeRecordsPendingApproval?: ?$ElementType<Scalars, 'Int'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type ImportSpreadsheetInput = {|
  document: $ElementType<Scalars, 'String'>,
  documentKey: $ElementType<Scalars, 'String'>,
  inputChannel: InputChannelEnum,
  importType: ImportType,
  worksheetIndex: $ElementType<Scalars, 'Int'>,
  headerRow: $ElementType<Scalars, 'Int'>,
  amountColumn: $ElementType<Scalars, 'Int'>,
  currencyColumn: $ElementType<Scalars, 'Int'>,
  dateColumn: $ElementType<Scalars, 'Int'>,
  dateDayFirst: $ElementType<Scalars, 'Boolean'>,
  descriptionColumns?: ?Array<?$ElementType<Scalars, 'Int'>>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type ImportSpreadsheetPayload = {|
  __typename?: 'ImportSpreadsheetPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export const ImportTypeValues = Object.freeze({
  Income: 'income',
  Expenses: 'expenses',
  IncomeAndExpenses: 'income_and_expenses'
});


export type ImportType = $Values<typeof ImportTypeValues>;

export type IncomeLineType = {|
  __typename?: 'IncomeLineType',
  amount?: ?$ElementType<Scalars, 'DecimalScalar'>,
  recordIds?: ?Array<?$ElementType<Scalars, 'String'>>,
  category?: ?$ElementType<Scalars, 'String'>,
|};

export type IncomeSummaryType = {|
  __typename?: 'IncomeSummaryType',
  total?: ?$ElementType<Scalars, 'DecimalScalar'>,
  incomeLines?: ?Array<?IncomeLineType>,
|};

export type IncomeTaxReportPartnerType = {|
  __typename?: 'IncomeTaxReportPartnerType',
  incomeTaxReport?: ?IncomeTaxReportType,
  annualPayslips?: ?AnnualPayslipTypeConnection,
  hasIncomeFromEmployment?: ?$ElementType<Scalars, 'Boolean'>,
  totalIncomeFromEmployment?: ?$ElementType<Scalars, 'DecimalScalar'>,
  totalEmployeeContributionsToPension?: ?$ElementType<Scalars, 'DecimalScalar'>,
  totalEmployeeContributionsToPublicHealthInsurance?: ?$ElementType<Scalars, 'DecimalScalar'>,
  totalEmployeeContributionsToPublicCareInsurance?: ?$ElementType<Scalars, 'DecimalScalar'>,
  totalEmployeeContributionsToUnemploymentInsurance?: ?$ElementType<Scalars, 'DecimalScalar'>,
  totalEmployeeContributionsToPrivateHealthAndCareInsurance?: ?$ElementType<Scalars, 'DecimalScalar'>,
  totalEmployeeAdvancePayments?: ?$ElementType<Scalars, 'DecimalScalar'>,
  profitFromSelfEmployment?: ?$ElementType<Scalars, 'DecimalScalar'>,
|};


export type IncomeTaxReportPartnerTypeAnnualPayslipsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type IncomeTaxReportType = {|
  ...Node,
  ...BaseReportInterface,
  ...ReportPeriodInterface,
  ...{|
    __typename?: 'IncomeTaxReportType',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    reportedDate?: ?$ElementType<Scalars, 'Date'>,
    sentWithSorted?: ?$ElementType<Scalars, 'Boolean'>,
    isCorrection?: ?$ElementType<Scalars, 'Boolean'>,
    business: BusinessType,
    year: $ElementType<Scalars, 'Int'>,
    data?: ?$ElementType<Scalars, 'JSONString'>,
    pagesValidityRaw?: ?$ElementType<Scalars, 'JSONString'>,
    confirmationDocument?: ?DocumentType,
    shouldStartChargingVatNextYear?: ?$ElementType<Scalars, 'Boolean'>,
    prepaymentAmounts?: ?$ElementType<Scalars, 'JSONString'>,
    sentToTaxAdvisor?: ?$ElementType<Scalars, 'Boolean'>,
    sentToTaxAdvisorOnDate?: ?$ElementType<Scalars, 'DateTime'>,
    children: YearlyChildInfoTypeConnection,
    annualPayslips: AnnualPayslipTypeConnection,
    hideOnTimeline?: ?$ElementType<Scalars, 'Boolean'>,
    isSent?: ?$ElementType<Scalars, 'Boolean'>,
    submissionDeadline?: ?$ElementType<Scalars, 'Date'>,
    requiresSubscription?: ?$ElementType<Scalars, 'Boolean'>,
    isFeatureInTrial?: ?$ElementType<Scalars, 'Boolean'>,
    reportingInterval?: ?VatReportingInterval,
    submitUrgencyLevel?: ?ReportSubmitUrgencyLevel,
    allowSubmission?: ?$ElementType<Scalars, 'Boolean'>,
    periodStartDate?: ?$ElementType<Scalars, 'Date'>,
    mainPartner?: ?IncomeTaxReportPartnerType,
    spouse?: ?IncomeTaxReportPartnerType,
    isJointAssessment?: ?$ElementType<Scalars, 'Boolean'>,
    totalIncome?: ?$ElementType<Scalars, 'DecimalScalar'>,
    totalEmployeeAdvancePayments?: ?$ElementType<Scalars, 'DecimalScalar'>,
    estimatedTaxAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    taxableItems?: ?$ElementType<Scalars, 'JSONString'>,
    taxComponentAmounts?: ?$ElementType<Scalars, 'JSONString'>,
    ratedNonTaxableIncomeAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    sumOfTaxableIncome?: ?$ElementType<Scalars, 'DecimalScalar'>,
    totalTaxableExpenses?: ?$ElementType<Scalars, 'DecimalScalar'>,
    totalTaxPayments?: ?$ElementType<Scalars, 'DecimalScalar'>,
    totalTaxPaid?: ?$ElementType<Scalars, 'DecimalScalar'>,
    remainingTaxToBePaid?: ?$ElementType<Scalars, 'DecimalScalar'>,
    isFormValid?: ?$ElementType<Scalars, 'Boolean'>,
    isFormValidExceptIncomeFromPnl?: ?$ElementType<Scalars, 'Boolean'>,
    pageData?: ?$ElementType<Scalars, 'JSONString'>,
    pnlReport?: ?PnlReportType,
    annualVatReport?: ?AnnualVatReportType,
    pagesValidity?: ?$ElementType<Scalars, 'JSONString'>,
    paidAddOns?: ?$ElementType<Scalars, 'JSONString'>,
    shouldAskIfReportSubmittedAlready?: ?$ElementType<Scalars, 'Boolean'>,
  |}
|};


export type IncomeTaxReportTypeChildrenArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type IncomeTaxReportTypeAnnualPayslipsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type IncomeTaxReportTypePageDataArgs = {|
  pageName?: ?$ElementType<Scalars, 'String'>,
|};

export type IncomeTaxReportTypeConnection = {|
  __typename?: 'IncomeTaxReportTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?IncomeTaxReportTypeEdge>,
|};

/** A Relay edge containing a `IncomeTaxReportType` and its cursor. */
export type IncomeTaxReportTypeEdge = {|
  __typename?: 'IncomeTaxReportTypeEdge',
  /** The item at the end of the edge */
  node?: ?IncomeTaxReportType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export const InputChannelEnumValues = Object.freeze({
  Unknown: 'unknown',
  Lexoffice: 'lexoffice',
  Debitoor: 'debitoor',
  Sevdesk: 'sevdesk',
  Excel: 'excel',
  GoogleSheets: 'googleSheets',
  Dropbox: 'dropbox',
  GoogleDrive: 'googleDrive',
  OneDrive: 'oneDrive',
  DocumentUpload: 'documentUpload',
  InvoicingTool: 'invoicingTool',
  Manually: 'manually',
  Stripe: 'stripe',
  TaxServicesInvoice: 'taxServicesInvoice',
  Recurring: 'recurring',
  Elster: 'elster',
  ElsterPreview: 'elsterPreview',
  TaxAdvisor: 'taxAdvisor',
  Bank: 'bank'
});


export type InputChannelEnum = $Values<typeof InputChannelEnumValues>;

export type IntroduceAddCreditCardInput = {|
  stripePaymentMethodId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceAddCreditCardPayload = {|
  __typename?: 'IntroduceAddCreditCardPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceAnnualPayslipInput = {|
  amounts: $ElementType<Scalars, 'JSONString'>,
  startDate?: ?$ElementType<Scalars, 'Date'>,
  endDate?: ?$ElementType<Scalars, 'Date'>,
  taxClass?: ?$ElementType<Scalars, 'String'>,
  incomeTaxReportYear: $ElementType<Scalars, 'ID'>,
  annualPayslipId?: ?$ElementType<Scalars, 'ID'>,
  isMainPartner: $ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceAnnualPayslipPayload = {|
  __typename?: 'IntroduceAnnualPayslipPayload',
  annualPayslip?: ?AnnualPayslipType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceAnnualReportsStartYearInput = {|
  startYear: $ElementType<Scalars, 'Int'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceAnnualReportsStartYearPayload = {|
  __typename?: 'IntroduceAnnualReportsStartYearPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceAnonymousLoginInput = {|
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceAnonymousLoginPayload = {|
  __typename?: 'IntroduceAnonymousLoginPayload',
  token?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBankConnectionInput = {|
  bankName: $ElementType<Scalars, 'String'>,
  isBusinessAccount: $ElementType<Scalars, 'Boolean'>,
  returnTo: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBankConnectionPayload = {|
  __typename?: 'IntroduceBankConnectionPayload',
  redirectUrl?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBusinessCustomerInput = {|
  customerId?: ?$ElementType<Scalars, 'String'>,
  isPrivatePerson: $ElementType<Scalars, 'Boolean'>,
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  businessName?: ?$ElementType<Scalars, 'String'>,
  address: $ElementType<Scalars, 'JSONString'>,
  vatNumber?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBusinessCustomerPayload = {|
  __typename?: 'IntroduceBusinessCustomerPayload',
  customer?: ?BusinessCustomerType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBusinessProfileInput = {|
  name?: ?$ElementType<Scalars, 'String'>,
  companyName?: ?$ElementType<Scalars, 'String'>,
  taxNumber?: ?$ElementType<Scalars, 'String'>,
  vatNumber?: ?$ElementType<Scalars, 'String'>,
  registeredTaxState?: ?$ElementType<Scalars, 'String'>,
  address?: ?$ElementType<Scalars, 'JSONString'>,
  profession?: ?$ElementType<Scalars, 'String'>,
  businessLegalType?: ?$ElementType<Scalars, 'String'>,
  isSelfEmployedBeforeRegistrationYear?: ?$ElementType<Scalars, 'Boolean'>,
  selfEmploymentStartDate?: ?$ElementType<Scalars, 'Date'>,
  selfEmploymentEndDate?: ?$ElementType<Scalars, 'Date'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceBusinessProfilePayload = {|
  __typename?: 'IntroduceBusinessProfilePayload',
  business?: ?BusinessType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceCompletedOnboardingStepInput = {|
  stepName?: ?OnboardingStepsEnum,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceCompletedOnboardingStepPayload = {|
  __typename?: 'IntroduceCompletedOnboardingStepPayload',
  business?: ?BusinessType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceCustomTaxServiceBillingInput = {|
  netPrice: $ElementType<Scalars, 'DecimalScalar'>,
  description: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceCustomTaxServiceBillingPayload = {|
  __typename?: 'IntroduceCustomTaxServiceBillingPayload',
  serviceBilling?: ?TaxServiceBillingType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceDocumentInput = {|
  document: $ElementType<Scalars, 'JSONString'>,
  recordId?: ?$ElementType<Scalars, 'String'>,
  markAutoRecordPending?: ?$ElementType<Scalars, 'Boolean'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
  inputChannel: InputChannelEnum,
  fileName?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceDocumentPayload = {|
  __typename?: 'IntroduceDocumentPayload',
  document?: ?DocumentType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceIncomeStatementDocumentInput = {|
  year: $ElementType<Scalars, 'Int'>,
  document: $ElementType<Scalars, 'JSONString'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceIncomeStatementDocumentPayload = {|
  __typename?: 'IntroduceIncomeStatementDocumentPayload',
  incomeTaxReport?: ?IncomeTaxReportType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceIncomeStatementPreparationRequestInput = {|
  year: $ElementType<Scalars, 'Int'>,
  messageBody: $ElementType<Scalars, 'String'>,
  autoResponse: $ElementType<Scalars, 'String'>,
  netPrice: $ElementType<Scalars, 'DecimalScalar'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceIncomeStatementPreparationRequestPayload = {|
  __typename?: 'IntroduceIncomeStatementPreparationRequestPayload',
  incomeTaxReport?: ?IncomeTaxReportType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceInvoiceInput = {|
  document: $ElementType<Scalars, 'JSONString'>,
  customerId: $ElementType<Scalars, 'String'>,
  paymentMethodId?: ?$ElementType<Scalars, 'String'>,
  invoiceNumber?: ?$ElementType<Scalars, 'String'>,
  invoiceOrdinalNumber?: ?$ElementType<Scalars, 'Int'>,
  date?: ?$ElementType<Scalars, 'String'>,
  serviceDateFrom?: ?$ElementType<Scalars, 'Date'>,
  serviceDateUntil?: ?$ElementType<Scalars, 'Date'>,
  dueDate?: ?$ElementType<Scalars, 'Date'>,
  currency?: ?$ElementType<Scalars, 'String'>,
  lineItems?: ?Array<?InvoiceLineItemInputType>,
  isServiceOrGoods?: ?SaleType,
  languageCode?: ?Languages,
  vatRate?: ?$ElementType<Scalars, 'Float'>,
  vatExemptionParagraph?: ?VatExemptionParagraph,
  headerType?: ?$ElementType<Scalars, 'String'>,
  themeColor?: ?$ElementType<Scalars, 'String'>,
  isTaxNumberMissing?: ?$ElementType<Scalars, 'Boolean'>,
  personalNote?: ?$ElementType<Scalars, 'String'>,
  paymentStatus?: ?$ElementType<Scalars, 'String'>,
  paymentMonth?: ?$ElementType<Scalars, 'Date'>,
  description?: ?$ElementType<Scalars, 'String'>,
  editInvoiceId?: ?$ElementType<Scalars, 'String'>,
  paymentAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceInvoiceLogoInput = {|
  logo: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceInvoiceLogoPayload = {|
  __typename?: 'IntroduceInvoiceLogoPayload',
  invoiceLogo?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceInvoicePayload = {|
  __typename?: 'IntroduceInvoicePayload',
  invoice?: ?InvoiceType,
  document?: ?DocumentType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceInvoicePaymentMethodInput = {|
  paymentMethodId?: ?$ElementType<Scalars, 'String'>,
  paymentMethodType?: ?PaymentMethod,
  name?: ?$ElementType<Scalars, 'String'>,
  iban?: ?$ElementType<Scalars, 'String'>,
  accountNumber?: ?$ElementType<Scalars, 'String'>,
  bic?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  text?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceInvoicePaymentMethodPayload = {|
  __typename?: 'IntroduceInvoicePaymentMethodPayload',
  paymentMethod?: ?InvoicePaymentMethodType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceInvoiceSettingsInput = {|
  website?: ?$ElementType<Scalars, 'String'>,
  phone?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  invoiceNumberPrefix?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceInvoiceSettingsPayload = {|
  __typename?: 'IntroduceInvoiceSettingsPayload',
  invoiceSettings?: ?InvoiceSettingsType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceLinkingInput = {|
  pendingAutoRecordId: $ElementType<Scalars, 'ID'>,
  linkToRecordId: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceLinkingPayload = {|
  __typename?: 'IntroduceLinkingPayload',
  pendingAutoRecordId?: ?$ElementType<Scalars, 'ID'>,
  deletedRecordId?: ?$ElementType<Scalars, 'ID'>,
  record?: ?RecordType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceNewSubscriptionPlanInput = {|
  plan?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceNewSubscriptionPlanPayload = {|
  __typename?: 'IntroduceNewSubscriptionPlanPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceRecordInput = {|
  recordType: $ElementType<Scalars, 'String'>,
  date?: ?$ElementType<Scalars, 'Date'>,
  grossAmount: $ElementType<Scalars, 'DecimalScalar'>,
  vat?: ?$ElementType<Scalars, 'JSONString'>,
  currency: $ElementType<Scalars, 'String'>,
  category?: ?$ElementType<Scalars, 'String'>,
  description?: ?$ElementType<Scalars, 'String'>,
  documentId?: ?$ElementType<Scalars, 'String'>,
  recordId?: ?$ElementType<Scalars, 'String'>,
  isCustomerPrivatePerson?: ?$ElementType<Scalars, 'Boolean'>,
  counterPartyCountry?: ?$ElementType<Scalars, 'String'>,
  counterPartyVatNumber?: ?$ElementType<Scalars, 'String'>,
  expectedLocalVatRate?: ?$ElementType<Scalars, 'String'>,
  otherTaxesCharged?: ?$ElementType<Scalars, 'Boolean'>,
  vatDeductiblePortion?: ?$ElementType<Scalars, 'DecimalScalar'>,
  netDeductiblePortion?: ?$ElementType<Scalars, 'DecimalScalar'>,
  isHomeOffice?: ?$ElementType<Scalars, 'DecimalScalar'>,
  flatRateExpenseStartDate?: ?$ElementType<Scalars, 'Date'>,
  flatRateExpenseEndDate?: ?$ElementType<Scalars, 'Date'>,
  privateCarDistanceDriven?: ?$ElementType<Scalars, 'Int'>,
  privateCarUsageType?: ?$ElementType<Scalars, 'String'>,
  privateCarDriveToWorkTotalDays?: ?$ElementType<Scalars, 'Int'>,
  businessTripMealAccommodationCountry?: ?$ElementType<Scalars, 'String'>,
  isServiceOrGoods?: ?$ElementType<Scalars, 'String'>,
  assetLifetimeValue?: ?$ElementType<Scalars, 'Int'>,
  paymentStatus?: ?$ElementType<Scalars, 'String'>,
  paymentMonth?: ?$ElementType<Scalars, 'Date'>,
  repeatInterval?: ?$ElementType<Scalars, 'String'>,
  recurringSettingSource?: ?$ElementType<Scalars, 'String'>,
  pendingAutoRecordId?: ?$ElementType<Scalars, 'ID'>,
  vatExemptionParagraph?: ?VatExemptionParagraph,
  expenseAttributionToVatExemptRevenue?: ?ExpenseAttributionToVatExemptRevenue,
  vatPaymentPeriodInterval?: ?VatReportingInterval,
  reverseChargeNetAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
  reverseChargeApply?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceRecordPayload = {|
  __typename?: 'IntroduceRecordPayload',
  record?: ?RecordType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceReferralCodeReceivedInput = {|
  referralCode: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceReferralCodeReceivedPayload = {|
  __typename?: 'IntroduceReferralCodeReceivedPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceSampleDocumentInput = {|
  recordType: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceSampleDocumentPayload = {|
  __typename?: 'IntroduceSampleDocumentPayload',
  document?: ?DocumentType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceSubscriptionCanceledInput = {|
  reasonType: UnsubscribeReason,
  reasonText?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceSubscriptionCanceledPayload = {|
  __typename?: 'IntroduceSubscriptionCanceledPayload',
  canceledSubscription?: ?Subscription,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceSubscriptionCheckoutSessionInput = {|
  plan: $ElementType<Scalars, 'String'>,
  successUrl: $ElementType<Scalars, 'String'>,
  cancelUrl: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceSubscriptionCheckoutSessionPayload = {|
  __typename?: 'IntroduceSubscriptionCheckoutSessionPayload',
  checkoutSessionId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceSubscriptionResumedInput = {|
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceSubscriptionResumedPayload = {|
  __typename?: 'IntroduceSubscriptionResumedPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceTaxNeedsOnboardingQuestionInput = {|
  taxNeeds: TaxNeeds,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceTaxNeedsOnboardingQuestionPayload = {|
  __typename?: 'IntroduceTaxNeedsOnboardingQuestionPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceTaxRequestInput = {|
  type: TaxServiceType,
  messageBody?: ?$ElementType<Scalars, 'String'>,
  autoResponse?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceTaxRequestPayload = {|
  __typename?: 'IntroduceTaxRequestPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceUnsupportedBankAccountInput = {|
  bankName: $ElementType<Scalars, 'String'>,
  isBusinessAccount: $ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceUnsupportedBankAccountPayload = {|
  __typename?: 'IntroduceUnsupportedBankAccountPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceUpdateCreditCardInput = {|
  stripePaymentMethodId?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceUpdateCreditCardPayload = {|
  __typename?: 'IntroduceUpdateCreditCardPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceUserSettingsInput = {|
  languageCode: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceUserSettingsPayload = {|
  __typename?: 'IntroduceUserSettingsPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceVatSettingsInput = {|
  year: $ElementType<Scalars, 'Int'>,
  shouldPayVat: $ElementType<Scalars, 'Boolean'>,
  vatReportingInterval?: ?$ElementType<Scalars, 'String'>,
  vatExemptionParagraph?: ?VatExemptionParagraph,
  vatReportsStartDate?: ?$ElementType<Scalars, 'Date'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceVatSettingsPayload = {|
  __typename?: 'IntroduceVatSettingsPayload',
  yearlyConfig?: ?YearlyConfigType,
  yearlyConfigEdge?: ?YearlyConfigTypeEdge,
  business?: ?BusinessType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceYearlyChildInfoInput = {|
  data: $ElementType<Scalars, 'JSONString'>,
  incomeTaxReportYear: $ElementType<Scalars, 'Int'>,
  yearlyChildInfoId?: ?$ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type IntroduceYearlyChildInfoPayload = {|
  __typename?: 'IntroduceYearlyChildInfoPayload',
  yearlyChildInfo?: ?YearlyChildInfoType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type InviteFriendsInput = {|
  emails?: ?Array<?$ElementType<Scalars, 'String'>>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type InviteFriendsPayload = {|
  __typename?: 'InviteFriendsPayload',
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type InvoiceLineItemInputType = {|
  description?: ?$ElementType<Scalars, 'String'>,
  unitPrice?: ?$ElementType<Scalars, 'DecimalScalar'>,
  total?: ?$ElementType<Scalars, 'DecimalScalar'>,
  unit?: ?ItemUnit,
  quantity?: ?$ElementType<Scalars, 'DecimalScalar'>,
  vatRate?: ?$ElementType<Scalars, 'DecimalScalar'>,
|};

export type InvoiceLineItemType = {|
  ...Node,
  ...{|
    __typename?: 'InvoiceLineItemType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    description: $ElementType<Scalars, 'String'>,
    unitPrice?: ?$ElementType<Scalars, 'DecimalScalar'>,
    unit?: ?ItemUnit,
    quantity?: ?$ElementType<Scalars, 'DecimalScalar'>,
    invoice: InvoiceType,
    total?: ?$ElementType<Scalars, 'DecimalScalar'>,
  |}
|};

export type InvoiceLineItemTypeConnection = {|
  __typename?: 'InvoiceLineItemTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?InvoiceLineItemTypeEdge>,
|};

/** A Relay edge containing a `InvoiceLineItemType` and its cursor. */
export type InvoiceLineItemTypeEdge = {|
  __typename?: 'InvoiceLineItemTypeEdge',
  /** The item at the end of the edge */
  node?: ?InvoiceLineItemType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export type InvoicePaymentMethodType = {|
  ...Node,
  ...{|
    __typename?: 'InvoicePaymentMethodType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessType,
    paymentMethodType?: ?PaymentMethod,
    isDefault: $ElementType<Scalars, 'Boolean'>,
    name?: ?$ElementType<Scalars, 'String'>,
    iban?: ?$ElementType<Scalars, 'String'>,
    accountNumber?: ?$ElementType<Scalars, 'String'>,
    bic?: ?$ElementType<Scalars, 'String'>,
    email?: ?$ElementType<Scalars, 'String'>,
    text?: ?$ElementType<Scalars, 'String'>,
    invoices: InvoiceTypeConnection,
  |}
|};


export type InvoicePaymentMethodTypeInvoicesArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type InvoicePaymentMethodTypeConnection = {|
  __typename?: 'InvoicePaymentMethodTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?InvoicePaymentMethodTypeEdge>,
|};

/** A Relay edge containing a `InvoicePaymentMethodType` and its cursor. */
export type InvoicePaymentMethodTypeEdge = {|
  __typename?: 'InvoicePaymentMethodTypeEdge',
  /** The item at the end of the edge */
  node?: ?InvoicePaymentMethodType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export type InvoiceSettingsType = {|
  ...Node,
  ...{|
    __typename?: 'InvoiceSettingsType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessType,
    website?: ?$ElementType<Scalars, 'String'>,
    phone?: ?$ElementType<Scalars, 'String'>,
    email?: ?$ElementType<Scalars, 'String'>,
    invoiceNumberPrefix?: ?$ElementType<Scalars, 'String'>,
    lastInvoiceOrdinalNumber: $ElementType<Scalars, 'Int'>,
    hasLogo: $ElementType<Scalars, 'Boolean'>,
    headerType?: ?$ElementType<Scalars, 'String'>,
    themeColor?: ?$ElementType<Scalars, 'String'>,
    invoiceLogo?: ?$ElementType<Scalars, 'String'>,
  |}
|};

export type InvoiceType = {|
  ...Node,
  ...{|
    __typename?: 'InvoiceType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessType,
    date: $ElementType<Scalars, 'Date'>,
    customer?: ?BusinessCustomerType,
    serviceDateFrom?: ?$ElementType<Scalars, 'Date'>,
    serviceDateUntil?: ?$ElementType<Scalars, 'Date'>,
    dueDate?: ?$ElementType<Scalars, 'Date'>,
    invoiceNumber: $ElementType<Scalars, 'String'>,
    notes?: ?$ElementType<Scalars, 'String'>,
    language?: ?Languages,
    currency: $ElementType<Scalars, 'String'>,
    record?: ?RecordType,
    isServiceOrGoods?: ?SaleType,
    document?: ?DocumentType,
    vatRate?: ?$ElementType<Scalars, 'Float'>,
    vatExemptionParagraph?: ?VatExemptionParagraph,
    isTaxNumberMissing: $ElementType<Scalars, 'Boolean'>,
    paymentMethod?: ?InvoicePaymentMethodType,
    personalNote?: ?$ElementType<Scalars, 'String'>,
    paymentAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    lineItems?: ?InvoiceLineItemTypeConnection,
    attachmentFilename?: ?$ElementType<Scalars, 'String'>,
    downloadUrl?: ?$ElementType<Scalars, 'String'>,
  |}
|};


export type InvoiceTypeLineItemsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type InvoiceTypeConnection = {|
  __typename?: 'InvoiceTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?InvoiceTypeEdge>,
  count?: ?$ElementType<Scalars, 'Int'>,
  exists?: ?$ElementType<Scalars, 'Boolean'>,
|};

/** A Relay edge containing a `InvoiceType` and its cursor. */
export type InvoiceTypeEdge = {|
  __typename?: 'InvoiceTypeEdge',
  /** The item at the end of the edge */
  node?: ?InvoiceType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export const ItemUnitValues = Object.freeze({
  Hours: 'hours',
  Days: 'days',
  Items: 'items'
});


export type ItemUnit = $Values<typeof ItemUnitValues>;


export const LanguagesValues = Object.freeze({
  En: 'en',
  De: 'de'
});


export type Languages = $Values<typeof LanguagesValues>;

export type MarkAutoRecordAsNotPendingInput = {|
  autoRecordId: $ElementType<Scalars, 'ID'>,
  shouldDeleteRecurringSetting?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkAutoRecordAsNotPendingPayload = {|
  __typename?: 'MarkAutoRecordAsNotPendingPayload',
  autoRecordId?: ?$ElementType<Scalars, 'ID'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkIncomeTaxReportAsSentInput = {|
  year: $ElementType<Scalars, 'Int'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkIncomeTaxReportAsSentPayload = {|
  __typename?: 'MarkIncomeTaxReportAsSentPayload',
  incomeTaxReport?: ?IncomeTaxReportType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkInvoicePaymentMethodAsDefaultInput = {|
  paymentMethodId: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkInvoicePaymentMethodAsDefaultPayload = {|
  __typename?: 'MarkInvoicePaymentMethodAsDefaultPayload',
  newDefaultPaymentMethod?: ?InvoicePaymentMethodType,
  previousDefaultPaymentMethod?: ?InvoicePaymentMethodType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkPnlAsAlreadySentInput = {|
  year: $ElementType<Scalars, 'Int'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkPnlAsAlreadySentPayload = {|
  __typename?: 'MarkPnlAsAlreadySentPayload',
  pnlReport?: ?PnlReportType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkRecordPaymentStatusInput = {|
  recordId: $ElementType<Scalars, 'ID'>,
  paymentMonth?: ?$ElementType<Scalars, 'Date'>,
  markPaid?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkRecordPaymentStatusPayload = {|
  __typename?: 'MarkRecordPaymentStatusPayload',
  record?: ?RecordType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkTaxRegistrationAlreadyDeclaredInput = {|
  taxState?: ?$ElementType<Scalars, 'String'>,
  taxNumber?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkTaxRegistrationAlreadyDeclaredPayload = {|
  __typename?: 'MarkTaxRegistrationAlreadyDeclaredPayload',
  taxRegistration?: ?TaxRegistrationType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkTaxRegistrationApprovalReceivedInput = {|
  taxNumber: $ElementType<Scalars, 'String'>,
  profession: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkTaxRegistrationApprovalReceivedPayload = {|
  __typename?: 'MarkTaxRegistrationApprovalReceivedPayload',
  taxRegistration?: ?TaxRegistrationType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkZmReportAsAlreadySentInput = {|
  reportedPeriodStartDate: $ElementType<Scalars, 'Date'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type MarkZmReportAsAlreadySentPayload = {|
  __typename?: 'MarkZMReportAsAlreadySentPayload',
  zmReport?: ?ZmReportType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type Mutation = {|
  __typename?: 'Mutation',
  IntroduceLinking?: ?IntroduceLinkingPayload,
  IntroduceBusinessCustomer?: ?IntroduceBusinessCustomerPayload,
  IntroduceInvoicePaymentMethod?: ?IntroduceInvoicePaymentMethodPayload,
  IntroduceInvoiceSettings?: ?IntroduceInvoiceSettingsPayload,
  IntroduceInvoice?: ?IntroduceInvoicePayload,
  IntroduceInvoiceLogo?: ?IntroduceInvoiceLogoPayload,
  DeleteBusinessCustomer?: ?DeleteBusinessCustomerPayload,
  DeleteInvoicePaymentMethod?: ?DeleteInvoicePaymentMethodPayload,
  DeleteInvoiceLogo?: ?DeleteInvoiceLogoPayload,
  MarkInvoicePaymentMethodAsDefault?: ?MarkInvoicePaymentMethodAsDefaultPayload,
  SendInvoice?: ?SendInvoicePayload,
  IntroduceAnonymousLogin?: ?IntroduceAnonymousLoginPayload,
  ParseImportSpreadsheet?: ?ParseImportSpreadsheetPayload,
  ImportSpreadsheet?: ?ImportSpreadsheetPayload,
  ImportDatevFile?: ?ImportDatevFilePayload,
  ExportAccountData?: ?ExportAccountDataPayload,
  IntroduceUnsupportedBankAccount?: ?IntroduceUnsupportedBankAccountPayload,
  IntroduceBankConnection?: ?IntroduceBankConnectionPayload,
  RefreshBankConnection?: ?RefreshBankConnectionPayload,
  ReconnectBankConnection?: ?ReconnectBankConnectionPayload,
  DeleteBankConnection?: ?DeleteBankConnectionPayload,
  IntroduceSubscriptionCanceled?: ?IntroduceSubscriptionCanceledPayload,
  BillCustomerForTaxService?: ?BillCustomerForTaxServicePayload,
  IntroduceSubscriptionCheckoutSession?: ?IntroduceSubscriptionCheckoutSessionPayload,
  IntroduceNewSubscriptionPlan?: ?IntroduceNewSubscriptionPlanPayload,
  IntroduceSubscriptionResumed?: ?IntroduceSubscriptionResumedPayload,
  IntroduceAddCreditCard?: ?IntroduceAddCreditCardPayload,
  IntroduceUpdateCreditCard?: ?IntroduceUpdateCreditCardPayload,
  InviteFriends?: ?InviteFriendsPayload,
  SignupAffiliateProgram?: ?SignupAffiliateProgramPayload,
  UpdateTaxRegistration?: ?UpdateTaxRegistrationPayload,
  SendTaxRegistration?: ?SendTaxRegistrationPayload,
  DeleteTaxRegistration?: ?DeleteTaxRegistrationPayload,
  MarkTaxRegistrationAlreadyDeclared?: ?MarkTaxRegistrationAlreadyDeclaredPayload,
  StartTaxRegistrationSubmission?: ?StartTaxRegistrationSubmissionPayload,
  MarkTaxRegistrationApprovalReceived?: ?MarkTaxRegistrationApprovalReceivedPayload,
  IntroduceTaxRequest?: ?IntroduceTaxRequestPayload,
  IntroduceIncomeStatementPreparationRequest?: ?IntroduceIncomeStatementPreparationRequestPayload,
  IntroduceIncomeStatementDocument?: ?IntroduceIncomeStatementDocumentPayload,
  IntroduceCustomTaxServiceBilling?: ?IntroduceCustomTaxServiceBillingPayload,
  CancelTaxServiceBilling?: ?CancelTaxServiceBillingPayload,
  SendVAT?: ?SendVatPayload,
  SendAnnualVAT?: ?SendAnnualVatPayload,
  SendPNL?: ?SendPnlPayload,
  MarkPnlAsAlreadySent?: ?MarkPnlAsAlreadySentPayload,
  MarkIncomeTaxReportAsSent?: ?MarkIncomeTaxReportAsSentPayload,
  DeletePnlReport?: ?DeletePnlReportPayload,
  DeleteVatReport?: ?DeleteVatReportPayload,
  DeleteAnnualVatReport?: ?DeleteAnnualVatReportPayload,
  DeleteIncomeTaxReport?: ?DeleteIncomeTaxReportPayload,
  UpdateIncomeTaxReport?: ?UpdateIncomeTaxReportPayload,
  SavePreviousYearRevenue?: ?SavePreviousYearRevenuePayload,
  SendIncomeTaxReport?: ?SendIncomeTaxReportPayload,
  SendZM?: ?SendZmPayload,
  DeleteZMReport?: ?DeleteZmReportPayload,
  MarkZMReportAsAlreadySent?: ?MarkZmReportAsAlreadySentPayload,
  IntroduceAnnualPayslip?: ?IntroduceAnnualPayslipPayload,
  IntroduceYearlyChildInfo?: ?IntroduceYearlyChildInfoPayload,
  DeleteAnnualPayslip?: ?DeleteAnnualPayslipPayload,
  DeleteYearlyChildInfo?: ?DeleteYearlyChildInfoPayload,
  SaveIncomeTaxPrepayments?: ?SaveIncomeTaxPrepaymentsPayload,
  IntroduceAnnualReportsStartYear?: ?IntroduceAnnualReportsStartYearPayload,
  IntroduceDocument?: ?IntroduceDocumentPayload,
  IntroduceSampleDocument?: ?IntroduceSampleDocumentPayload,
  IntroduceRecord?: ?IntroduceRecordPayload,
  IntroduceVatSettings?: ?IntroduceVatSettingsPayload,
  DeleteRecord?: ?DeleteRecordPayload,
  DeleteDocument?: ?DeleteDocumentPayload,
  IntroduceTaxNeedsOnboardingQuestion?: ?IntroduceTaxNeedsOnboardingQuestionPayload,
  IntroduceCompletedOnboardingStep?: ?IntroduceCompletedOnboardingStepPayload,
  IntroduceBusinessProfile?: ?IntroduceBusinessProfilePayload,
  IntroduceUserSettings?: ?IntroduceUserSettingsPayload,
  IntroduceReferralCodeReceived?: ?IntroduceReferralCodeReceivedPayload,
  MarkAutoRecordAsNotPending?: ?MarkAutoRecordAsNotPendingPayload,
  DeleteUserAccount?: ?DeleteUserAccountPayload,
  DismissAlert?: ?DismissAlertPayload,
  MarkRecordPaymentStatus?: ?MarkRecordPaymentStatusPayload,
|};


export type MutationIntroduceLinkingArgs = {|
  input: IntroduceLinkingInput,
|};


export type MutationIntroduceBusinessCustomerArgs = {|
  input: IntroduceBusinessCustomerInput,
|};


export type MutationIntroduceInvoicePaymentMethodArgs = {|
  input: IntroduceInvoicePaymentMethodInput,
|};


export type MutationIntroduceInvoiceSettingsArgs = {|
  input: IntroduceInvoiceSettingsInput,
|};


export type MutationIntroduceInvoiceArgs = {|
  input: IntroduceInvoiceInput,
|};


export type MutationIntroduceInvoiceLogoArgs = {|
  input: IntroduceInvoiceLogoInput,
|};


export type MutationDeleteBusinessCustomerArgs = {|
  input: DeleteBusinessCustomerInput,
|};


export type MutationDeleteInvoicePaymentMethodArgs = {|
  input: DeleteInvoicePaymentMethodInput,
|};


export type MutationDeleteInvoiceLogoArgs = {|
  input: DeleteInvoiceLogoInput,
|};


export type MutationMarkInvoicePaymentMethodAsDefaultArgs = {|
  input: MarkInvoicePaymentMethodAsDefaultInput,
|};


export type MutationSendInvoiceArgs = {|
  input: SendInvoiceInput,
|};


export type MutationIntroduceAnonymousLoginArgs = {|
  input: IntroduceAnonymousLoginInput,
|};


export type MutationParseImportSpreadsheetArgs = {|
  input: ParseImportSpreadsheetInput,
|};


export type MutationImportSpreadsheetArgs = {|
  input: ImportSpreadsheetInput,
|};


export type MutationImportDatevFileArgs = {|
  input: ImportDatevFileInput,
|};


export type MutationExportAccountDataArgs = {|
  input: ExportAccountDataInput,
|};


export type MutationIntroduceUnsupportedBankAccountArgs = {|
  input: IntroduceUnsupportedBankAccountInput,
|};


export type MutationIntroduceBankConnectionArgs = {|
  input: IntroduceBankConnectionInput,
|};


export type MutationRefreshBankConnectionArgs = {|
  input: RefreshBankConnectionInput,
|};


export type MutationReconnectBankConnectionArgs = {|
  input: ReconnectBankConnectionInput,
|};


export type MutationDeleteBankConnectionArgs = {|
  input: DeleteBankConnectionInput,
|};


export type MutationIntroduceSubscriptionCanceledArgs = {|
  input: IntroduceSubscriptionCanceledInput,
|};


export type MutationBillCustomerForTaxServiceArgs = {|
  input: BillCustomerForTaxServiceInput,
|};


export type MutationIntroduceSubscriptionCheckoutSessionArgs = {|
  input: IntroduceSubscriptionCheckoutSessionInput,
|};


export type MutationIntroduceNewSubscriptionPlanArgs = {|
  input: IntroduceNewSubscriptionPlanInput,
|};


export type MutationIntroduceSubscriptionResumedArgs = {|
  input: IntroduceSubscriptionResumedInput,
|};


export type MutationIntroduceAddCreditCardArgs = {|
  input: IntroduceAddCreditCardInput,
|};


export type MutationIntroduceUpdateCreditCardArgs = {|
  input: IntroduceUpdateCreditCardInput,
|};


export type MutationInviteFriendsArgs = {|
  input: InviteFriendsInput,
|};


export type MutationSignupAffiliateProgramArgs = {|
  input: SignupAffiliateProgramInput,
|};


export type MutationUpdateTaxRegistrationArgs = {|
  input: UpdateTaxRegistrationInput,
|};


export type MutationSendTaxRegistrationArgs = {|
  input: SendTaxRegistrationInput,
|};


export type MutationDeleteTaxRegistrationArgs = {|
  input: DeleteTaxRegistrationInput,
|};


export type MutationMarkTaxRegistrationAlreadyDeclaredArgs = {|
  input: MarkTaxRegistrationAlreadyDeclaredInput,
|};


export type MutationStartTaxRegistrationSubmissionArgs = {|
  input: StartTaxRegistrationSubmissionInput,
|};


export type MutationMarkTaxRegistrationApprovalReceivedArgs = {|
  input: MarkTaxRegistrationApprovalReceivedInput,
|};


export type MutationIntroduceTaxRequestArgs = {|
  input: IntroduceTaxRequestInput,
|};


export type MutationIntroduceIncomeStatementPreparationRequestArgs = {|
  input: IntroduceIncomeStatementPreparationRequestInput,
|};


export type MutationIntroduceIncomeStatementDocumentArgs = {|
  input: IntroduceIncomeStatementDocumentInput,
|};


export type MutationIntroduceCustomTaxServiceBillingArgs = {|
  input: IntroduceCustomTaxServiceBillingInput,
|};


export type MutationCancelTaxServiceBillingArgs = {|
  input: CancelTaxServiceBillingInput,
|};


export type MutationSendVatArgs = {|
  input: SendVatInput,
|};


export type MutationSendAnnualVatArgs = {|
  input: SendAnnualVatInput,
|};


export type MutationSendPnlArgs = {|
  input: SendPnlInput,
|};


export type MutationMarkPnlAsAlreadySentArgs = {|
  input: MarkPnlAsAlreadySentInput,
|};


export type MutationMarkIncomeTaxReportAsSentArgs = {|
  input: MarkIncomeTaxReportAsSentInput,
|};


export type MutationDeletePnlReportArgs = {|
  input: DeletePnlReportInput,
|};


export type MutationDeleteVatReportArgs = {|
  input: DeleteVatReportInput,
|};


export type MutationDeleteAnnualVatReportArgs = {|
  input: DeleteAnnualVatReportInput,
|};


export type MutationDeleteIncomeTaxReportArgs = {|
  input: DeleteIncomeTaxReportInput,
|};


export type MutationUpdateIncomeTaxReportArgs = {|
  input: UpdateIncomeTaxReportInput,
|};


export type MutationSavePreviousYearRevenueArgs = {|
  input: SavePreviousYearRevenueInput,
|};


export type MutationSendIncomeTaxReportArgs = {|
  input: SendIncomeTaxReportInput,
|};


export type MutationSendZmArgs = {|
  input: SendZmInput,
|};


export type MutationDeleteZmReportArgs = {|
  input: DeleteZmReportInput,
|};


export type MutationMarkZmReportAsAlreadySentArgs = {|
  input: MarkZmReportAsAlreadySentInput,
|};


export type MutationIntroduceAnnualPayslipArgs = {|
  input: IntroduceAnnualPayslipInput,
|};


export type MutationIntroduceYearlyChildInfoArgs = {|
  input: IntroduceYearlyChildInfoInput,
|};


export type MutationDeleteAnnualPayslipArgs = {|
  input: DeleteAnnualPayslipInput,
|};


export type MutationDeleteYearlyChildInfoArgs = {|
  input: DeleteYearlyChildInfoInput,
|};


export type MutationSaveIncomeTaxPrepaymentsArgs = {|
  input: SaveIncomeTaxPrepaymentsInput,
|};


export type MutationIntroduceAnnualReportsStartYearArgs = {|
  input: IntroduceAnnualReportsStartYearInput,
|};


export type MutationIntroduceDocumentArgs = {|
  input: IntroduceDocumentInput,
|};


export type MutationIntroduceSampleDocumentArgs = {|
  input: IntroduceSampleDocumentInput,
|};


export type MutationIntroduceRecordArgs = {|
  input: IntroduceRecordInput,
|};


export type MutationIntroduceVatSettingsArgs = {|
  input: IntroduceVatSettingsInput,
|};


export type MutationDeleteRecordArgs = {|
  input: DeleteRecordInput,
|};


export type MutationDeleteDocumentArgs = {|
  input: DeleteDocumentInput,
|};


export type MutationIntroduceTaxNeedsOnboardingQuestionArgs = {|
  input: IntroduceTaxNeedsOnboardingQuestionInput,
|};


export type MutationIntroduceCompletedOnboardingStepArgs = {|
  input: IntroduceCompletedOnboardingStepInput,
|};


export type MutationIntroduceBusinessProfileArgs = {|
  input: IntroduceBusinessProfileInput,
|};


export type MutationIntroduceUserSettingsArgs = {|
  input: IntroduceUserSettingsInput,
|};


export type MutationIntroduceReferralCodeReceivedArgs = {|
  input: IntroduceReferralCodeReceivedInput,
|};


export type MutationMarkAutoRecordAsNotPendingArgs = {|
  input: MarkAutoRecordAsNotPendingInput,
|};


export type MutationDeleteUserAccountArgs = {|
  input: DeleteUserAccountInput,
|};


export type MutationDismissAlertArgs = {|
  input: DismissAlertInput,
|};


export type MutationMarkRecordPaymentStatusArgs = {|
  input: MarkRecordPaymentStatusInput,
|};

/** An object with an ID */
export type Node = {|
  /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
|};

export const OnboardingStepsEnumValues = Object.freeze({
  RegisterAsAFreelancer: 'register_as_a_freelancer',
  LearnAboutTaxes: 'learn_about_taxes',
  ExpenseOverview: 'expense_overview',
  TaxesPageTour: 'taxes_page_tour',
  CreateFirstInvoice: 'create_first_invoice',
  GetToKnowPersonalTaxAdvisor: 'get_to_know_personal_tax_advisor',
  TaxQuestionDedicatedTaxAdvisor: 'tax_question_dedicated_tax_advisor',
  TaxGuides: 'tax_guides',
  Reports: 'reports',
  DedicatedTaxAdvisor: 'dedicated_tax_advisor',
  Expense: 'expense',
  Income: 'income',
  DataImport: 'data_import'
});


export type OnboardingStepsEnum = $Values<typeof OnboardingStepsEnumValues>;

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {|
  __typename?: 'PageInfo',
  /** When paginating forwards, are there more items? */
  hasNextPage: $ElementType<Scalars, 'Boolean'>,
  /** When paginating backwards, are there more items? */
  hasPreviousPage: $ElementType<Scalars, 'Boolean'>,
  /** When paginating backwards, the cursor to continue. */
  startCursor?: ?$ElementType<Scalars, 'String'>,
  /** When paginating forwards, the cursor to continue. */
  endCursor?: ?$ElementType<Scalars, 'String'>,
|};

export type ParseImportSpreadsheetInput = {|
  document: $ElementType<Scalars, 'String'>,
  inputChannel: InputChannelEnum,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type ParseImportSpreadsheetPayload = {|
  __typename?: 'ParseImportSpreadsheetPayload',
  workSheets?: ?Array<?WorkSheetType>,
  documentKey?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export const PartialRecordSourceCategoryEnumValues = Object.freeze({
  Imported: 'imported',
  Uploaded: 'uploaded',
  Recurring: 'recurring',
  Bank: 'bank'
});


export type PartialRecordSourceCategoryEnum = $Values<typeof PartialRecordSourceCategoryEnumValues>;

export type Payment = {|
  __typename?: 'Payment',
  reason?: ?$ElementType<Scalars, 'String'>,
  paid?: ?$ElementType<Scalars, 'Boolean'>,
  amount?: ?$ElementType<Scalars, 'Int'>,
|};

export const PaymentMethodValues = Object.freeze({
  BankWithIban: 'bank_with_iban',
  BankWithAccountNumber: 'bank_with_account_number',
  Paypal: 'paypal',
  FreeTextForm: 'free_text_form'
});


export type PaymentMethod = $Values<typeof PaymentMethodValues>;

export type PnlReportType = {|
  ...Node,
  ...BaseReportInterface,
  ...ReportPeriodInterface,
  ...{|
    __typename?: 'PNLReportType',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    reportedDate?: ?$ElementType<Scalars, 'Date'>,
    sentWithSorted?: ?$ElementType<Scalars, 'Boolean'>,
    isCorrection?: ?$ElementType<Scalars, 'Boolean'>,
    business: BusinessType,
    year: $ElementType<Scalars, 'Int'>,
    confirmationDocument?: ?DocumentType,
    reportedProfitOrLoss?: ?$ElementType<Scalars, 'DecimalScalar'>,
    hideOnTimeline?: ?$ElementType<Scalars, 'Boolean'>,
    isSent?: ?$ElementType<Scalars, 'Boolean'>,
    submissionDeadline?: ?$ElementType<Scalars, 'Date'>,
    requiresSubscription?: ?$ElementType<Scalars, 'Boolean'>,
    isFeatureInTrial?: ?$ElementType<Scalars, 'Boolean'>,
    reportingInterval?: ?VatReportingInterval,
    submitUrgencyLevel?: ?ReportSubmitUrgencyLevel,
    allowSubmission?: ?$ElementType<Scalars, 'Boolean'>,
    periodStartDate?: ?$ElementType<Scalars, 'Date'>,
    incomeSummary?: ?IncomeSummaryType,
    expensesSummary?: ?ExpenseSummaryType,
    total?: ?$ElementType<Scalars, 'DecimalScalar'>,
    isPayingVatInYear?: ?$ElementType<Scalars, 'Boolean'>,
    unpaidRecords?: ?RecordTypeConnection,
    invoicesWithoutTaxNumber?: ?RecordTypeConnection,
  |}
|};


export type PnlReportTypeUnpaidRecordsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  recordType?: ?$ElementType<Scalars, 'String'>,
  recordType_In?: ?Array<?$ElementType<Scalars, 'String'>>,
  category?: ?$ElementType<Scalars, 'String'>,
  date_Lte?: ?$ElementType<Scalars, 'Date'>,
  date_Gte?: ?$ElementType<Scalars, 'Date'>,
  recurringSettingSource_Isnull?: ?$ElementType<Scalars, 'Boolean'>,
  paymentStatus?: ?$ElementType<Scalars, 'String'>,
  search?: ?$ElementType<Scalars, 'String'>,
  dateYear?: ?$ElementType<Scalars, 'Int'>,
  paymentDateOrDateYearLte?: ?$ElementType<Scalars, 'Int'>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
  idIn?: ?$ElementType<Scalars, 'String'>,
  excludeVatPayments?: ?$ElementType<Scalars, 'Boolean'>,
  recommendedRecordsForPendingAutoRecord?: ?$ElementType<Scalars, 'String'>,
|};


export type PnlReportTypeInvoicesWithoutTaxNumberArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type PnlReportTypeConnection = {|
  __typename?: 'PNLReportTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?PnlReportTypeEdge>,
|};

/** A Relay edge containing a `PNLReportType` and its cursor. */
export type PnlReportTypeEdge = {|
  __typename?: 'PNLReportTypeEdge',
  /** The item at the end of the edge */
  node?: ?PnlReportType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export type Query = {|
  __typename?: 'Query',
  id: $ElementType<Scalars, 'ID'>,
  bankProviders?: ?Array<?BankProviderType>,
  invoiceSettings?: ?InvoiceSettingsType,
  invoice?: ?InvoiceType,
  GetLatestDataExport?: ?GetLatestDataExportPayload,
  taxOffice?: ?TaxOfficeType,
  taxOffices?: ?Array<?TaxOfficeType>,
  taxServicesBillingData?: ?Array<?TaxServiceBillingDataType>,
  taxServiceBillingData?: ?TaxServiceBillingDataType,
  taxServiceBillings?: ?TaxServiceBillingTypeConnection,
  reportsTimeline?: ?Array<?ReportsUnion>,
  annualReportsTimeline?: ?Array<?IncomeTaxReportType>,
  taxStates?: ?Array<?$ElementType<Scalars, 'String'>>,
  businessLegalTypes?: ?Array<?$ElementType<Scalars, 'String'>>,
  isReportsSupportedForBusinessLegalType?: ?$ElementType<Scalars, 'Boolean'>,
  vatReport?: ?VatReportType,
  zmReport?: ?ZmReportType,
  annualVatReport?: ?AnnualVatReportType,
  pnlReport?: ?PnlReportType,
  incomeTaxReport?: ?IncomeTaxReportType,
  annualPayslip?: ?AnnualPayslipType,
  yearlyChildInfo?: ?YearlyChildInfoType,
  payslipLines?: ?$ElementType<Scalars, 'JSONString'>,
  firstVatSubmissionDeadline?: ?$ElementType<Scalars, 'Date'>,
  shouldPayVatInYear?: ?$ElementType<Scalars, 'Boolean'>,
  annualReportsStartYear?: ?$ElementType<Scalars, 'Int'>,
  latestSubscription?: ?Subscription,
  activeSubscription?: ?Subscription,
  failedPayment?: ?Payment,
  paymentMethod?: ?CreditCard,
  customerCardSetupIntentSecret?: ?$ElementType<Scalars, 'String'>,
  couponText?: ?$ElementType<Scalars, 'String'>,
  referralCodeToDistribute?: ?$ElementType<Scalars, 'String'>,
  stripeTaxAdvisorDashboardLink?: ?$ElementType<Scalars, 'String'>,
  showAffiliateProgram?: ?$ElementType<Scalars, 'Boolean'>,
  affiliateReferralLink?: ?$ElementType<Scalars, 'String'>,
  tapfiliateDashboardInitialPassword?: ?$ElementType<Scalars, 'String'>,
  userAssumingCapabilities?: ?$ElementType<Scalars, 'String'>,
  possibleBusinessesToAssume?: ?BusinessTypeConnection,
  record?: ?RecordType,
  autoRecord?: ?AutoRecordType,
  document?: ?DocumentType,
  expenseCategories?: ?Array<?ExpenseCategory>,
  currentUser?: ?UserType,
  currentBusiness?: ?BusinessType,
  userSettings?: ?UserSettingsType,
  businessIntercomLink?: ?$ElementType<Scalars, 'String'>,
  convertCurrency?: ?$ElementType<Scalars, 'DecimalScalar'>,
  supportedCurrencies?: ?Array<?Currency>,
  supportedVatExemptionParagraphs?: ?Array<?VatExemptionParagraph>,
  isFirstLogin?: ?$ElementType<Scalars, 'Boolean'>,
  isFirstSession?: ?$ElementType<Scalars, 'Boolean'>,
  validateVatNumber?: ?$ElementType<Scalars, 'Boolean'>,
  taxNumberFormat?: ?$ElementType<Scalars, 'String'>,
  validateTaxNumber?: ?$ElementType<Scalars, 'Boolean'>,
  taxOfficeFromTaxNumber?: ?TaxOfficeType,
  validatePersonalTaxId?: ?$ElementType<Scalars, 'Boolean'>,
|};


export type QueryInvoiceArgs = {|
  invoiceId?: ?$ElementType<Scalars, 'ID'>,
|};


export type QueryGetLatestDataExportArgs = {|
  input: GetLatestDataExportInput,
|};


export type QueryTaxOfficeArgs = {|
  id?: ?$ElementType<Scalars, 'String'>,
|};


export type QueryTaxServiceBillingDataArgs = {|
  serviceType?: ?TaxServiceType,
|};


export type QueryTaxServiceBillingsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  state?: ?ServiceFeeState,
  state_In?: ?Array<?ServiceFeeState>,
  orderBy?: ?$ElementType<Scalars, 'String'>,
|};


export type QueryVatReportArgs = {|
  reportedPeriodStartDate: $ElementType<Scalars, 'Date'>,
|};


export type QueryZmReportArgs = {|
  reportedPeriodStartDate: $ElementType<Scalars, 'Date'>,
|};


export type QueryAnnualVatReportArgs = {|
  year: $ElementType<Scalars, 'Int'>,
|};


export type QueryPnlReportArgs = {|
  year: $ElementType<Scalars, 'Int'>,
|};


export type QueryIncomeTaxReportArgs = {|
  year: $ElementType<Scalars, 'Int'>,
|};


export type QueryAnnualPayslipArgs = {|
  id?: ?$ElementType<Scalars, 'ID'>,
|};


export type QueryYearlyChildInfoArgs = {|
  id?: ?$ElementType<Scalars, 'ID'>,
|};


export type QueryShouldPayVatInYearArgs = {|
  year?: ?$ElementType<Scalars, 'Int'>,
|};


export type QueryPossibleBusinessesToAssumeArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  id?: ?$ElementType<Scalars, 'ID'>,
  createdAt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Gte?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lt?: ?$ElementType<Scalars, 'DateTime'>,
  updatedAt_Lte?: ?$ElementType<Scalars, 'DateTime'>,
  search?: ?$ElementType<Scalars, 'String'>,
|};


export type QueryRecordArgs = {|
  recordId?: ?$ElementType<Scalars, 'ID'>,
|};


export type QueryAutoRecordArgs = {|
  autoRecordId?: ?$ElementType<Scalars, 'ID'>,
|};


export type QueryDocumentArgs = {|
  id?: ?$ElementType<Scalars, 'ID'>,
|};


export type QueryUserSettingsArgs = {|
  setDefaultLanguage?: ?$ElementType<Scalars, 'String'>,
|};


export type QueryBusinessIntercomLinkArgs = {|
  id?: ?$ElementType<Scalars, 'ID'>,
|};


export type QueryConvertCurrencyArgs = {|
  baseCurrency?: ?$ElementType<Scalars, 'String'>,
  destCurrency?: ?$ElementType<Scalars, 'String'>,
  amount?: ?$ElementType<Scalars, 'DecimalScalar'>,
  date?: ?$ElementType<Scalars, 'Date'>,
|};


export type QueryValidateVatNumberArgs = {|
  vatNumber: $ElementType<Scalars, 'String'>,
|};


export type QueryTaxNumberFormatArgs = {|
  taxState: $ElementType<Scalars, 'String'>,
|};


export type QueryValidateTaxNumberArgs = {|
  taxNumber: $ElementType<Scalars, 'String'>,
  taxState: $ElementType<Scalars, 'String'>,
|};


export type QueryTaxOfficeFromTaxNumberArgs = {|
  taxNumber: $ElementType<Scalars, 'String'>,
  taxState: $ElementType<Scalars, 'String'>,
|};


export type QueryValidatePersonalTaxIdArgs = {|
  taxId: $ElementType<Scalars, 'String'>,
|};

export type ReconnectBankConnectionInput = {|
  connectionId: $ElementType<Scalars, 'ID'>,
  returnTo: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type ReconnectBankConnectionPayload = {|
  __typename?: 'ReconnectBankConnectionPayload',
  redirectUrl?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export const RecordGrossAmountCurrencyValues = Object.freeze({
  /** ADB Unit of Account */
  Xua: 'XUA',
  /** Afghani */
  Afn: 'AFN',
  /** Algerian Dinar */
  Dzd: 'DZD',
  /** Argentine Peso */
  Ars: 'ARS',
  /** Armenian Dram */
  Amd: 'AMD',
  /** Aruban Guilder */
  Awg: 'AWG',
  /** Australian Dollar */
  Aud: 'AUD',
  /** Azerbaijanian Manat */
  Azn: 'AZN',
  /** Bahamian Dollar */
  Bsd: 'BSD',
  /** Bahraini Dinar */
  Bhd: 'BHD',
  /** Baht */
  Thb: 'THB',
  /** Balboa */
  Pab: 'PAB',
  /** Barbados Dollar */
  Bbd: 'BBD',
  /** Belarussian Ruble */
  Byn: 'BYN',
  /** Belarussian Ruble */
  Byr: 'BYR',
  /** Belize Dollar */
  Bzd: 'BZD',
  /** Bermudian Dollar (customarily known as Bermuda Dollar) */
  Bmd: 'BMD',
  /** Bhutanese ngultrum */
  Btn: 'BTN',
  /** Bolivar Fuerte */
  Vef: 'VEF',
  /** Boliviano */
  Bob: 'BOB',
  /** Bond Markets Units European Composite Unit (EURCO) */
  Xba: 'XBA',
  /** Brazilian Real */
  Brl: 'BRL',
  /** Brunei Dollar */
  Bnd: 'BND',
  /** Bulgarian Lev */
  Bgn: 'BGN',
  /** Burundi Franc */
  Bif: 'BIF',
  /** CFA Franc BCEAO */
  Xof: 'XOF',
  /** CFA franc BEAC */
  Xaf: 'XAF',
  /** CFP Franc */
  Xpf: 'XPF',
  /** Canadian Dollar */
  Cad: 'CAD',
  /** Cape Verde Escudo */
  Cve: 'CVE',
  /** Cayman Islands Dollar */
  Kyd: 'KYD',
  /** Chilean peso */
  Clp: 'CLP',
  /** Codes specifically reserved for testing purposes */
  Xts: 'XTS',
  /** Colombian peso */
  Cop: 'COP',
  /** Comoro Franc */
  Kmf: 'KMF',
  /** Congolese franc */
  Cdf: 'CDF',
  /** Convertible Marks */
  Bam: 'BAM',
  /** Cordoba Oro */
  Nio: 'NIO',
  /** Costa Rican Colon */
  Crc: 'CRC',
  /** Croatian Kuna */
  Hrk: 'HRK',
  /** Cuban Peso */
  Cup: 'CUP',
  /** Cuban convertible peso */
  Cuc: 'CUC',
  /** Czech Koruna */
  Czk: 'CZK',
  /** Dalasi */
  Gmd: 'GMD',
  /** Danish Krone */
  Dkk: 'DKK',
  /** Denar */
  Mkd: 'MKD',
  /** Djibouti Franc */
  Djf: 'DJF',
  /** Dobra */
  Std: 'STD',
  /** Dominican Peso */
  Dop: 'DOP',
  /** Dong */
  Vnd: 'VND',
  /** East Caribbean Dollar */
  Xcd: 'XCD',
  /** Egyptian Pound */
  Egp: 'EGP',
  /** El Salvador Colon */
  Svc: 'SVC',
  /** Ethiopian Birr */
  Etb: 'ETB',
  /** Euro */
  Eur: 'EUR',
  /** European Monetary Unit (E.M.U.-6) */
  Xbb: 'XBB',
  /** European Unit of Account 17(E.U.A.-17) */
  Xbd: 'XBD',
  /** European Unit of Account 9(E.U.A.-9) */
  Xbc: 'XBC',
  /** Falkland Islands Pound */
  Fkp: 'FKP',
  /** Fiji Dollar */
  Fjd: 'FJD',
  /** Forint */
  Huf: 'HUF',
  /** Ghana Cedi */
  Ghs: 'GHS',
  /** Gibraltar Pound */
  Gip: 'GIP',
  /** Gold */
  Xau: 'XAU',
  /** Gold-Franc */
  Xfo: 'XFO',
  /** Guarani */
  Pyg: 'PYG',
  /** Guinea Franc */
  Gnf: 'GNF',
  /** Guyana Dollar */
  Gyd: 'GYD',
  /** Haitian gourde */
  Htg: 'HTG',
  /** Hong Kong Dollar */
  Hkd: 'HKD',
  /** Hryvnia */
  Uah: 'UAH',
  /** Iceland Krona */
  Isk: 'ISK',
  /** Indian Rupee */
  Inr: 'INR',
  /** Iranian Rial */
  Irr: 'IRR',
  /** Iraqi Dinar */
  Iqd: 'IQD',
  /** Isle of Man Pound */
  Imp: 'IMP',
  /** Jamaican Dollar */
  Jmd: 'JMD',
  /** Jordanian Dinar */
  Jod: 'JOD',
  /** Kenyan Shilling */
  Kes: 'KES',
  /** Kina */
  Pgk: 'PGK',
  /** Kip */
  Lak: 'LAK',
  /** Kuwaiti Dinar */
  Kwd: 'KWD',
  /** Kwanza */
  Aoa: 'AOA',
  /** Kyat */
  Mmk: 'MMK',
  /** Lari */
  Gel: 'GEL',
  /** Latvian Lats */
  Lvl: 'LVL',
  /** Lebanese Pound */
  Lbp: 'LBP',
  /** Lek */
  All: 'ALL',
  /** Lempira */
  Hnl: 'HNL',
  /** Leone */
  Sll: 'SLL',
  /** Lesotho loti */
  Lsl: 'LSL',
  /** Liberian Dollar */
  Lrd: 'LRD',
  /** Libyan Dinar */
  Lyd: 'LYD',
  /** Lilangeni */
  Szl: 'SZL',
  /** Lithuanian Litas */
  Ltl: 'LTL',
  /** Malagasy Ariary */
  Mga: 'MGA',
  /** Malawian Kwacha */
  Mwk: 'MWK',
  /** Malaysian Ringgit */
  Myr: 'MYR',
  /** Manat */
  Tmm: 'TMM',
  /** Mauritius Rupee */
  Mur: 'MUR',
  /** Metical */
  Mzn: 'MZN',
  /** Mexican Unidad de Inversion (UDI) */
  Mxv: 'MXV',
  /** Mexican peso */
  Mxn: 'MXN',
  /** Moldovan Leu */
  Mdl: 'MDL',
  /** Moroccan Dirham */
  Mad: 'MAD',
  /** Mvdol */
  Bov: 'BOV',
  /** Naira */
  Ngn: 'NGN',
  /** Nakfa */
  Ern: 'ERN',
  /** Namibian Dollar */
  Nad: 'NAD',
  /** Nepalese Rupee */
  Npr: 'NPR',
  /** Netherlands Antillian Guilder */
  Ang: 'ANG',
  /** New Israeli Sheqel */
  Ils: 'ILS',
  /** New Leu */
  Ron: 'RON',
  /** New Taiwan Dollar */
  Twd: 'TWD',
  /** New Zealand Dollar */
  Nzd: 'NZD',
  /** North Korean Won */
  Kpw: 'KPW',
  /** Norwegian Krone */
  Nok: 'NOK',
  /** Nuevo Sol */
  Pen: 'PEN',
  /** Ouguiya */
  Mro: 'MRO',
  /** Paanga */
  Top: 'TOP',
  /** Pakistan Rupee */
  Pkr: 'PKR',
  /** Palladium */
  Xpd: 'XPD',
  /** Pataca */
  Mop: 'MOP',
  /** Philippine Peso */
  Php: 'PHP',
  /** Platinum */
  Xpt: 'XPT',
  /** Pound Sterling */
  Gbp: 'GBP',
  /** Pula */
  Bwp: 'BWP',
  /** Qatari Rial */
  Qar: 'QAR',
  /** Quetzal */
  Gtq: 'GTQ',
  /** Rand */
  Zar: 'ZAR',
  /** Rial Omani */
  Omr: 'OMR',
  /** Riel */
  Khr: 'KHR',
  /** Rufiyaa */
  Mvr: 'MVR',
  /** Rupiah */
  Idr: 'IDR',
  /** Russian Ruble */
  Rub: 'RUB',
  /** Rwanda Franc */
  Rwf: 'RWF',
  /** SDR */
  Xdr: 'XDR',
  /** Saint Helena Pound */
  Shp: 'SHP',
  /** Saudi Riyal */
  Sar: 'SAR',
  /** Serbian Dinar */
  Rsd: 'RSD',
  /** Seychelles Rupee */
  Scr: 'SCR',
  /** Silver */
  Xag: 'XAG',
  /** Singapore Dollar */
  Sgd: 'SGD',
  /** Solomon Islands Dollar */
  Sbd: 'SBD',
  /** Som */
  Kgs: 'KGS',
  /** Somali Shilling */
  Sos: 'SOS',
  /** Somoni */
  Tjs: 'TJS',
  /** South Sudanese Pound */
  Ssp: 'SSP',
  /** Sri Lanka Rupee */
  Lkr: 'LKR',
  /** Sucre */
  Xsu: 'XSU',
  /** Sudanese Pound */
  Sdg: 'SDG',
  /** Surinam Dollar */
  Srd: 'SRD',
  /** Swedish Krona */
  Sek: 'SEK',
  /** Swiss Franc */
  Chf: 'CHF',
  /** Syrian Pound */
  Syp: 'SYP',
  /** Taka */
  Bdt: 'BDT',
  /** Tala */
  Wst: 'WST',
  /** Tanzanian Shilling */
  Tzs: 'TZS',
  /** Tenge */
  Kzt: 'KZT',
  /** The codes assigned for transactions where no currency is involved */
  Xxx: 'XXX',
  /** Trinidad and Tobago Dollar */
  Ttd: 'TTD',
  /** Tugrik */
  Mnt: 'MNT',
  /** Tunisian Dinar */
  Tnd: 'TND',
  /** Turkish Lira */
  Try: 'TRY',
  /** Turkmenistan New Manat */
  Tmt: 'TMT',
  /** Tuvalu dollar */
  Tvd: 'TVD',
  /** UAE Dirham */
  Aed: 'AED',
  /** UIC-Franc */
  Xfu: 'XFU',
  /** US Dollar */
  Usd: 'USD',
  /** US Dollar (Next day) */
  Usn: 'USN',
  /** Uganda Shilling */
  Ugx: 'UGX',
  /** Unidad de Fomento */
  Clf: 'CLF',
  /** Unidad de Valor Real */
  Cou: 'COU',
  /** Uruguay Peso en Unidades Indexadas (URUIURUI) */
  Uyi: 'UYI',
  /** Uruguayan peso */
  Uyu: 'UYU',
  /** Uzbekistan Sum */
  Uzs: 'UZS',
  /** Vatu */
  Vuv: 'VUV',
  /** WIR Euro */
  Che: 'CHE',
  /** WIR Franc */
  Chw: 'CHW',
  /** Won */
  Krw: 'KRW',
  /** Yemeni Rial */
  Yer: 'YER',
  /** Yen */
  Jpy: 'JPY',
  /** Yuan Renminbi */
  Cny: 'CNY',
  /** Zambian Kwacha */
  Zmk: 'ZMK',
  /** Zambian Kwacha */
  Zmw: 'ZMW',
  /** Zimbabwe Dollar A/06 */
  Zwd: 'ZWD',
  /** Zimbabwe dollar A/08 */
  Zwn: 'ZWN',
  /** Zimbabwe dollar A/09 */
  Zwl: 'ZWL',
  /** Zloty */
  Pln: 'PLN'
});


export type RecordGrossAmountCurrency = $Values<typeof RecordGrossAmountCurrencyValues>;

export type RecordType = {|
  ...AbstractRecordInterface,
  ...{|
    __typename?: 'RecordType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business?: ?BusinessType,
    date?: ?$ElementType<Scalars, 'Date'>,
    grossAmountCurrency: RecordGrossAmountCurrency,
    grossAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    currencyConversionRate?: ?$ElementType<Scalars, 'DecimalScalar'>,
    description?: ?$ElementType<Scalars, 'String'>,
    document?: ?DocumentType,
    vat?: ?$ElementType<Scalars, 'JSONString'>,
    totalVat?: ?$ElementType<Scalars, 'DecimalScalar'>,
    netAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    convertedGrossAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    category?: ?$ElementType<Scalars, 'String'>,
    recordType?: ?$ElementType<Scalars, 'String'>,
    isCustomerPrivatePerson?: ?$ElementType<Scalars, 'Boolean'>,
    counterPartyCountry?: ?$ElementType<Scalars, 'String'>,
    counterPartyVatNumber?: ?$ElementType<Scalars, 'String'>,
    isServiceOrGoods?: ?$ElementType<Scalars, 'String'>,
    expectedLocalVatRate?: ?$ElementType<Scalars, 'DecimalScalar'>,
    otherTaxesCharged?: ?$ElementType<Scalars, 'Boolean'>,
    isHomeOffice?: ?$ElementType<Scalars, 'Boolean'>,
    vatReport?: ?VatReportType,
    zmReport?: ?ZmReportType,
    vatDeductiblePortion?: ?$ElementType<Scalars, 'DecimalScalar'>,
    netDeductiblePortion?: ?$ElementType<Scalars, 'DecimalScalar'>,
    flatRateExpenseStartDate?: ?$ElementType<Scalars, 'Date'>,
    flatRateExpenseEndDate?: ?$ElementType<Scalars, 'Date'>,
    privateCarDistanceDriven?: ?$ElementType<Scalars, 'Int'>,
    businessTripMealAccommodationCountry?: ?$ElementType<Scalars, 'String'>,
    privateCarUsageType?: ?$ElementType<Scalars, 'String'>,
    privateCarDriveToWorkTotalDays?: ?$ElementType<Scalars, 'Int'>,
    assetLifetimeValue?: ?$ElementType<Scalars, 'Int'>,
    paymentStatus?: ?$ElementType<Scalars, 'String'>,
    paymentMonth?: ?$ElementType<Scalars, 'Date'>,
    repeatInterval?: ?$ElementType<Scalars, 'String'>,
    recurringSettingSource?: ?RecurringRecordSettingType,
    vatExemptionParagraph?: ?VatExemptionParagraph,
    expenseAttributionToVatExemptRevenue?: ?ExpenseAttributionToVatExemptRevenue,
    inputChannel?: ?InputChannelEnum,
    vatPaymentPeriodInterval?: ?VatReportingInterval,
    reverseChargeNetAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    reverseChargeApply?: ?$ElementType<Scalars, 'Boolean'>,
    linkedAutoRecords: AutoRecordTypeConnection,
    invoice?: ?InvoiceType,
    uid?: ?$ElementType<Scalars, 'String'>,
    partialRecordSourceCategoryType?: ?PartialRecordSourceCategoryEnum,
    deductibleGrossAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    deductibleConvertedGrossAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    currency?: ?$ElementType<Scalars, 'String'>,
    depreciationAmounts?: ?$ElementType<Scalars, 'JSONString'>,
    shouldLink?: ?$ElementType<Scalars, 'Boolean'>,
    isBasedOnInternalCreatedInvoice?: ?$ElementType<Scalars, 'Boolean'>,
  |}
|};


export type RecordTypeLinkedAutoRecordsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type RecordTypeConnection = {|
  __typename?: 'RecordTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?RecordTypeEdge>,
  count?: ?$ElementType<Scalars, 'Int'>,
  exists?: ?$ElementType<Scalars, 'Boolean'>,
  convertedGrossAmountSum?: ?$ElementType<Scalars, 'DecimalScalar'>,
|};

/** A Relay edge containing a `RecordType` and its cursor. */
export type RecordTypeEdge = {|
  __typename?: 'RecordTypeEdge',
  /** The item at the end of the edge */
  node?: ?RecordType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export type RecurringRecordSettingType = {|
  ...Node,
  ...{|
    __typename?: 'RecurringRecordSettingType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    startDate: $ElementType<Scalars, 'Date'>,
    derivedRecords: RecordTypeConnection,
    derivedAutorecords: AutoRecordTypeConnection,
    uid?: ?$ElementType<Scalars, 'String'>,
  |}
|};


export type RecurringRecordSettingTypeDerivedRecordsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type RecurringRecordSettingTypeDerivedAutorecordsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type RefreshBankConnectionInput = {|
  connectionId: $ElementType<Scalars, 'ID'>,
  returnTo: $ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type RefreshBankConnectionPayload = {|
  __typename?: 'RefreshBankConnectionPayload',
  redirectUrl?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type ReportPeriodInterface = {|
  reportingInterval?: ?VatReportingInterval,
  submitUrgencyLevel?: ?ReportSubmitUrgencyLevel,
  submissionDeadline?: ?$ElementType<Scalars, 'Date'>,
  allowSubmission?: ?$ElementType<Scalars, 'Boolean'>,
  periodStartDate?: ?$ElementType<Scalars, 'Date'>,
|};

export const ReportSubmitUrgencyLevelValues = Object.freeze({
  InputNeeded: 'input_needed',
  Overdue: 'overdue',
  Upcoming: 'upcoming',
  Open: 'open',
  PreparedByTaxAdvisor: 'prepared_by_tax_advisor'
});


export type ReportSubmitUrgencyLevel = $Values<typeof ReportSubmitUrgencyLevelValues>;

export type ReportsUnion = PnlReportType | VatReportType | AnnualVatReportType | IncomeTaxReportType | ZmReportType | TaxRegistrationType;

export const SaleTypeValues = Object.freeze({
  Goods: 'goods',
  Service: 'service',
  OtherVatfree: 'other_vatfree',
  VatRefund: 'vat_refund'
});


export type SaleType = $Values<typeof SaleTypeValues>;

export type SaveIncomeTaxPrepaymentsInput = {|
  year: $ElementType<Scalars, 'Int'>,
  taxPrepaymentsUpdate: $ElementType<Scalars, 'JSONString'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SaveIncomeTaxPrepaymentsPayload = {|
  __typename?: 'SaveIncomeTaxPrepaymentsPayload',
  incomeTaxReport?: ?IncomeTaxReportType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SavePreviousYearRevenueInput = {|
  year: $ElementType<Scalars, 'Int'>,
  revenue: $ElementType<Scalars, 'DecimalScalar'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SavePreviousYearRevenuePayload = {|
  __typename?: 'SavePreviousYearRevenuePayload',
  annualVatReport?: ?AnnualVatReportType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SendAnnualVatInput = {|
  year: $ElementType<Scalars, 'Int'>,
  isTestRun?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SendAnnualVatPayload = {|
  __typename?: 'SendAnnualVATPayload',
  vatReport?: ?AnnualVatReportType,
  document?: ?DocumentType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SendIncomeTaxReportInput = {|
  year: $ElementType<Scalars, 'Int'>,
  isTestRun?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SendIncomeTaxReportPayload = {|
  __typename?: 'SendIncomeTaxReportPayload',
  incomeTaxReport?: ?IncomeTaxReportType,
  document?: ?DocumentType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SendInvoiceInput = {|
  invoiceId: $ElementType<Scalars, 'ID'>,
  email: $ElementType<Scalars, 'String'>,
  subject: $ElementType<Scalars, 'String'>,
  message: $ElementType<Scalars, 'String'>,
  sendCopy?: ?$ElementType<Scalars, 'Boolean'>,
  isTest?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SendInvoicePayload = {|
  __typename?: 'SendInvoicePayload',
  invoice?: ?InvoiceType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SendPnlInput = {|
  year: $ElementType<Scalars, 'Int'>,
  isTestRun?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SendPnlPayload = {|
  __typename?: 'SendPNLPayload',
  pnlReport?: ?PnlReportType,
  document?: ?DocumentType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SendTaxRegistrationInput = {|
  isTestRun: $ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SendTaxRegistrationPayload = {|
  __typename?: 'SendTaxRegistrationPayload',
  taxRegistration?: ?TaxRegistrationType,
  document?: ?DocumentType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SendVatInput = {|
  reportedPeriodStartDate: $ElementType<Scalars, 'Date'>,
  isTestRun?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SendVatPayload = {|
  __typename?: 'SendVATPayload',
  vatReport?: ?VatReportType,
  document?: ?DocumentType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SendZmInput = {|
  reportedPeriodStartDate: $ElementType<Scalars, 'Date'>,
  isTestRun?: ?$ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SendZmPayload = {|
  __typename?: 'SendZMPayload',
  zmReport?: ?ZmReportType,
  document?: ?DocumentType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export const ServiceFeeStateValues = Object.freeze({
  Cancelled: 'cancelled',
  ChargeFailed: 'charge_failed',
  Paid: 'paid',
  Pending: 'pending',
  Processing: 'processing'
});


export type ServiceFeeState = $Values<typeof ServiceFeeStateValues>;

export type SignupAffiliateProgramInput = {|
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type SignupAffiliateProgramPayload = {|
  __typename?: 'SignupAffiliateProgramPayload',
  affiliateReferralLink?: ?$ElementType<Scalars, 'String'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type StartTaxRegistrationSubmissionInput = {|
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type StartTaxRegistrationSubmissionPayload = {|
  __typename?: 'StartTaxRegistrationSubmissionPayload',
  taxRegistration?: ?TaxRegistrationType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type Subscription = {|
  __typename?: 'Subscription',
  name?: ?$ElementType<Scalars, 'String'>,
  isActive?: ?$ElementType<Scalars, 'Boolean'>,
  isSubscriptionCanceled?: ?$ElementType<Scalars, 'Boolean'>,
  isLegacyYearlySubscription?: ?$ElementType<Scalars, 'Boolean'>,
  startDate?: ?$ElementType<Scalars, 'String'>,
  nextPaymentDate?: ?$ElementType<Scalars, 'String'>,
  paymentAmount?: ?$ElementType<Scalars, 'String'>,
  endDate?: ?$ElementType<Scalars, 'String'>,
  canceledAtDate?: ?$ElementType<Scalars, 'String'>,
  cancelAtDate?: ?$ElementType<Scalars, 'String'>,
  creditCard?: ?CreditCard,
|};

export const TaxAdvisorLanguageSettingsLanguageValues = Object.freeze({
  /** English */
  En: 'EN',
  /** German */
  De: 'DE'
});


export type TaxAdvisorLanguageSettingsLanguage = $Values<typeof TaxAdvisorLanguageSettingsLanguageValues>;

export type TaxAdvisorLanguageType = {|
  ...Node,
  ...{|
    __typename?: 'TaxAdvisorLanguageType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    taxAdvisor: TaxAdvisorType,
    language: TaxAdvisorLanguageSettingsLanguage,
    videoUrl?: ?$ElementType<Scalars, 'String'>,
    bio: $ElementType<Scalars, 'String'>,
    calendlyUrl: $ElementType<Scalars, 'String'>,
    calendlyLongCallUrl?: ?$ElementType<Scalars, 'String'>,
    capacityWeight: $ElementType<Scalars, 'Int'>,
  |}
|};

export type TaxAdvisorLanguageTypeConnection = {|
  __typename?: 'TaxAdvisorLanguageTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?TaxAdvisorLanguageTypeEdge>,
|};

/** A Relay edge containing a `TaxAdvisorLanguageType` and its cursor. */
export type TaxAdvisorLanguageTypeEdge = {|
  __typename?: 'TaxAdvisorLanguageTypeEdge',
  /** The item at the end of the edge */
  node?: ?TaxAdvisorLanguageType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export type TaxAdvisorType = {|
  ...Node,
  ...{|
    __typename?: 'TaxAdvisorType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    taxAdvisory: TaxAdvisoryType,
    user: UserType,
    name: $ElementType<Scalars, 'String'>,
    intercomId: $ElementType<Scalars, 'String'>,
    pictureUrl?: ?$ElementType<Scalars, 'String'>,
    languagesSettings: TaxAdvisorLanguageTypeConnection,
    platformFees: TaxServiceBillingTypeConnection,
  |}
|};


export type TaxAdvisorTypeLanguagesSettingsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
  language?: ?TaxAdvisorLanguageSettingsLanguage,
|};


export type TaxAdvisorTypePlatformFeesArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type TaxAdvisorTypeConnection = {|
  __typename?: 'TaxAdvisorTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?TaxAdvisorTypeEdge>,
|};

/** A Relay edge containing a `TaxAdvisorType` and its cursor. */
export type TaxAdvisorTypeEdge = {|
  __typename?: 'TaxAdvisorTypeEdge',
  /** The item at the end of the edge */
  node?: ?TaxAdvisorType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export type TaxAdvisoryType = {|
  ...Node,
  ...{|
    __typename?: 'TaxAdvisoryType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    name: $ElementType<Scalars, 'String'>,
    stripeAccountId?: ?$ElementType<Scalars, 'String'>,
    emailToSendInvoices?: ?$ElementType<Scalars, 'String'>,
    currentInvoiceNumber: $ElementType<Scalars, 'Int'>,
    termsAndConditionsUrl?: ?$ElementType<Scalars, 'String'>,
    advisoryMembers: TaxAdvisorTypeConnection,
    platformFees: TaxServiceBillingTypeConnection,
  |}
|};


export type TaxAdvisoryTypeAdvisoryMembersArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type TaxAdvisoryTypePlatformFeesArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export const TaxNeedsValues = Object.freeze({
  RegisterAsAFreelancer: 'register_as_a_freelancer',
  Reports: 'reports',
  Invoicing: 'invoicing',
  TaxQuestion: 'tax_question',
  OldBusiness: 'old_business'
});


export type TaxNeeds = $Values<typeof TaxNeedsValues>;

export type TaxOfficeType = {|
  __typename?: 'TaxOfficeType',
  taxOfficeId?: ?$ElementType<Scalars, 'String'>,
  label?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  phone?: ?$ElementType<Scalars, 'String'>,
  website?: ?$ElementType<Scalars, 'String'>,
  address?: ?$ElementType<Scalars, 'String'>,
  openingHours?: ?Array<?$ElementType<Scalars, 'String'>>,
  bankAccounts?: ?Array<?$ElementType<Scalars, 'JSONString'>>,
|};

export type TaxRegistrationType = {|
  ...Node,
  ...BaseReportInterface,
  ...{|
    __typename?: 'TaxRegistrationType',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    reportedDate?: ?$ElementType<Scalars, 'Date'>,
    sentWithSorted?: ?$ElementType<Scalars, 'Boolean'>,
    isCorrection?: ?$ElementType<Scalars, 'Boolean'>,
    business?: ?BusinessType,
    data?: ?$ElementType<Scalars, 'JSONString'>,
    pagesValidity?: ?$ElementType<Scalars, 'JSONString'>,
    confirmationDocument?: ?DocumentType,
    startedSubmissionProcess: $ElementType<Scalars, 'Boolean'>,
    approvalReceiveDate?: ?$ElementType<Scalars, 'Date'>,
    hideOnTimeline?: ?$ElementType<Scalars, 'Boolean'>,
    isSent?: ?$ElementType<Scalars, 'Boolean'>,
    submissionDeadline?: ?$ElementType<Scalars, 'Date'>,
    requiresSubscription?: ?$ElementType<Scalars, 'Boolean'>,
    isFeatureInTrial?: ?$ElementType<Scalars, 'Boolean'>,
    shouldFillSpouseData?: ?$ElementType<Scalars, 'Boolean'>,
    pageData?: ?$ElementType<Scalars, 'JSONString'>,
    isFormValid?: ?$ElementType<Scalars, 'Boolean'>,
    firstYearInBusiness?: ?$ElementType<Scalars, 'Int'>,
    vatNumberRequested?: ?$ElementType<Scalars, 'Boolean'>,
    isTaxOfficeResponseReceived?: ?$ElementType<Scalars, 'Boolean'>,
    taxOfficeId?: ?$ElementType<Scalars, 'String'>,
    shouldChargeVat?: ?$ElementType<Scalars, 'Boolean'>,
  |}
|};


export type TaxRegistrationTypePageDataArgs = {|
  pageName?: ?$ElementType<Scalars, 'String'>,
|};

export type TaxRegistrationTypeConnection = {|
  __typename?: 'TaxRegistrationTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?TaxRegistrationTypeEdge>,
|};

/** A Relay edge containing a `TaxRegistrationType` and its cursor. */
export type TaxRegistrationTypeEdge = {|
  __typename?: 'TaxRegistrationTypeEdge',
  /** The item at the end of the edge */
  node?: ?TaxRegistrationType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export type TaxServiceBillingDataType = {|
  __typename?: 'TaxServiceBillingDataType',
  serviceType?: ?TaxServiceType,
  netPriceWithoutFee?: ?$ElementType<Scalars, 'DecimalScalar'>,
  sortedFeeNet?: ?$ElementType<Scalars, 'DecimalScalar'>,
  taxAdvisorGrossAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
  sortedFeeGrossAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
  totalAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
  totalVat?: ?$ElementType<Scalars, 'DecimalScalar'>,
  vatRate?: ?$ElementType<Scalars, 'DecimalScalar'>,
  totalNetAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
|};

export const TaxServiceBillingPlatformFeeNetCurrencyValues = Object.freeze({
  /** ADB Unit of Account */
  Xua: 'XUA',
  /** Afghani */
  Afn: 'AFN',
  /** Algerian Dinar */
  Dzd: 'DZD',
  /** Argentine Peso */
  Ars: 'ARS',
  /** Armenian Dram */
  Amd: 'AMD',
  /** Aruban Guilder */
  Awg: 'AWG',
  /** Australian Dollar */
  Aud: 'AUD',
  /** Azerbaijanian Manat */
  Azn: 'AZN',
  /** Bahamian Dollar */
  Bsd: 'BSD',
  /** Bahraini Dinar */
  Bhd: 'BHD',
  /** Baht */
  Thb: 'THB',
  /** Balboa */
  Pab: 'PAB',
  /** Barbados Dollar */
  Bbd: 'BBD',
  /** Belarussian Ruble */
  Byn: 'BYN',
  /** Belarussian Ruble */
  Byr: 'BYR',
  /** Belize Dollar */
  Bzd: 'BZD',
  /** Bermudian Dollar (customarily known as Bermuda Dollar) */
  Bmd: 'BMD',
  /** Bhutanese ngultrum */
  Btn: 'BTN',
  /** Bolivar Fuerte */
  Vef: 'VEF',
  /** Boliviano */
  Bob: 'BOB',
  /** Bond Markets Units European Composite Unit (EURCO) */
  Xba: 'XBA',
  /** Brazilian Real */
  Brl: 'BRL',
  /** Brunei Dollar */
  Bnd: 'BND',
  /** Bulgarian Lev */
  Bgn: 'BGN',
  /** Burundi Franc */
  Bif: 'BIF',
  /** CFA Franc BCEAO */
  Xof: 'XOF',
  /** CFA franc BEAC */
  Xaf: 'XAF',
  /** CFP Franc */
  Xpf: 'XPF',
  /** Canadian Dollar */
  Cad: 'CAD',
  /** Cape Verde Escudo */
  Cve: 'CVE',
  /** Cayman Islands Dollar */
  Kyd: 'KYD',
  /** Chilean peso */
  Clp: 'CLP',
  /** Codes specifically reserved for testing purposes */
  Xts: 'XTS',
  /** Colombian peso */
  Cop: 'COP',
  /** Comoro Franc */
  Kmf: 'KMF',
  /** Congolese franc */
  Cdf: 'CDF',
  /** Convertible Marks */
  Bam: 'BAM',
  /** Cordoba Oro */
  Nio: 'NIO',
  /** Costa Rican Colon */
  Crc: 'CRC',
  /** Croatian Kuna */
  Hrk: 'HRK',
  /** Cuban Peso */
  Cup: 'CUP',
  /** Cuban convertible peso */
  Cuc: 'CUC',
  /** Czech Koruna */
  Czk: 'CZK',
  /** Dalasi */
  Gmd: 'GMD',
  /** Danish Krone */
  Dkk: 'DKK',
  /** Denar */
  Mkd: 'MKD',
  /** Djibouti Franc */
  Djf: 'DJF',
  /** Dobra */
  Std: 'STD',
  /** Dominican Peso */
  Dop: 'DOP',
  /** Dong */
  Vnd: 'VND',
  /** East Caribbean Dollar */
  Xcd: 'XCD',
  /** Egyptian Pound */
  Egp: 'EGP',
  /** El Salvador Colon */
  Svc: 'SVC',
  /** Ethiopian Birr */
  Etb: 'ETB',
  /** Euro */
  Eur: 'EUR',
  /** European Monetary Unit (E.M.U.-6) */
  Xbb: 'XBB',
  /** European Unit of Account 17(E.U.A.-17) */
  Xbd: 'XBD',
  /** European Unit of Account 9(E.U.A.-9) */
  Xbc: 'XBC',
  /** Falkland Islands Pound */
  Fkp: 'FKP',
  /** Fiji Dollar */
  Fjd: 'FJD',
  /** Forint */
  Huf: 'HUF',
  /** Ghana Cedi */
  Ghs: 'GHS',
  /** Gibraltar Pound */
  Gip: 'GIP',
  /** Gold */
  Xau: 'XAU',
  /** Gold-Franc */
  Xfo: 'XFO',
  /** Guarani */
  Pyg: 'PYG',
  /** Guinea Franc */
  Gnf: 'GNF',
  /** Guyana Dollar */
  Gyd: 'GYD',
  /** Haitian gourde */
  Htg: 'HTG',
  /** Hong Kong Dollar */
  Hkd: 'HKD',
  /** Hryvnia */
  Uah: 'UAH',
  /** Iceland Krona */
  Isk: 'ISK',
  /** Indian Rupee */
  Inr: 'INR',
  /** Iranian Rial */
  Irr: 'IRR',
  /** Iraqi Dinar */
  Iqd: 'IQD',
  /** Isle of Man Pound */
  Imp: 'IMP',
  /** Jamaican Dollar */
  Jmd: 'JMD',
  /** Jordanian Dinar */
  Jod: 'JOD',
  /** Kenyan Shilling */
  Kes: 'KES',
  /** Kina */
  Pgk: 'PGK',
  /** Kip */
  Lak: 'LAK',
  /** Kuwaiti Dinar */
  Kwd: 'KWD',
  /** Kwanza */
  Aoa: 'AOA',
  /** Kyat */
  Mmk: 'MMK',
  /** Lari */
  Gel: 'GEL',
  /** Latvian Lats */
  Lvl: 'LVL',
  /** Lebanese Pound */
  Lbp: 'LBP',
  /** Lek */
  All: 'ALL',
  /** Lempira */
  Hnl: 'HNL',
  /** Leone */
  Sll: 'SLL',
  /** Lesotho loti */
  Lsl: 'LSL',
  /** Liberian Dollar */
  Lrd: 'LRD',
  /** Libyan Dinar */
  Lyd: 'LYD',
  /** Lilangeni */
  Szl: 'SZL',
  /** Lithuanian Litas */
  Ltl: 'LTL',
  /** Malagasy Ariary */
  Mga: 'MGA',
  /** Malawian Kwacha */
  Mwk: 'MWK',
  /** Malaysian Ringgit */
  Myr: 'MYR',
  /** Manat */
  Tmm: 'TMM',
  /** Mauritius Rupee */
  Mur: 'MUR',
  /** Metical */
  Mzn: 'MZN',
  /** Mexican Unidad de Inversion (UDI) */
  Mxv: 'MXV',
  /** Mexican peso */
  Mxn: 'MXN',
  /** Moldovan Leu */
  Mdl: 'MDL',
  /** Moroccan Dirham */
  Mad: 'MAD',
  /** Mvdol */
  Bov: 'BOV',
  /** Naira */
  Ngn: 'NGN',
  /** Nakfa */
  Ern: 'ERN',
  /** Namibian Dollar */
  Nad: 'NAD',
  /** Nepalese Rupee */
  Npr: 'NPR',
  /** Netherlands Antillian Guilder */
  Ang: 'ANG',
  /** New Israeli Sheqel */
  Ils: 'ILS',
  /** New Leu */
  Ron: 'RON',
  /** New Taiwan Dollar */
  Twd: 'TWD',
  /** New Zealand Dollar */
  Nzd: 'NZD',
  /** North Korean Won */
  Kpw: 'KPW',
  /** Norwegian Krone */
  Nok: 'NOK',
  /** Nuevo Sol */
  Pen: 'PEN',
  /** Ouguiya */
  Mro: 'MRO',
  /** Paanga */
  Top: 'TOP',
  /** Pakistan Rupee */
  Pkr: 'PKR',
  /** Palladium */
  Xpd: 'XPD',
  /** Pataca */
  Mop: 'MOP',
  /** Philippine Peso */
  Php: 'PHP',
  /** Platinum */
  Xpt: 'XPT',
  /** Pound Sterling */
  Gbp: 'GBP',
  /** Pula */
  Bwp: 'BWP',
  /** Qatari Rial */
  Qar: 'QAR',
  /** Quetzal */
  Gtq: 'GTQ',
  /** Rand */
  Zar: 'ZAR',
  /** Rial Omani */
  Omr: 'OMR',
  /** Riel */
  Khr: 'KHR',
  /** Rufiyaa */
  Mvr: 'MVR',
  /** Rupiah */
  Idr: 'IDR',
  /** Russian Ruble */
  Rub: 'RUB',
  /** Rwanda Franc */
  Rwf: 'RWF',
  /** SDR */
  Xdr: 'XDR',
  /** Saint Helena Pound */
  Shp: 'SHP',
  /** Saudi Riyal */
  Sar: 'SAR',
  /** Serbian Dinar */
  Rsd: 'RSD',
  /** Seychelles Rupee */
  Scr: 'SCR',
  /** Silver */
  Xag: 'XAG',
  /** Singapore Dollar */
  Sgd: 'SGD',
  /** Solomon Islands Dollar */
  Sbd: 'SBD',
  /** Som */
  Kgs: 'KGS',
  /** Somali Shilling */
  Sos: 'SOS',
  /** Somoni */
  Tjs: 'TJS',
  /** South Sudanese Pound */
  Ssp: 'SSP',
  /** Sri Lanka Rupee */
  Lkr: 'LKR',
  /** Sucre */
  Xsu: 'XSU',
  /** Sudanese Pound */
  Sdg: 'SDG',
  /** Surinam Dollar */
  Srd: 'SRD',
  /** Swedish Krona */
  Sek: 'SEK',
  /** Swiss Franc */
  Chf: 'CHF',
  /** Syrian Pound */
  Syp: 'SYP',
  /** Taka */
  Bdt: 'BDT',
  /** Tala */
  Wst: 'WST',
  /** Tanzanian Shilling */
  Tzs: 'TZS',
  /** Tenge */
  Kzt: 'KZT',
  /** The codes assigned for transactions where no currency is involved */
  Xxx: 'XXX',
  /** Trinidad and Tobago Dollar */
  Ttd: 'TTD',
  /** Tugrik */
  Mnt: 'MNT',
  /** Tunisian Dinar */
  Tnd: 'TND',
  /** Turkish Lira */
  Try: 'TRY',
  /** Turkmenistan New Manat */
  Tmt: 'TMT',
  /** Tuvalu dollar */
  Tvd: 'TVD',
  /** UAE Dirham */
  Aed: 'AED',
  /** UIC-Franc */
  Xfu: 'XFU',
  /** US Dollar */
  Usd: 'USD',
  /** US Dollar (Next day) */
  Usn: 'USN',
  /** Uganda Shilling */
  Ugx: 'UGX',
  /** Unidad de Fomento */
  Clf: 'CLF',
  /** Unidad de Valor Real */
  Cou: 'COU',
  /** Uruguay Peso en Unidades Indexadas (URUIURUI) */
  Uyi: 'UYI',
  /** Uruguayan peso */
  Uyu: 'UYU',
  /** Uzbekistan Sum */
  Uzs: 'UZS',
  /** Vatu */
  Vuv: 'VUV',
  /** WIR Euro */
  Che: 'CHE',
  /** WIR Franc */
  Chw: 'CHW',
  /** Won */
  Krw: 'KRW',
  /** Yemeni Rial */
  Yer: 'YER',
  /** Yen */
  Jpy: 'JPY',
  /** Yuan Renminbi */
  Cny: 'CNY',
  /** Zambian Kwacha */
  Zmk: 'ZMK',
  /** Zambian Kwacha */
  Zmw: 'ZMW',
  /** Zimbabwe Dollar A/06 */
  Zwd: 'ZWD',
  /** Zimbabwe dollar A/08 */
  Zwn: 'ZWN',
  /** Zimbabwe dollar A/09 */
  Zwl: 'ZWL',
  /** Zloty */
  Pln: 'PLN'
});


export type TaxServiceBillingPlatformFeeNetCurrency = $Values<typeof TaxServiceBillingPlatformFeeNetCurrencyValues>;

export const TaxServiceBillingPlatformFeeTotalCurrencyValues = Object.freeze({
  /** ADB Unit of Account */
  Xua: 'XUA',
  /** Afghani */
  Afn: 'AFN',
  /** Algerian Dinar */
  Dzd: 'DZD',
  /** Argentine Peso */
  Ars: 'ARS',
  /** Armenian Dram */
  Amd: 'AMD',
  /** Aruban Guilder */
  Awg: 'AWG',
  /** Australian Dollar */
  Aud: 'AUD',
  /** Azerbaijanian Manat */
  Azn: 'AZN',
  /** Bahamian Dollar */
  Bsd: 'BSD',
  /** Bahraini Dinar */
  Bhd: 'BHD',
  /** Baht */
  Thb: 'THB',
  /** Balboa */
  Pab: 'PAB',
  /** Barbados Dollar */
  Bbd: 'BBD',
  /** Belarussian Ruble */
  Byn: 'BYN',
  /** Belarussian Ruble */
  Byr: 'BYR',
  /** Belize Dollar */
  Bzd: 'BZD',
  /** Bermudian Dollar (customarily known as Bermuda Dollar) */
  Bmd: 'BMD',
  /** Bhutanese ngultrum */
  Btn: 'BTN',
  /** Bolivar Fuerte */
  Vef: 'VEF',
  /** Boliviano */
  Bob: 'BOB',
  /** Bond Markets Units European Composite Unit (EURCO) */
  Xba: 'XBA',
  /** Brazilian Real */
  Brl: 'BRL',
  /** Brunei Dollar */
  Bnd: 'BND',
  /** Bulgarian Lev */
  Bgn: 'BGN',
  /** Burundi Franc */
  Bif: 'BIF',
  /** CFA Franc BCEAO */
  Xof: 'XOF',
  /** CFA franc BEAC */
  Xaf: 'XAF',
  /** CFP Franc */
  Xpf: 'XPF',
  /** Canadian Dollar */
  Cad: 'CAD',
  /** Cape Verde Escudo */
  Cve: 'CVE',
  /** Cayman Islands Dollar */
  Kyd: 'KYD',
  /** Chilean peso */
  Clp: 'CLP',
  /** Codes specifically reserved for testing purposes */
  Xts: 'XTS',
  /** Colombian peso */
  Cop: 'COP',
  /** Comoro Franc */
  Kmf: 'KMF',
  /** Congolese franc */
  Cdf: 'CDF',
  /** Convertible Marks */
  Bam: 'BAM',
  /** Cordoba Oro */
  Nio: 'NIO',
  /** Costa Rican Colon */
  Crc: 'CRC',
  /** Croatian Kuna */
  Hrk: 'HRK',
  /** Cuban Peso */
  Cup: 'CUP',
  /** Cuban convertible peso */
  Cuc: 'CUC',
  /** Czech Koruna */
  Czk: 'CZK',
  /** Dalasi */
  Gmd: 'GMD',
  /** Danish Krone */
  Dkk: 'DKK',
  /** Denar */
  Mkd: 'MKD',
  /** Djibouti Franc */
  Djf: 'DJF',
  /** Dobra */
  Std: 'STD',
  /** Dominican Peso */
  Dop: 'DOP',
  /** Dong */
  Vnd: 'VND',
  /** East Caribbean Dollar */
  Xcd: 'XCD',
  /** Egyptian Pound */
  Egp: 'EGP',
  /** El Salvador Colon */
  Svc: 'SVC',
  /** Ethiopian Birr */
  Etb: 'ETB',
  /** Euro */
  Eur: 'EUR',
  /** European Monetary Unit (E.M.U.-6) */
  Xbb: 'XBB',
  /** European Unit of Account 17(E.U.A.-17) */
  Xbd: 'XBD',
  /** European Unit of Account 9(E.U.A.-9) */
  Xbc: 'XBC',
  /** Falkland Islands Pound */
  Fkp: 'FKP',
  /** Fiji Dollar */
  Fjd: 'FJD',
  /** Forint */
  Huf: 'HUF',
  /** Ghana Cedi */
  Ghs: 'GHS',
  /** Gibraltar Pound */
  Gip: 'GIP',
  /** Gold */
  Xau: 'XAU',
  /** Gold-Franc */
  Xfo: 'XFO',
  /** Guarani */
  Pyg: 'PYG',
  /** Guinea Franc */
  Gnf: 'GNF',
  /** Guyana Dollar */
  Gyd: 'GYD',
  /** Haitian gourde */
  Htg: 'HTG',
  /** Hong Kong Dollar */
  Hkd: 'HKD',
  /** Hryvnia */
  Uah: 'UAH',
  /** Iceland Krona */
  Isk: 'ISK',
  /** Indian Rupee */
  Inr: 'INR',
  /** Iranian Rial */
  Irr: 'IRR',
  /** Iraqi Dinar */
  Iqd: 'IQD',
  /** Isle of Man Pound */
  Imp: 'IMP',
  /** Jamaican Dollar */
  Jmd: 'JMD',
  /** Jordanian Dinar */
  Jod: 'JOD',
  /** Kenyan Shilling */
  Kes: 'KES',
  /** Kina */
  Pgk: 'PGK',
  /** Kip */
  Lak: 'LAK',
  /** Kuwaiti Dinar */
  Kwd: 'KWD',
  /** Kwanza */
  Aoa: 'AOA',
  /** Kyat */
  Mmk: 'MMK',
  /** Lari */
  Gel: 'GEL',
  /** Latvian Lats */
  Lvl: 'LVL',
  /** Lebanese Pound */
  Lbp: 'LBP',
  /** Lek */
  All: 'ALL',
  /** Lempira */
  Hnl: 'HNL',
  /** Leone */
  Sll: 'SLL',
  /** Lesotho loti */
  Lsl: 'LSL',
  /** Liberian Dollar */
  Lrd: 'LRD',
  /** Libyan Dinar */
  Lyd: 'LYD',
  /** Lilangeni */
  Szl: 'SZL',
  /** Lithuanian Litas */
  Ltl: 'LTL',
  /** Malagasy Ariary */
  Mga: 'MGA',
  /** Malawian Kwacha */
  Mwk: 'MWK',
  /** Malaysian Ringgit */
  Myr: 'MYR',
  /** Manat */
  Tmm: 'TMM',
  /** Mauritius Rupee */
  Mur: 'MUR',
  /** Metical */
  Mzn: 'MZN',
  /** Mexican Unidad de Inversion (UDI) */
  Mxv: 'MXV',
  /** Mexican peso */
  Mxn: 'MXN',
  /** Moldovan Leu */
  Mdl: 'MDL',
  /** Moroccan Dirham */
  Mad: 'MAD',
  /** Mvdol */
  Bov: 'BOV',
  /** Naira */
  Ngn: 'NGN',
  /** Nakfa */
  Ern: 'ERN',
  /** Namibian Dollar */
  Nad: 'NAD',
  /** Nepalese Rupee */
  Npr: 'NPR',
  /** Netherlands Antillian Guilder */
  Ang: 'ANG',
  /** New Israeli Sheqel */
  Ils: 'ILS',
  /** New Leu */
  Ron: 'RON',
  /** New Taiwan Dollar */
  Twd: 'TWD',
  /** New Zealand Dollar */
  Nzd: 'NZD',
  /** North Korean Won */
  Kpw: 'KPW',
  /** Norwegian Krone */
  Nok: 'NOK',
  /** Nuevo Sol */
  Pen: 'PEN',
  /** Ouguiya */
  Mro: 'MRO',
  /** Paanga */
  Top: 'TOP',
  /** Pakistan Rupee */
  Pkr: 'PKR',
  /** Palladium */
  Xpd: 'XPD',
  /** Pataca */
  Mop: 'MOP',
  /** Philippine Peso */
  Php: 'PHP',
  /** Platinum */
  Xpt: 'XPT',
  /** Pound Sterling */
  Gbp: 'GBP',
  /** Pula */
  Bwp: 'BWP',
  /** Qatari Rial */
  Qar: 'QAR',
  /** Quetzal */
  Gtq: 'GTQ',
  /** Rand */
  Zar: 'ZAR',
  /** Rial Omani */
  Omr: 'OMR',
  /** Riel */
  Khr: 'KHR',
  /** Rufiyaa */
  Mvr: 'MVR',
  /** Rupiah */
  Idr: 'IDR',
  /** Russian Ruble */
  Rub: 'RUB',
  /** Rwanda Franc */
  Rwf: 'RWF',
  /** SDR */
  Xdr: 'XDR',
  /** Saint Helena Pound */
  Shp: 'SHP',
  /** Saudi Riyal */
  Sar: 'SAR',
  /** Serbian Dinar */
  Rsd: 'RSD',
  /** Seychelles Rupee */
  Scr: 'SCR',
  /** Silver */
  Xag: 'XAG',
  /** Singapore Dollar */
  Sgd: 'SGD',
  /** Solomon Islands Dollar */
  Sbd: 'SBD',
  /** Som */
  Kgs: 'KGS',
  /** Somali Shilling */
  Sos: 'SOS',
  /** Somoni */
  Tjs: 'TJS',
  /** South Sudanese Pound */
  Ssp: 'SSP',
  /** Sri Lanka Rupee */
  Lkr: 'LKR',
  /** Sucre */
  Xsu: 'XSU',
  /** Sudanese Pound */
  Sdg: 'SDG',
  /** Surinam Dollar */
  Srd: 'SRD',
  /** Swedish Krona */
  Sek: 'SEK',
  /** Swiss Franc */
  Chf: 'CHF',
  /** Syrian Pound */
  Syp: 'SYP',
  /** Taka */
  Bdt: 'BDT',
  /** Tala */
  Wst: 'WST',
  /** Tanzanian Shilling */
  Tzs: 'TZS',
  /** Tenge */
  Kzt: 'KZT',
  /** The codes assigned for transactions where no currency is involved */
  Xxx: 'XXX',
  /** Trinidad and Tobago Dollar */
  Ttd: 'TTD',
  /** Tugrik */
  Mnt: 'MNT',
  /** Tunisian Dinar */
  Tnd: 'TND',
  /** Turkish Lira */
  Try: 'TRY',
  /** Turkmenistan New Manat */
  Tmt: 'TMT',
  /** Tuvalu dollar */
  Tvd: 'TVD',
  /** UAE Dirham */
  Aed: 'AED',
  /** UIC-Franc */
  Xfu: 'XFU',
  /** US Dollar */
  Usd: 'USD',
  /** US Dollar (Next day) */
  Usn: 'USN',
  /** Uganda Shilling */
  Ugx: 'UGX',
  /** Unidad de Fomento */
  Clf: 'CLF',
  /** Unidad de Valor Real */
  Cou: 'COU',
  /** Uruguay Peso en Unidades Indexadas (URUIURUI) */
  Uyi: 'UYI',
  /** Uruguayan peso */
  Uyu: 'UYU',
  /** Uzbekistan Sum */
  Uzs: 'UZS',
  /** Vatu */
  Vuv: 'VUV',
  /** WIR Euro */
  Che: 'CHE',
  /** WIR Franc */
  Chw: 'CHW',
  /** Won */
  Krw: 'KRW',
  /** Yemeni Rial */
  Yer: 'YER',
  /** Yen */
  Jpy: 'JPY',
  /** Yuan Renminbi */
  Cny: 'CNY',
  /** Zambian Kwacha */
  Zmk: 'ZMK',
  /** Zambian Kwacha */
  Zmw: 'ZMW',
  /** Zimbabwe Dollar A/06 */
  Zwd: 'ZWD',
  /** Zimbabwe dollar A/08 */
  Zwn: 'ZWN',
  /** Zimbabwe dollar A/09 */
  Zwl: 'ZWL',
  /** Zloty */
  Pln: 'PLN'
});


export type TaxServiceBillingPlatformFeeTotalCurrency = $Values<typeof TaxServiceBillingPlatformFeeTotalCurrencyValues>;

export const TaxServiceBillingTotalNetAmountCurrencyValues = Object.freeze({
  /** ADB Unit of Account */
  Xua: 'XUA',
  /** Afghani */
  Afn: 'AFN',
  /** Algerian Dinar */
  Dzd: 'DZD',
  /** Argentine Peso */
  Ars: 'ARS',
  /** Armenian Dram */
  Amd: 'AMD',
  /** Aruban Guilder */
  Awg: 'AWG',
  /** Australian Dollar */
  Aud: 'AUD',
  /** Azerbaijanian Manat */
  Azn: 'AZN',
  /** Bahamian Dollar */
  Bsd: 'BSD',
  /** Bahraini Dinar */
  Bhd: 'BHD',
  /** Baht */
  Thb: 'THB',
  /** Balboa */
  Pab: 'PAB',
  /** Barbados Dollar */
  Bbd: 'BBD',
  /** Belarussian Ruble */
  Byn: 'BYN',
  /** Belarussian Ruble */
  Byr: 'BYR',
  /** Belize Dollar */
  Bzd: 'BZD',
  /** Bermudian Dollar (customarily known as Bermuda Dollar) */
  Bmd: 'BMD',
  /** Bhutanese ngultrum */
  Btn: 'BTN',
  /** Bolivar Fuerte */
  Vef: 'VEF',
  /** Boliviano */
  Bob: 'BOB',
  /** Bond Markets Units European Composite Unit (EURCO) */
  Xba: 'XBA',
  /** Brazilian Real */
  Brl: 'BRL',
  /** Brunei Dollar */
  Bnd: 'BND',
  /** Bulgarian Lev */
  Bgn: 'BGN',
  /** Burundi Franc */
  Bif: 'BIF',
  /** CFA Franc BCEAO */
  Xof: 'XOF',
  /** CFA franc BEAC */
  Xaf: 'XAF',
  /** CFP Franc */
  Xpf: 'XPF',
  /** Canadian Dollar */
  Cad: 'CAD',
  /** Cape Verde Escudo */
  Cve: 'CVE',
  /** Cayman Islands Dollar */
  Kyd: 'KYD',
  /** Chilean peso */
  Clp: 'CLP',
  /** Codes specifically reserved for testing purposes */
  Xts: 'XTS',
  /** Colombian peso */
  Cop: 'COP',
  /** Comoro Franc */
  Kmf: 'KMF',
  /** Congolese franc */
  Cdf: 'CDF',
  /** Convertible Marks */
  Bam: 'BAM',
  /** Cordoba Oro */
  Nio: 'NIO',
  /** Costa Rican Colon */
  Crc: 'CRC',
  /** Croatian Kuna */
  Hrk: 'HRK',
  /** Cuban Peso */
  Cup: 'CUP',
  /** Cuban convertible peso */
  Cuc: 'CUC',
  /** Czech Koruna */
  Czk: 'CZK',
  /** Dalasi */
  Gmd: 'GMD',
  /** Danish Krone */
  Dkk: 'DKK',
  /** Denar */
  Mkd: 'MKD',
  /** Djibouti Franc */
  Djf: 'DJF',
  /** Dobra */
  Std: 'STD',
  /** Dominican Peso */
  Dop: 'DOP',
  /** Dong */
  Vnd: 'VND',
  /** East Caribbean Dollar */
  Xcd: 'XCD',
  /** Egyptian Pound */
  Egp: 'EGP',
  /** El Salvador Colon */
  Svc: 'SVC',
  /** Ethiopian Birr */
  Etb: 'ETB',
  /** Euro */
  Eur: 'EUR',
  /** European Monetary Unit (E.M.U.-6) */
  Xbb: 'XBB',
  /** European Unit of Account 17(E.U.A.-17) */
  Xbd: 'XBD',
  /** European Unit of Account 9(E.U.A.-9) */
  Xbc: 'XBC',
  /** Falkland Islands Pound */
  Fkp: 'FKP',
  /** Fiji Dollar */
  Fjd: 'FJD',
  /** Forint */
  Huf: 'HUF',
  /** Ghana Cedi */
  Ghs: 'GHS',
  /** Gibraltar Pound */
  Gip: 'GIP',
  /** Gold */
  Xau: 'XAU',
  /** Gold-Franc */
  Xfo: 'XFO',
  /** Guarani */
  Pyg: 'PYG',
  /** Guinea Franc */
  Gnf: 'GNF',
  /** Guyana Dollar */
  Gyd: 'GYD',
  /** Haitian gourde */
  Htg: 'HTG',
  /** Hong Kong Dollar */
  Hkd: 'HKD',
  /** Hryvnia */
  Uah: 'UAH',
  /** Iceland Krona */
  Isk: 'ISK',
  /** Indian Rupee */
  Inr: 'INR',
  /** Iranian Rial */
  Irr: 'IRR',
  /** Iraqi Dinar */
  Iqd: 'IQD',
  /** Isle of Man Pound */
  Imp: 'IMP',
  /** Jamaican Dollar */
  Jmd: 'JMD',
  /** Jordanian Dinar */
  Jod: 'JOD',
  /** Kenyan Shilling */
  Kes: 'KES',
  /** Kina */
  Pgk: 'PGK',
  /** Kip */
  Lak: 'LAK',
  /** Kuwaiti Dinar */
  Kwd: 'KWD',
  /** Kwanza */
  Aoa: 'AOA',
  /** Kyat */
  Mmk: 'MMK',
  /** Lari */
  Gel: 'GEL',
  /** Latvian Lats */
  Lvl: 'LVL',
  /** Lebanese Pound */
  Lbp: 'LBP',
  /** Lek */
  All: 'ALL',
  /** Lempira */
  Hnl: 'HNL',
  /** Leone */
  Sll: 'SLL',
  /** Lesotho loti */
  Lsl: 'LSL',
  /** Liberian Dollar */
  Lrd: 'LRD',
  /** Libyan Dinar */
  Lyd: 'LYD',
  /** Lilangeni */
  Szl: 'SZL',
  /** Lithuanian Litas */
  Ltl: 'LTL',
  /** Malagasy Ariary */
  Mga: 'MGA',
  /** Malawian Kwacha */
  Mwk: 'MWK',
  /** Malaysian Ringgit */
  Myr: 'MYR',
  /** Manat */
  Tmm: 'TMM',
  /** Mauritius Rupee */
  Mur: 'MUR',
  /** Metical */
  Mzn: 'MZN',
  /** Mexican Unidad de Inversion (UDI) */
  Mxv: 'MXV',
  /** Mexican peso */
  Mxn: 'MXN',
  /** Moldovan Leu */
  Mdl: 'MDL',
  /** Moroccan Dirham */
  Mad: 'MAD',
  /** Mvdol */
  Bov: 'BOV',
  /** Naira */
  Ngn: 'NGN',
  /** Nakfa */
  Ern: 'ERN',
  /** Namibian Dollar */
  Nad: 'NAD',
  /** Nepalese Rupee */
  Npr: 'NPR',
  /** Netherlands Antillian Guilder */
  Ang: 'ANG',
  /** New Israeli Sheqel */
  Ils: 'ILS',
  /** New Leu */
  Ron: 'RON',
  /** New Taiwan Dollar */
  Twd: 'TWD',
  /** New Zealand Dollar */
  Nzd: 'NZD',
  /** North Korean Won */
  Kpw: 'KPW',
  /** Norwegian Krone */
  Nok: 'NOK',
  /** Nuevo Sol */
  Pen: 'PEN',
  /** Ouguiya */
  Mro: 'MRO',
  /** Paanga */
  Top: 'TOP',
  /** Pakistan Rupee */
  Pkr: 'PKR',
  /** Palladium */
  Xpd: 'XPD',
  /** Pataca */
  Mop: 'MOP',
  /** Philippine Peso */
  Php: 'PHP',
  /** Platinum */
  Xpt: 'XPT',
  /** Pound Sterling */
  Gbp: 'GBP',
  /** Pula */
  Bwp: 'BWP',
  /** Qatari Rial */
  Qar: 'QAR',
  /** Quetzal */
  Gtq: 'GTQ',
  /** Rand */
  Zar: 'ZAR',
  /** Rial Omani */
  Omr: 'OMR',
  /** Riel */
  Khr: 'KHR',
  /** Rufiyaa */
  Mvr: 'MVR',
  /** Rupiah */
  Idr: 'IDR',
  /** Russian Ruble */
  Rub: 'RUB',
  /** Rwanda Franc */
  Rwf: 'RWF',
  /** SDR */
  Xdr: 'XDR',
  /** Saint Helena Pound */
  Shp: 'SHP',
  /** Saudi Riyal */
  Sar: 'SAR',
  /** Serbian Dinar */
  Rsd: 'RSD',
  /** Seychelles Rupee */
  Scr: 'SCR',
  /** Silver */
  Xag: 'XAG',
  /** Singapore Dollar */
  Sgd: 'SGD',
  /** Solomon Islands Dollar */
  Sbd: 'SBD',
  /** Som */
  Kgs: 'KGS',
  /** Somali Shilling */
  Sos: 'SOS',
  /** Somoni */
  Tjs: 'TJS',
  /** South Sudanese Pound */
  Ssp: 'SSP',
  /** Sri Lanka Rupee */
  Lkr: 'LKR',
  /** Sucre */
  Xsu: 'XSU',
  /** Sudanese Pound */
  Sdg: 'SDG',
  /** Surinam Dollar */
  Srd: 'SRD',
  /** Swedish Krona */
  Sek: 'SEK',
  /** Swiss Franc */
  Chf: 'CHF',
  /** Syrian Pound */
  Syp: 'SYP',
  /** Taka */
  Bdt: 'BDT',
  /** Tala */
  Wst: 'WST',
  /** Tanzanian Shilling */
  Tzs: 'TZS',
  /** Tenge */
  Kzt: 'KZT',
  /** The codes assigned for transactions where no currency is involved */
  Xxx: 'XXX',
  /** Trinidad and Tobago Dollar */
  Ttd: 'TTD',
  /** Tugrik */
  Mnt: 'MNT',
  /** Tunisian Dinar */
  Tnd: 'TND',
  /** Turkish Lira */
  Try: 'TRY',
  /** Turkmenistan New Manat */
  Tmt: 'TMT',
  /** Tuvalu dollar */
  Tvd: 'TVD',
  /** UAE Dirham */
  Aed: 'AED',
  /** UIC-Franc */
  Xfu: 'XFU',
  /** US Dollar */
  Usd: 'USD',
  /** US Dollar (Next day) */
  Usn: 'USN',
  /** Uganda Shilling */
  Ugx: 'UGX',
  /** Unidad de Fomento */
  Clf: 'CLF',
  /** Unidad de Valor Real */
  Cou: 'COU',
  /** Uruguay Peso en Unidades Indexadas (URUIURUI) */
  Uyi: 'UYI',
  /** Uruguayan peso */
  Uyu: 'UYU',
  /** Uzbekistan Sum */
  Uzs: 'UZS',
  /** Vatu */
  Vuv: 'VUV',
  /** WIR Euro */
  Che: 'CHE',
  /** WIR Franc */
  Chw: 'CHW',
  /** Won */
  Krw: 'KRW',
  /** Yemeni Rial */
  Yer: 'YER',
  /** Yen */
  Jpy: 'JPY',
  /** Yuan Renminbi */
  Cny: 'CNY',
  /** Zambian Kwacha */
  Zmk: 'ZMK',
  /** Zambian Kwacha */
  Zmw: 'ZMW',
  /** Zimbabwe Dollar A/06 */
  Zwd: 'ZWD',
  /** Zimbabwe dollar A/08 */
  Zwn: 'ZWN',
  /** Zimbabwe dollar A/09 */
  Zwl: 'ZWL',
  /** Zloty */
  Pln: 'PLN'
});


export type TaxServiceBillingTotalNetAmountCurrency = $Values<typeof TaxServiceBillingTotalNetAmountCurrencyValues>;

export type TaxServiceBillingType = {|
  ...Node,
  ...{|
    __typename?: 'TaxServiceBillingType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business?: ?BusinessType,
    taxAdvisory: TaxAdvisoryType,
    taxAdvisor?: ?TaxAdvisorType,
    serviceDate?: ?$ElementType<Scalars, 'Date'>,
    platformFeeTotalCurrency: TaxServiceBillingPlatformFeeTotalCurrency,
    platformFeeTotal: $ElementType<Scalars, 'DecimalScalar'>,
    platformFeeNetCurrency: TaxServiceBillingPlatformFeeNetCurrency,
    platformFeeNet: $ElementType<Scalars, 'DecimalScalar'>,
    totalNetAmountCurrency: TaxServiceBillingTotalNetAmountCurrency,
    totalNetAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    paymentDate?: ?$ElementType<Scalars, 'Date'>,
    state?: ?ServiceFeeState,
    serviceType?: ?TaxServiceType,
    customDescription?: ?$ElementType<Scalars, 'String'>,
    invoiceNumber?: ?$ElementType<Scalars, 'String'>,
    hasFailedAttempt: $ElementType<Scalars, 'Boolean'>,
    serviceNameHumanized?: ?$ElementType<Scalars, 'String'>,
  |}
|};


export type TaxServiceBillingTypeServiceNameHumanizedArgs = {|
  isCapitalized?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type TaxServiceBillingTypeConnection = {|
  __typename?: 'TaxServiceBillingTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?TaxServiceBillingTypeEdge>,
|};

/** A Relay edge containing a `TaxServiceBillingType` and its cursor. */
export type TaxServiceBillingTypeEdge = {|
  __typename?: 'TaxServiceBillingTypeEdge',
  /** The item at the end of the edge */
  node?: ?TaxServiceBillingType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export const TaxServiceTypeValues = Object.freeze({
  AboutServices: 'about_services',
  Bwa: 'bwa',
  Custom: 'custom',
  GeneralCall: 'general_call',
  GeneralLongCall: 'general_long_call',
  GeneralText: 'general_text',
  IncomeTaxReturnPreparation: 'income_tax_return_preparation',
  ReviewAnnualTaxReports: 'review_annual_tax_reports',
  ReviewRegistrationForm: 'review_registration_form',
  TaxOfficeMessage: 'tax_office_message'
});


export type TaxServiceType = $Values<typeof TaxServiceTypeValues>;

export const UnsubscribeReasonValues = Object.freeze({
  SwitchToDifferentPlan: 'switch_to_different_plan',
  NoLongerFreelancer: 'no_longer_freelancer',
  SwitchToAnotherService: 'switch_to_another_service',
  TooExpensive: 'too_expensive',
  Other: 'other'
});


export type UnsubscribeReason = $Values<typeof UnsubscribeReasonValues>;

export type UnsupportedBankAccountType = {|
  ...Node,
  ...{|
    __typename?: 'UnsupportedBankAccountType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessType,
    bankName: $ElementType<Scalars, 'String'>,
    isBusinessAccount?: ?$ElementType<Scalars, 'Boolean'>,
  |}
|};

export type UnsupportedBankAccountTypeConnection = {|
  __typename?: 'UnsupportedBankAccountTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?UnsupportedBankAccountTypeEdge>,
  count?: ?$ElementType<Scalars, 'Int'>,
  exists?: ?$ElementType<Scalars, 'Boolean'>,
|};

/** A Relay edge containing a `UnsupportedBankAccountType` and its cursor. */
export type UnsupportedBankAccountTypeEdge = {|
  __typename?: 'UnsupportedBankAccountTypeEdge',
  /** The item at the end of the edge */
  node?: ?UnsupportedBankAccountType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export type UpdateIncomeTaxReportInput = {|
  data: $ElementType<Scalars, 'JSONString'>,
  pageName: $ElementType<Scalars, 'String'>,
  isValid: $ElementType<Scalars, 'Boolean'>,
  year: $ElementType<Scalars, 'Int'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateIncomeTaxReportPayload = {|
  __typename?: 'UpdateIncomeTaxReportPayload',
  incomeTaxReport?: ?IncomeTaxReportType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateTaxRegistrationInput = {|
  data: $ElementType<Scalars, 'JSONString'>,
  pageName: $ElementType<Scalars, 'String'>,
  isValid: $ElementType<Scalars, 'Boolean'>,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateTaxRegistrationPayload = {|
  __typename?: 'UpdateTaxRegistrationPayload',
  taxRegistration?: ?TaxRegistrationType,
  clientMutationId?: ?$ElementType<Scalars, 'String'>,
|};

export type UserSettingsType = {|
  ...Node,
  ...{|
    __typename?: 'UserSettingsType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    user?: ?UserType,
    languageCode: $ElementType<Scalars, 'String'>,
  |}
|};

export type UserType = {|
  ...Node,
  ...{|
    __typename?: 'UserType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    password: $ElementType<Scalars, 'String'>,
    lastLogin?: ?$ElementType<Scalars, 'DateTime'>,
    /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: $ElementType<Scalars, 'Boolean'>,
    /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: $ElementType<Scalars, 'String'>,
    firstName: $ElementType<Scalars, 'String'>,
    lastName: $ElementType<Scalars, 'String'>,
    email: $ElementType<Scalars, 'String'>,
    /** Designates whether the user can log into this admin site. */
  isStaff: $ElementType<Scalars, 'Boolean'>,
    /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: $ElementType<Scalars, 'Boolean'>,
    dateJoined: $ElementType<Scalars, 'DateTime'>,
    taxAdvisors: TaxAdvisorTypeConnection,
    userSettings?: ?UserSettingsType,
    business?: ?BusinessType,
    intercomUserId?: ?$ElementType<Scalars, 'String'>,
    pictureUrl?: ?$ElementType<Scalars, 'String'>,
  |}
|};


export type UserTypeTaxAdvisorsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export const VatExemptionParagraphValues = Object.freeze({
  Paragraph_1: 'paragraph_1',
  Paragraph_2: 'paragraph_2',
  Paragraph_3: 'paragraph_3',
  Paragraph_4: 'paragraph_4',
  Paragraph_4a: 'paragraph_4a',
  Paragraph_4b: 'paragraph_4b',
  Paragraph_5: 'paragraph_5',
  Paragraph_6: 'paragraph_6',
  Paragraph_7: 'paragraph_7',
  Paragraph_8: 'paragraph_8',
  Paragraph_9: 'paragraph_9',
  Paragraph_10: 'paragraph_10',
  Paragraph_11: 'paragraph_11',
  Paragraph_11a: 'paragraph_11a',
  Paragraph_11b: 'paragraph_11b',
  Paragraph_12: 'paragraph_12',
  Paragraph_13: 'paragraph_13',
  Paragraph_14: 'paragraph_14',
  Paragraph_15: 'paragraph_15',
  Paragraph_15a: 'paragraph_15a',
  Paragraph_15b: 'paragraph_15b',
  Paragraph_15c: 'paragraph_15c',
  Paragraph_16: 'paragraph_16',
  Paragraph_17: 'paragraph_17',
  Paragraph_18: 'paragraph_18',
  Paragraph_18a: 'paragraph_18a',
  Paragraph_20: 'paragraph_20',
  Paragraph_21: 'paragraph_21',
  Paragraph_22: 'paragraph_22',
  Paragraph_23: 'paragraph_23',
  Paragraph_24: 'paragraph_24',
  Paragraph_25: 'paragraph_25',
  Paragraph_26: 'paragraph_26',
  Paragraph_27: 'paragraph_27',
  Paragraph_28: 'paragraph_28',
  Paragraph_29: 'paragraph_29'
});


export type VatExemptionParagraph = $Values<typeof VatExemptionParagraphValues>;

export const VatReportBookingCategoryValues = Object.freeze({
  LocalInputTax: 'local_input_tax',
  TaxFreeSalesP7OrUnder: 'tax_free_sales_p7_or_under',
  TaxFreeSales: 'tax_free_sales',
  TaxFreeRentalAndLeasingSales: 'tax_free_rental_and_leasing_sales',
  SalesWith_7Vat: 'sales_with_7_vat',
  SalesWith_19Vat: 'sales_with_19_vat',
  SalesOtherVatRate: 'sales_other_vat_rate',
  TaxFreeSaleEuGoods: 'tax_free_sale_eu_goods',
  TaxFreeSaleEuService: 'tax_free_sale_eu_service',
  TaxFreeSaleNonEuGoods: 'tax_free_sale_non_eu_goods',
  TaxFreeSaleNonEuService: 'tax_free_sale_non_eu_service',
  TaxableGoodsBoughtFromEuSupplier_19Vat: 'taxable_goods_bought_from_eu_supplier_19_vat',
  TaxableGoodsBoughtFromEuSupplier_7Vat: 'taxable_goods_bought_from_eu_supplier_7_vat',
  TaxableGoodsBoughtFromEuSupplierOtherVat: 'taxable_goods_bought_from_eu_supplier_other_vat',
  InputTaxFromGoodsBoughtEuSupplier: 'input_tax_from_goods_bought_eu_supplier',
  TaxableServiceFromEuSupplier: 'taxable_service_from_eu_supplier',
  InputTaxServicesFromEuSupplier: 'input_tax_services_from_eu_supplier',
  SmallBusinessOwnerIncome: 'small_business_owner_income',
  SmallBusinessOwnerTaxableGoodsBoughtFromEuSupplier_19Vat: 'small_business_owner_taxable_goods_bought_from_eu_supplier_19_vat',
  SmallBusinessOwnerTaxableGoodsBoughtFromEuSupplier_7Vat: 'small_business_owner_taxable_goods_bought_from_eu_supplier_7_vat',
  SmallBusinessOwnerTaxableGoodsBoughtFromEuSupplierOtherVat: 'small_business_owner_taxable_goods_bought_from_eu_supplier_other_vat',
  SmallBusinessOwnerTaxableServiceFromEuSupplier: 'small_business_owner_taxable_service_from_eu_supplier'
});


export type VatReportBookingCategory = $Values<typeof VatReportBookingCategoryValues>;

export const VatReportingIntervalValues = Object.freeze({
  Monthly: 'monthly',
  Quarterly: 'quarterly',
  Yearly: 'yearly',
  Unknown: 'unknown'
});


export type VatReportingInterval = $Values<typeof VatReportingIntervalValues>;

export type VatReportInterface = {|
  totalVatToBePaid?: ?$ElementType<Scalars, 'DecimalScalar'>,
  allLines?: ?Array<?VatReportLineType>,
  shouldPayVatInYear?: ?$ElementType<Scalars, 'Boolean'>,
  business?: ?BusinessType,
  paymentReference?: ?$ElementType<Scalars, 'String'>,
|};

export const VatReportLineCategoryValues = Object.freeze({
  Expense: 'expense',
  Income: 'income'
});


export type VatReportLineCategory = $Values<typeof VatReportLineCategoryValues>;

export type VatReportLineType = {|
  ...Node,
  ...{|
    __typename?: 'VatReportLineType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    vatReport: VatReportType,
    bookingCategory?: ?VatReportBookingCategory,
    netAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    taxAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    lineCategory?: ?VatReportLineCategory,
    recordIds?: ?Array<?$ElementType<Scalars, 'String'>>,
  |}
|};

export type VatReportLineTypeConnection = {|
  __typename?: 'VatReportLineTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?VatReportLineTypeEdge>,
|};

/** A Relay edge containing a `VatReportLineType` and its cursor. */
export type VatReportLineTypeEdge = {|
  __typename?: 'VatReportLineTypeEdge',
  /** The item at the end of the edge */
  node?: ?VatReportLineType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export const VatReportReportPeriodValues = Object.freeze({
  /** Monthly */
  Monthly: 'MONTHLY',
  /** Quarterly */
  Quarterly: 'QUARTERLY',
  /** Yearly */
  Yearly: 'YEARLY',
  /** Unknown */
  Unknown: 'UNKNOWN'
});


export type VatReportReportPeriod = $Values<typeof VatReportReportPeriodValues>;

export type VatReportType = {|
  ...Node,
  ...VatReportInterface,
  ...BaseReportInterface,
  ...BaseVatReportInterface,
  ...ReportPeriodInterface,
  ...{|
    __typename?: 'VatReportType',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    reportedDate?: ?$ElementType<Scalars, 'Date'>,
    sentWithSorted?: ?$ElementType<Scalars, 'Boolean'>,
    isCorrection?: ?$ElementType<Scalars, 'Boolean'>,
    confirmationDocument?: ?DocumentType,
    business?: ?BusinessType,
    periodStartDate?: ?$ElementType<Scalars, 'Date'>,
    reportPeriod: VatReportReportPeriod,
    importedTotalAmount?: ?$ElementType<Scalars, 'DecimalScalar'>,
    recordSet: RecordTypeConnection,
    autorecordSet: AutoRecordTypeConnection,
    storedLines: VatReportLineTypeConnection,
    totalVatToBePaid?: ?$ElementType<Scalars, 'DecimalScalar'>,
    allLines?: ?Array<?VatReportLineType>,
    shouldPayVatInYear?: ?$ElementType<Scalars, 'Boolean'>,
    paymentReference?: ?$ElementType<Scalars, 'String'>,
    hideOnTimeline?: ?$ElementType<Scalars, 'Boolean'>,
    isSent?: ?$ElementType<Scalars, 'Boolean'>,
    submissionDeadline?: ?$ElementType<Scalars, 'Date'>,
    requiresSubscription?: ?$ElementType<Scalars, 'Boolean'>,
    isFeatureInTrial?: ?$ElementType<Scalars, 'Boolean'>,
    unpaidRecords?: ?RecordTypeConnection,
    invoicesWithoutTaxNumber?: ?RecordTypeConnection,
    expensesWithoutAttributionToVatExemptRevenue?: ?RecordTypeConnection,
    reportingInterval?: ?VatReportingInterval,
    submitUrgencyLevel?: ?ReportSubmitUrgencyLevel,
    allowSubmission?: ?$ElementType<Scalars, 'Boolean'>,
    vatCollected?: ?$ElementType<Scalars, 'DecimalScalar'>,
    inputTax?: ?$ElementType<Scalars, 'DecimalScalar'>,
    totalRevenueFromServiceToCustomersInTheEu?: ?$ElementType<Scalars, 'DecimalScalar'>,
    isFirstVatReportWithSorted?: ?$ElementType<Scalars, 'Boolean'>,
    isPaid?: ?$ElementType<Scalars, 'Boolean'>,
    paymentRecord?: ?RecordType,
  |}
|};


export type VatReportTypeRecordSetArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type VatReportTypeAutorecordSetArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type VatReportTypeStoredLinesArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type VatReportTypeUnpaidRecordsArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type VatReportTypeInvoicesWithoutTaxNumberArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type VatReportTypeExpensesWithoutAttributionToVatExemptRevenueArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type VatReportTypeConnection = {|
  __typename?: 'VatReportTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?VatReportTypeEdge>,
|};

/** A Relay edge containing a `VatReportType` and its cursor. */
export type VatReportTypeEdge = {|
  __typename?: 'VatReportTypeEdge',
  /** The item at the end of the edge */
  node?: ?VatReportType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export type WorkSheetType = {|
  __typename?: 'WorkSheetType',
  name?: ?$ElementType<Scalars, 'String'>,
  rows?: ?$ElementType<Scalars, 'JSONString'>,
  columnsInfo?: ?Array<?ColumnInfoType>,
|};

export type YearlyChildInfoType = {|
  ...Node,
  ...{|
    __typename?: 'YearlyChildInfoType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    incomeTaxReport: IncomeTaxReportType,
    data?: ?$ElementType<Scalars, 'JSONString'>,
    name?: ?$ElementType<Scalars, 'String'>,
  |}
|};

export type YearlyChildInfoTypeConnection = {|
  __typename?: 'YearlyChildInfoTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?YearlyChildInfoTypeEdge>,
|};

/** A Relay edge containing a `YearlyChildInfoType` and its cursor. */
export type YearlyChildInfoTypeEdge = {|
  __typename?: 'YearlyChildInfoTypeEdge',
  /** The item at the end of the edge */
  node?: ?YearlyChildInfoType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export type YearlyConfigType = {|
  ...Node,
  ...{|
    __typename?: 'YearlyConfigType',
    /** The ID of the object. */
  id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    business: BusinessType,
    year?: ?$ElementType<Scalars, 'Int'>,
    shouldPayVat?: ?$ElementType<Scalars, 'Boolean'>,
    vatReportingInterval?: ?VatReportingInterval,
  |}
|};

export type YearlyConfigTypeConnection = {|
  __typename?: 'YearlyConfigTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?YearlyConfigTypeEdge>,
|};

/** A Relay edge containing a `YearlyConfigType` and its cursor. */
export type YearlyConfigTypeEdge = {|
  __typename?: 'YearlyConfigTypeEdge',
  /** The item at the end of the edge */
  node?: ?YearlyConfigType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};

export type ZmReportDataLine = {|
  __typename?: 'ZmReportDataLine',
  vatNumber?: ?$ElementType<Scalars, 'String'>,
  amount?: ?$ElementType<Scalars, 'DecimalScalar'>,
  saleType?: ?$ElementType<Scalars, 'String'>,
  recordIds?: ?Array<?$ElementType<Scalars, 'String'>>,
|};

export type ZmReportType = {|
  ...Node,
  ...BaseReportInterface,
  ...ReportPeriodInterface,
  ...{|
    __typename?: 'ZmReportType',
    id: $ElementType<Scalars, 'ID'>,
    updatedAt: $ElementType<Scalars, 'DateTime'>,
    createdAt: $ElementType<Scalars, 'DateTime'>,
    reportedDate?: ?$ElementType<Scalars, 'Date'>,
    sentWithSorted?: ?$ElementType<Scalars, 'Boolean'>,
    isCorrection?: ?$ElementType<Scalars, 'Boolean'>,
    confirmationDocument?: ?DocumentType,
    business: BusinessType,
    periodStartDate?: ?$ElementType<Scalars, 'Date'>,
    recordSet: RecordTypeConnection,
    autorecordSet: AutoRecordTypeConnection,
    hideOnTimeline?: ?$ElementType<Scalars, 'Boolean'>,
    isSent?: ?$ElementType<Scalars, 'Boolean'>,
    submissionDeadline?: ?$ElementType<Scalars, 'Date'>,
    requiresSubscription?: ?$ElementType<Scalars, 'Boolean'>,
    isFeatureInTrial?: ?$ElementType<Scalars, 'Boolean'>,
    reportingInterval?: ?VatReportingInterval,
    submitUrgencyLevel?: ?ReportSubmitUrgencyLevel,
    allowSubmission?: ?$ElementType<Scalars, 'Boolean'>,
    lines?: ?Array<?ZmReportDataLine>,
    totalRevenue?: ?$ElementType<Scalars, 'DecimalScalar'>,
  |}
|};


export type ZmReportTypeRecordSetArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};


export type ZmReportTypeAutorecordSetArgs = {|
  offset?: ?$ElementType<Scalars, 'Int'>,
  before?: ?$ElementType<Scalars, 'String'>,
  after?: ?$ElementType<Scalars, 'String'>,
  first?: ?$ElementType<Scalars, 'Int'>,
  last?: ?$ElementType<Scalars, 'Int'>,
|};

export type ZmReportTypeConnection = {|
  __typename?: 'ZmReportTypeConnection',
  /** Pagination data for this connection. */
  pageInfo: PageInfo,
  /** Contains the nodes in this connection. */
  edges: Array<?ZmReportTypeEdge>,
|};

/** A Relay edge containing a `ZmReportType` and its cursor. */
export type ZmReportTypeEdge = {|
  __typename?: 'ZmReportTypeEdge',
  /** The item at the end of the edge */
  node?: ?ZmReportType,
  /** A cursor for use in pagination */
  cursor: $ElementType<Scalars, 'String'>,
|};
