import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: "57f6fa39e1dec2c6766122f1d52ebc59",
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: process.env.NODE_ENV,
  plugins: [new BugsnagPluginReact()],
  onError: event => {
    event.request.referer = document.referrer;
    const error = event.errors[0];
    event.groupingHash = `${error?.errorClass}: ${error?.errorMessage}`;
  },
});

export const setBugsnagUserMeta = ({email, username}) => {
  if (email) {
    Bugsnag.addMetadata('user', 'email', email);
  }
  if (username) {
    Bugsnag.addMetadata('user', 'username', username);
  }
};

export const bugsnagNotifyError = (error, metaData) => {
  Bugsnag.notify(error, (event) => {
    event.addMetadata('extra', 'metaData', metaData);
  });
};
