import React from "react";

const NoConnectionIcon = () => {
  return (
    <svg height="100%" viewBox="0 0 512 512" width="100%" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m185.699219 101.296875-32.398438-32.398437c32.660157-10.644532 67.183594-16.183594 102.695313-16.183594 92.578125 0 178.515625 37.496094 241.972656 105.585937l13.65625 14.652344-29.300781 27.308594-13.65625-14.652344c-55.800781-59.867187-131.328125-92.835937-212.671875-92.835937-24.0625 0-47.613282 2.90625-70.296875 8.523437zm63.679687 63.675781 43.566406 43.570313c39.207032 7.703125 75.957032 27.578125 106.070313 57.859375l14.125 14.203125 28.398437-28.246094-14.121093-14.203125c-47-47.257812-107.878907-73.285156-171.421875-73.285156-2.210938 0-4.414063.039062-6.617188.101562zm5.621094 230.222656c-17.699219 0-32.046875 14.347657-32.046875 32.042969 0 17.699219 14.347656 32.046875 32.046875 32.046875 17.695312 0 32.042969-14.347656 32.042969-32.046875 0-17.695312-14.347657-32.042969-32.042969-32.042969zm257 88.480469-483.675781-483.675781-28.324219 28.324219 77.03125 77.03125c-22.746094 14.757812-43.898438 32.449219-63 52.945312l-13.65625 14.648438 29.304688 27.3125 13.652343-14.652344c18.847657-20.21875 39.957031-37.339844 62.769531-51.183594l52.027344 52.027344c-26.773437 12.3125-51.671875 29.699219-73.554687 51.707031l-14.121094 14.203125 28.40625 28.242188 14.121094-14.203125c22.207031-22.335938 48.027343-39.007813 75.785156-49.3125l60.09375 60.089844c-31.519531 1.628906-65.160156 16.925781-89.015625 40.757812l-14.171875 14.152344 28.308594 28.339844 14.171875-14.152344c17.753906-17.734375 43.753906-29.191406 66.25-29.191406h.011718 1.394532.011718c15.425782 0 32.503907 5.390624 47.441407 14.5l180.414062 180.414062zm0 0"/>
    </svg>
  );
};

export default NoConnectionIcon;
