import React from "react";
import {Button, Card, CardTitle, Row} from "reactstrap";
import {Colxx} from "../common/CustomBootstrap";
import analytics from "../utils/analytics";
import IntlMessages from "../utils/IntlMessages";

const UnhandledError = ({error}) => {
  analytics.trackEvent("Unhandled Error", {error});
  return (
    <div className="d-flex flex-grow-1 flex-column">
      <div className="fixed-background"/>
      <Row className="h-100 mt-5">
        <Colxx xxs="2" md="2"/>
        <Colxx xxs="8" md="8">
          <Card className="auth-card">
            <div className="form-side">
              <CardTitle className="mb-4">
                <IntlMessages id="pages.error-title"/>
              </CardTitle>
              <Button
                href="/app"
                color="primary"
                className="btn-shadow"
                size="lg"
              >
                <IntlMessages id="pages.go-back-home"/>
              </Button>
            </div>
          </Card>
        </Colxx>
        <Colxx xxs="2" md="2"/>
      </Row>
    </div>
  );
};


export default UnhandledError;
