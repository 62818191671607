import React from "react";
import {NoConnectionView, ServerErrorView} from "../common/ErrorViews";
import IntlMessages from "../utils/IntlMessages";
import {reportError} from "../utils/errorReporting";
import {FormattedMessage} from "react-intl";
import ContactUsLink from "../common/ContactUsLink";


class RelayError {
  constructor(data) {
    this.data = data;
  }
}



export class ConnectionError extends RelayError {
}

export class ServerError extends RelayError {
}

export const renderRelayErrorView = (error, query, variables) => {
  if (error.constructor === ConnectionError) {
    reportError(new Error(`Connection error in data fetching (${error.data})`), {error, query, variables});
    return <NoConnectionView/>;
  } else {
    reportError(new Error(`Data fetch error. Backend error:'${error?.data?.[0]?.message}'`), {error, query, variables});
    return <ServerErrorView/>;
  }
};

export const renderConnectionError = () => {
  return <IntlMessages id="relayError.connectionErrorOnSave.message"/>;
};

export const renderServerError = (error) => {
  const _errors = (Array.isArray(error) ? error : [error]).flatMap(({data}) => data);

  if (_errors.length === 0) return null;

  return _errors.map((error, index) => {
    if (!error?.extensions?.code) {
      return (
          <FormattedMessage
              key={index}
              id="relayError.serverErrorOnSave.message"
              values={{contactUsLink: <ContactUsLink referer="Error On Mutation"/>}}
          />
      );
    }

    return (
        <FormattedMessage
            key={index}
            id={error?.extensions?.code}
            values={error?.extensions?.params}
        />
    );
  });
};

export const renderRelayErrorMessage = (error) => {
  return error.constructor === ConnectionError
    ? renderConnectionError()
    : renderServerError(error);
};
