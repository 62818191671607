import {createMutation} from "../relay/mutation";
import graphql from "babel-plugin-relay/macro";

export const IntroduceAnonymousLoginMutation = createMutation(graphql`
    mutation IntroduceAnonymousLoginMutation($input: IntroduceAnonymousLoginInput!) {
        IntroduceAnonymousLogin(input: $input) {
            token
        }
    }
`);
