import graphql from 'babel-plugin-relay/macro';
import {createMutation} from "./relay/mutation";

const mutation = graphql`
    mutation IntroduceReferralCodeReceivedMutation($input: IntroduceReferralCodeReceivedInput!) {
        IntroduceReferralCodeReceived(input: $input) {
            clientMutationId
        }
    }
`;

export default createMutation(mutation);


