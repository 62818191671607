import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/sass/typography.scss";
import "react-circular-progressbar/dist/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-table/react-table.css";
import 'react-image-lightbox/style.css';
import 'flag-icon-css/css/flag-icon.css';
import "./assets/css/sass/flags.scss";

export const loadDynamicStyles = (fn) => {
  import('./assets/css/sass/_gogo.style.scss').then(x => {
    fn();
  });
};
