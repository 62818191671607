import {isDev} from "./Utils";

export function initIntercom(extraArgs) {
  return window.Intercom("boot", {
    app_id: isDev() ? "gvbff5ui" : "biwa8bjn",
    ...extraArgs
  });
}

export function updateIntercomUserProps(properties) {
  return window.Intercom('update', properties);
}

export function trackIntercomEvent(event, props) {
  window.Intercom('trackEvent', event, props);
}

export function updateIntercomAboutPageChange() {
  return window.Intercom("update", {last_request_at: parseInt((new Date()).getTime() / 1000)});
}
