import React from 'react';
import { Col } from 'reactstrap';
import classNames from 'classnames';

const Colxx = (props) => (
  <Col {...props} widths={['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']} />
);

const Separator = (props) => {
  const cssClasses = classNames('separator', {[props.className]: !!props.className});
  return <div className={cssClasses} />;
};

export { Colxx, Separator };
