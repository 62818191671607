import {createIntl, createIntlCache} from 'react-intl';
import _ from 'lodash';
import enMessages from './locales/en.json';
import deMessages from './locales/de.json';
import {registerLocale} from "react-datepicker";
import de from "date-fns/locale/de";
import 'moment/locale/de';

// Support german for ReactDatePicker
registerLocale('de', de);


const cache = createIntlCache();

// Convert messages object into flat key -> value structure
const flattenMessages = (messages) => {
  const constructedKeysMessages = {};
  _.forEach(messages, (messageValue, messageKey) => {
    if (typeof messageValue === 'string') {
      constructedKeysMessages[messageKey] = messageValue;
    } else {
      _.assign(constructedKeysMessages, messages);
      _.assign(constructedKeysMessages, _.mapKeys(flattenMessages(messageValue), (val, key) => `${messageKey}.${key}`));
    }
  });
  return constructedKeysMessages;
};

const AppLocale = {
  en: createIntl({
    locale: 'en-DE',
    messages: flattenMessages(enMessages),
  }, cache),
  de: createIntl({
    locale: 'de-DE',
    messages: flattenMessages(deMessages),
  }, cache),
};
if (process.env.NODE_ENV !== 'production') {
  try {
    // noinspection JSFileReferences
    AppLocale.crowdin = createIntl({
      locale: 'crowdin',
      messages: flattenMessages(require('./locales/he.json')),
    }, cache);
  }
  catch (e) {
    // Ignore non existing he translation file.
  }
}

_.forEach(AppLocale, locale => {
  locale.formatMessageOptional = (descriptor, ...args) => {
    if (locale.messages[descriptor.id] == null) {
      return null;
    }
    return locale.formatMessage(descriptor, ...args);
  };
});

export default AppLocale;
