// entire file taken from auth0 documentation
import React, {useCallback, useContext, useEffect, useState} from "react";
import {isOfflineMode} from "../utils/Utils";
import auth0 from "auth0-js";
import {reportError} from "../utils/errorReporting";
import history from "../utils/history";

let globalAccessToken = null;
const USERNAME_PASSWORD_CONNECTION_NAME = 'Username-Password-Authentication';

export const webAuth = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  redirectUri: window.location.origin,
  scope: "openid email",
  responseType: "token"
});


export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({children}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [referralCode, setReferralCode] = useState(null);
  const [createAccountReferrer, setCreateAccountReferrer] = useState(null);

  const onRedirectCallback = appState => {
    if (appState.referralCode) {
      setReferralCode(appState.referralCode);
    }
    if (appState.createAccountReferrer) {
      setCreateAccountReferrer(appState.createAccountReferrer);
    }

    history.replace(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  function onFinishAuth(success) {
    setIsAuthenticated(success);
    setLoading(false);
  }

  const onAuthError = useCallback((error, shouldReport) => {
    if (shouldReport) {
      reportError(new Error(`Error in handling authentication flow (${error.code})`), {error});
    }
    onFinishAuth(false);
  }, []);

  const handleAuthResult = useCallback(authResult => {
    webAuth.client.userInfo(authResult.accessToken, function (err, returnedUser) {
      if (err) {
        onAuthError(err);
      } else {
        setUser(returnedUser);
        globalAccessToken = authResult.accessToken;
        onFinishAuth(true);
      }
    });
  }, [onAuthError]);

  const checkSession = useCallback(() => {
    webAuth.checkSession({}, function (err, authResult) {
      if (err) {
        const shouldReport = err.code !== "login_required";
        onAuthError(err, shouldReport);
      } else {
        handleAuthResult(authResult);
      }
    });
  }, [onAuthError, handleAuthResult]);

  useEffect(() => {
    if (window.location.hash.includes("access_token=")) {
      webAuth.parseHash({hash: window.location.hash}, function (err, authResult) {
        if (err) {
          onAuthError(err);
        } else {
          onRedirectCallback(authResult.appState);
          handleAuthResult(authResult);
        }
      });
    } else {
      checkSession();
    }
  }, [onAuthError, handleAuthResult, checkSession]);


  function loginWithGoogle(appState, onErrorCallback) {
    webAuth.authorize({
      connection: 'google-oauth2',
      appState: appState
    }, onErrorCallback);
  }

  function login(email, password, appState, onErrorCallback) {
    webAuth.login({
      realm: USERNAME_PASSWORD_CONNECTION_NAME,
      email: email,
      password: password,
      appState: appState
    }, function (err) {
      if (err) {
        onErrorCallback(err);
      }
    });

  }

  function signUpWithPassword(email, password, name, appState, onErrorCallback) {
    webAuth.signup({
      connection: USERNAME_PASSWORD_CONNECTION_NAME,
      email: email,
      password: password,
      user_metadata: {name}
    }, function (err) {

      if (err) {
        onErrorCallback(err);
      } else {
        login(email, password, appState, onErrorCallback);
      }
    });

  }

  function resetPassword(email, callback) {
    webAuth.changePassword({
      connection: USERNAME_PASSWORD_CONNECTION_NAME,
      email: email
    }, callback);
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        loginWithGoogle,
        signUpWithPassword,
        resetPassword,
        referralCode,
        createAccountReferrer,
        login,
        logout: (...p) => (webAuth.logout(...p)),
        checkSession,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export function refreshToken() {
  return new Promise(function (resolve, reject) {
    webAuth.checkSession({}, function (err, authResult) {
      if (err) {
        window.location.pathname = 'sign-in';
      } else {
        globalAccessToken = authResult.accessToken;
        resolve();
      }
    });
  });
}

export const getToken = async () => {
  if (isOfflineMode()) {
    return 'offline_mode_token';
  }
  return globalAccessToken;
};


