/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderInlineDataFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type businessState_failedBankConnections$ref: FragmentReference;
declare export opaque type businessState_failedBankConnections$fragmentType: businessState_failedBankConnections$ref;
export type businessState_failedBankConnections = {|
  +failedBankConnectionsCount: ?number,
  +$refType: businessState_failedBankConnections$ref,
|};
export type businessState_failedBankConnections$data = businessState_failedBankConnections;
export type businessState_failedBankConnections$key = {
  +$data?: businessState_failedBankConnections$data,
  +$fragmentRefs: businessState_failedBankConnections$ref,
  ...
};
*/


const node/*: ReaderInlineDataFragment*/ = {
  "kind": "InlineDataFragment",
  "name": "businessState_failedBankConnections"
};
// prettier-ignore
(node/*: any*/).hash = '8447e993bb4c7bac1420983cad09e07b';

module.exports = node;
