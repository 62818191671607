import React, {useCallback, useContext, useEffect, useState} from "react";
import {Auth0Provider, getToken as auth0GetToken, refreshToken as auth0RefreshToken, useAuth0} from "./react-auth0-spa";
import {IntroduceAnonymousLoginMutation} from "./IntroduceAnonymousLoginMutation";
import {renderRelayErrorView} from "../relay/errors";
import {useRelayEnvironment} from "react-relay/lib/hooks";
import useEventListener from "@use-it/event-listener";
import {isMobile} from "../utils/Utils";

const queryString = require('query-string');

export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);

const ANONYMOUS_TOKEN_KEY = 'anonymousToken';
const getAnonymousToken = () => {
  return localStorage.getItem(ANONYMOUS_TOKEN_KEY);
};
const setAnonymousToken = (token) => {
  localStorage.setItem(ANONYMOUS_TOKEN_KEY, token);
};
const deleteAnonymousToken = () => {
  localStorage.removeItem(ANONYMOUS_TOKEN_KEY);
};

export const AuthProvider = ({children}) => {
  return (
    <Auth0Provider>
      <InternalAuthProvider>
        {children}
      </InternalAuthProvider>
    </Auth0Provider>
  );
};

const InternalAuthProvider = ({children}) => {
  const env = useRelayEnvironment();
  const auth0Value = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  const anonymousToken = getAnonymousToken();
  // If anonymous user registered in another window, refresh auth0 token
  useEventListener('storage', ({key, oldValue, newValue}) => {
    const isAnonymousKeyChange = (key == null || key === ANONYMOUS_TOKEN_KEY);
    const isKeyCleared = (oldValue != null && newValue == null);
    if (isAnonymousKeyChange && isKeyCleared && !auth0Value.isAuthenticated) {
      auth0Value.checkSession();
    }
  });

  const shouldCreateAnonymousLogin = window.location.hash.includes("anonymous_login") && !isMobile();

  const doneLoading = useCallback(() => {
    // Remove hash from URL after loading
    if (shouldCreateAnonymousLogin) {
      window.history.replaceState(window.history.state, '', window.location.origin + window.location.pathname + window.location.search);
    }
    setIsLoading(false);
  }, [shouldCreateAnonymousLogin]);

  const createAnonymousLogin = useCallback(async () => {
    try {
      const response = await IntroduceAnonymousLoginMutation.async(env, {});
      setAnonymousToken(response.IntroduceAnonymousLogin.token);
      doneLoading();
    }
    catch (err) {
      setError(err);
    }
  }, [env, doneLoading]);

  const isAnonymous = !auth0Value.isAuthenticated && !!anonymousToken;
  useEffect(() => {
    if (isLoading && !auth0Value.loading) {
      if (!(auth0Value.isAuthenticated || isAnonymous) && shouldCreateAnonymousLogin) {
        // noinspection JSIgnoredPromiseFromCall
        createAnonymousLogin();
      } else {
        doneLoading();
      }
    }
  }, [
    auth0Value.loading, isLoading, shouldCreateAnonymousLogin, createAnonymousLogin, doneLoading, anonymousToken, auth0Value.isAuthenticated, isAnonymous
  ]);
  const parsedAnonymousToken = anonymousToken ? JSON.parse(atob(anonymousToken.split('.')[1])) : null;
  const user = isAnonymous ? {sub: parsedAnonymousToken?.sub} : auth0Value.user;
  const referralCode = queryString.parse(window.location.search)["referral_code"];
  const authValue = {
    ...auth0Value,
    isAnonymous,
    loading: isLoading,
    user,
    deleteAnonymousToken,
    referralCode: referralCode || auth0Value.referralCode,
  };

  return (
    <AuthContext.Provider value={{...authValue}}>
      {error && (
        <div className='h-100 d-flex'>
          {renderRelayErrorView(error)}
        </div>
      )}
      {children}
    </AuthContext.Provider>
  );
};

export const refreshToken = async () => {
  const anonymousToken = getAnonymousToken();
  if (anonymousToken) {
    // Not implemented
    throw new Error("Cannot refresh anonymous token");
  }
  return auth0RefreshToken();
};

export const getToken = async () => {
  const auth0Token = await auth0GetToken();
  if (auth0Token) {
    return auth0Token;
  }
  return getAnonymousToken();
};

export const getPrevAnonymousToken = async () => {
  const auth0Token = await auth0GetToken();
  if (auth0Token) {
    return getAnonymousToken();
  }
  return null;
};
