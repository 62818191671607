
const LANGUAGE_CODE_LOCAL_STORAGE_KEY = "languageCode";

function getLanguageCodeFromReferrer() {
  const referrer = document.referrer.split('/')[2];
  if (!referrer) {
    return null;
  }
  if (referrer.includes("de.getsorted")) {
    return "de";
  }
  if (referrer.includes("en.getsorted")) {
    return "en";
  }
  if (referrer.includes("getsorted") && !referrer.includes("app.")) {
    return "de";
  }
  return null;

}

function getLanguageAccordingToBrowser() {
  const browserLanguageCode = window.navigator.languages
    ? window.navigator.languages[0]
    : (window.navigator.language || window.navigator.userLanguage);

  const browserLanguage = browserLanguageCode ? browserLanguageCode.slice(0, 2) : "de";
  if (browserLanguage === "de") {
    return "de";
  }
  // any other language than German should get the english version
  return "en";
}

function getLanguageCodeFromLocalStorage() {
  return localStorage.getItem(LANGUAGE_CODE_LOCAL_STORAGE_KEY);
}

export function storeLanguageCodeInLocalStorage(languageCode) {
  localStorage.setItem(LANGUAGE_CODE_LOCAL_STORAGE_KEY, languageCode);
}

export function getDefaultLanguage() {
  const languageCodeFromReferrer = getLanguageCodeFromReferrer();
  if (languageCodeFromReferrer) {
    storeLanguageCodeInLocalStorage(languageCodeFromReferrer);
    return languageCodeFromReferrer;
  }
  const languageFromLocalStorage = getLanguageCodeFromLocalStorage();
  if (languageFromLocalStorage) {
    return languageFromLocalStorage;
  }
  return getLanguageAccordingToBrowser();
}

export function isCrowdinTranslationEnvironment() {
  return window.location.href.includes("sorted-web-translation");
}
