import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {BusinessProvider} from "./businessState";
import UnhandledError from "./layout/UnhandledError";
import {AuthProvider} from "./auth/AuthProvider";
import {RawIntlProvider} from "react-intl";
import AppLocale from "./lang";
import {getDefaultLanguage} from "./utils/languageUtils";
import {RelayEnvironmentProvider} from "react-relay/hooks";
import env from "./relay/environment";
import {ErrorBoundary, store} from "./AppRendererExports";

const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App'));

if (module.hot) {
  module.hot.accept();
}

ReactDOM.render(
  <RawIntlProvider value={AppLocale[getDefaultLanguage()]}>
    <ErrorBoundary FallbackComponent={UnhandledError}>
      <Provider store={store}>
        <Suspense fallback={<div className="loading"/>}>
          <RelayEnvironmentProvider environment={env}>
            <AuthProvider>
              <BusinessProvider>
                <App/>
              </BusinessProvider>
            </AuthProvider>
          </RelayEnvironmentProvider>
        </Suspense>
      </Provider>
    </ErrorBoundary>
  </RawIntlProvider>,
  document.getElementById('root')
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
