import {Environment, Network, Observable, RecordSource, Store, QueryResponseCache} from 'relay-runtime';
import {getPrevAnonymousToken, getToken, refreshToken} from "../auth/AuthProvider";
import {ConnectionError, ServerError} from "./errors";
import {globalAssumedBusinessId} from "../businessState";
import {isDev} from "../utils/Utils";


// Define a function that fetches the results of an operation (query/mutation/etc)
// and returns its results as a Promise:
const fetchQueryInner = async (
  operation,
  variables,
  sink,
  isRetry = false
) => {

  // Add authentication and other headers here
  const headers = {
    'content-type': 'application/json',
  };
  const token = await getToken();
  if (token) {
    headers.authorization = 'Bearer ' + token;
  }
  const prevAnonymousToken = await getPrevAnonymousToken();
  if (prevAnonymousToken) {
    headers['PREV-ANONYMOUS-TOKEN'] = prevAnonymousToken;
  }

  if (globalAssumedBusinessId) {
    headers['OVERRIDE-BUSINESS-ID'] = globalAssumedBusinessId;
  }
  const body = JSON.stringify({
    query: operation.text, // GraphQL text from input
    variables,
  });
  fetch(process.env.REACT_APP_BACKEND_URL, {
    method: 'POST',
    headers,
    body,
  }).then(response => {
    return response.json();
  }).then(async data => {
    if (data.errors) {
      if (data.errors[0].message === "Signature has expired" && !isRetry) {
        await refreshToken();
        return fetchQueryInner(operation, variables, sink);
      }
      sink.error(new ServerError(data.errors));
      sink.complete();
      return null;
    }
    sink.next(data);
    sink.complete();
    return null;
  }).catch((error) => {
    sink.error(new ConnectionError(error));
    sink.complete();
  });
};

export const cache = isDev() ? new QueryResponseCache({ size: 250, ttl: 3600 * 1000 }) : null;

export const fetchQuery = (
  request,
  variables,
  cacheConfig
) => {
  const queryID = request.text;
  const isMutation = request.operationKind === 'mutation';
  const isQuery = request.operationKind === 'query';
  const forceFetch = cacheConfig && cacheConfig.force;

  const variablesUsedForCaching = {...variables, globalAssumedBusinessId};
  // Try to get data from cache on queries
  const fromCache = cache && cache.get(queryID, variablesUsedForCaching);
  if (isQuery && fromCache !== null && !forceFetch) {
    return fromCache;
  }
  const updateCache = (data) => {
    if (cache != null) {
      if (isQuery && data) {
        cache.set(queryID, variablesUsedForCaching, data);
      }
      if (isMutation) {
        cache.clear();
      }
    }
  };

  return Observable.create(sink => {
    fetchQueryInner(request, variables, {
      ...sink,
      next: (data) => {
        updateCache(data);
        sink.next(data);
      },
    });
  });
};

// Create a network layer from the fetch function
const network = Network.create(fetchQuery);
const store = new Store(new RecordSource());

const environment = new Environment({
  network,
  store
});

export default environment;

