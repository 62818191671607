import React from "react";
import {NoConnectionIcon} from "../assets/svg";
import IntlMessages from "../utils/IntlMessages";
import ServerErrorIcon from "../assets/svg/ServerErrorIcon";
import {FormattedMessage} from "react-intl";
import ContactUsLink from "./ContactUsLink";

const renderErrorView = ({icon, headerStringName, helpText}) => {
  return (
    <div className="d-flex flex-grow-1 flex-column align-items-center justify-content-center">
      <div className="d-flex flex-row align-items-center">
        <div style={{height: '50px', width: '50px'}}>
          {icon}
        </div>
        <h1 className="p-3 m-0"><IntlMessages id={headerStringName}/></h1>
      </div>
      <p>{helpText}</p>
    </div>
  );
};

export const NoConnectionView = () => {
  return renderErrorView({
    icon: <NoConnectionIcon/>,
    headerStringName: "relayError.connectionErrorOnLoading.description",
    helpText: <IntlMessages id="relayError.connectionErrorOnLoading.helpText"/>
  });
};

export const ServerErrorView = () => {
  return renderErrorView({
    icon: <ServerErrorIcon/>,
    headerStringName: "relayError.serverErrorOnLoading.description",
    helpText: <FormattedMessage id="relayError.serverErrorOnLoading.helpText" values={{contactUsLink: <ContactUsLink referer="Error on Fetch"/>}}/>
  });
};

export const UnhandledErrorView = () => {
  return renderErrorView({
    icon: <ServerErrorIcon/>,
    headerStringName: "unhandledError.description",
    helpText: <FormattedMessage id="unhandledError.helpText" values={{contactUsLink: <ContactUsLink referer="Unhandled Error"/>}}/>
  });
};

